import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalRC from 'components/ModalRC';
import { ModalRCDevicesProps } from './ModalRCDevices.d';
import { useTranslation } from 'react-i18next';
import CustomToggle from 'components/CustomToggle/CustomToggle';
import { PenFilled } from 'assets/icons';
import Slider from 'components/Slider';
import { withStyles, Button, ListItemText, MenuItem, OutlinedInput, Select, Theme } from '@material-ui/core';
import Input from 'components/Input';
import iotApi from 'store/modules/iot';
const SubmitButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const CancelButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#6b718b',
		borderRadius: 0,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#6b718b',
		},
	},
}))(Button);

function ModalRCDevices(props: ModalRCDevicesProps): JSX.Element {
	const { id } = useParams<{ id: string }>();
	const { open, setOpen, subMenu } = props;
	const { t } = useTranslation();

	const [editable, setEditable] = useState<number>(-1);
	const [editableValue, setEditableValue] = useState<string>('');
	const [toggleItems, setToggleItems] = useState<Array<{ label: string; value: number; selected: boolean }>>([]);

	const [putParameters, _] = iotApi.usePutParametersMutation();


	useEffect(() => {
		if (subMenu) {
			setToggleItems(subMenu.pages.map((page, index) => ({ label: page.name, value: index, selected: index === 0 })));
		}
	}, [subMenu]);

	const setToggle = (value: number) => {
		setToggleItems((prevState) =>
			prevState.map((item) => {
				return { ...item, selected: item.value === value };
			}),
		);
	};

	const onFormSubmit = (ev: FormEvent) => {
		ev.preventDefault();
		setEditable(-1);
		const formData = new FormData(ev.target as HTMLFormElement);
		const data = Object.fromEntries(formData.entries());

		
		
		const putParametersData = {
			components: [
				{
					uuid: data.componentId,
					parameters: {
						parameter_code: data.parameterId,
						value: editableValue,
					},
				},
			],
		};

		putParameters({ uuid: id!, parameters: putParametersData });
	};
	
	return (
		<div className="m-rc-devices-modal">
			<ModalRC
				withSearch
				searchPlaceholder={t('Search for parameter').toString()}
				headline={subMenu?.name}
				open={open}
				setSearchValue={() => {
					return true;
				}}
				onFormSubmit={onFormSubmit}
				onClose={() => {
					setEditable(-1)
					setOpen(false)
				}}>
				<div className="content-container">
					<CustomToggle id="expert-level-submenu-toggler" items={toggleItems} setToggle={setToggle} />
					<div className="parameters-container">
						{subMenu.pages &&
							subMenu.pages.map(
								(page, index) =>
									toggleItems.find((item) => item.value === index && item.selected) &&
									page.parameter_groups.map((parameterGroup, index) => {
										return (
											<div key={index}>
												<div className="parameter-group-name">{parameterGroup.name}</div>
												{parameterGroup.parameter_descriptors.map((parameter, index) => (
													<div key={index} className={`parameter-row ${editable === index && 'editable'}`}>
														<div className="parameter-content">
															{editable === index ? (
																<>
																	<input type="hidden" name="componentId" value={parameter.device_uuid} />
																	<input type="hidden" name="parameterId" value={parameter.parameter_template_id} />
																	{(() => {
																		switch (parameter.type) {
																			case 'f32':
																			case 'u16':
																			case 'u32':
																			case 'u64':
																			case 'i16':
																				if (parameter.mapping && Object.keys(parameter.mapping).length > 0) {
																					return (
																						<>
																							<div>
																								<span className="parameter-name parameter-group">
																									{parameter.group}
																								</span>
																								<span className="parameter-name">
																									{parameter.name}
																								</span>
																							</div>
																							<Select
																								labelId="demo-simple-select-label"
																								label={parameter.name}
																								id="demo-simple-select"
																								value={parameter.value}
																								onChange={(e) => {
																									e.target.value &&
																										setEditableValue(e.target.value as string);
																								}}
																								input={<OutlinedInput />}>
																								{Object.keys(parameter.mapping).map(
																									(mappingKey, index) => {
																										return (
																											<MenuItem value={mappingKey} key={index}>
																												<ListItemText
																													primary={
																														parameter.mapping[mappingKey]
																													}
																												/>
																											</MenuItem>
																										);
																									},
																								)}
																							</Select>
																						</>
																					);
																				}
																				return (
																					<Slider
																						label={parameter.name}
																						minValue={parameter.min}
																						unit={parameter.unit || ''}
																						maxValue={parameter.max}
																						defaultValue={parameter.value}
																						handleValue={(value) => {
																							setEditableValue(value.toString());
																						}}
																					/>
																				);
																			default:
																				return (
																					<Input
																						type="default"
																						label={parameter.name}
																						onChange={(value) => {
																							setEditableValue(value);
																						}}
																					/>
																				);
																		}
																	})()}
																	<SubmitButton type="submit" className="parameter-submit">
																		{'Save'}
																	</SubmitButton>
																	<CancelButton
																		className="parameter-cancel"
																		size="small"
																		onClick={() => {
																			setEditable(-1);
																		}}>
																		{'Cancel'}
																	</CancelButton>
																</>
															) : (
																<>
																	<div>
																		<span className="parameter-name parameter-group">{parameter.group}</span>
																		<span className="parameter-name">{parameter.name}</span>
																	</div>
																	<span className="parameter-value">{parameter.value}</span>
																</>
															)}
														</div>
														{!parameter.is_read_only && editable < 0 ? (
															<PenFilled
																className="edit-icon"
																onClick={(e) => {
																	setEditable(index);
																	setEditableValue(parameter.value);
																}}
															/>
														) : (
															<span className="read-only-icon" />
														)}
													</div>
												))}
											</div>
										);
									}),
							)}
					</div>
				</div>
			</ModalRC>
		</div>
	);
}

export default ModalRCDevices;
