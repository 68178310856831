import ReactValidationError from 'components/ValidationError/ValidationError';
import { GoogleApiWrapper, IProvidedProps } from 'google-maps-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputProps } from './Input.d';

function Input(props: InputProps): JSX.Element {
	const {
		id,
		label,
		name,
		placeholder,
		error,
		invalid,
		disabled,
		type,
		readOnly,
		searchResults,
		unit,
		width,
		value,
		sliderShift,
		style,
		google,
		required,
		selectSearchedItem,
		onPlaceSelected,
	} = props;

	const [showSearchResults, setShowSearchResults] = useState(false);
	const [inputText, setInputText] = useState<string | number>(value || value === 0 ? value : '');
	const [showCancelButton, setShowCancelButton] = useState(false);
	const [passwordDisplay, setPasswordDisplay] = useState(false);
	const [unitShift, setUnitShift] = useState(sliderShift);

	const autocompleteInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setInputText(value || value === 0 ? value : '');
	}, [value]);

	useEffect(() => {
		setUnitShift(sliderShift);
	}, [sliderShift]);

	useEffect(() => {
		if (type !== 'address' || !google || !onPlaceSelected) return;
		const autocomplete = new google.maps.places.Autocomplete(autocompleteInputRef.current as HTMLInputElement);
		autocomplete.addListener('place_changed', () => {
			const place = autocomplete.getPlace();
			onPlaceSelected(place);
		});
	}, [google, type, onPlaceSelected]);

	const handleSearchFocus = (e) => {
		if (e.target.value) setShowSearchResults(true);
	};

	const handleSearchBlur = (e) => {
		setShowSearchResults(false);
	};

	const handleSearch = (e) => {
		if (e.target.value) {
			setShowSearchResults(true);
			if (searchResults?.length) setShowCancelButton(true);
		} else {
			setShowSearchResults(false);
			setShowCancelButton(false);
		}
		setInputText(e.target.value);
		props.setValue?.(e.target.value);
	};

	const handleResultSelect = (e) => {
		setInputText(e.target.innerText);
		selectSearchedItem?.(e.target.innerText);
	};

	const handleCancel = (e) => {
		setInputText('');
		setShowCancelButton(false);
		props.setValue?.('');
	};

	const handlePasswordDisplay = (e) => {
		setPasswordDisplay(!passwordDisplay);
	};

	const handleUnitInputChange = (e) => {
		setUnitShift(e.target.value.length);
		setInputText(e.target.value);
		props.setValue?.(e.target.value);
	};

	const handleUnitBlur = (e) => {
		if (!e.target.value.length) setUnitShift(false);
	};

	const handleDefaultInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setInputText(event.target.value);
		props.setValue?.(event.target.value);
	};

	return (
		<div className="a-input" style={{ ...style }}>
			{
				{
					default: (
						<input
							id={id}
							value={inputText}
							name={name}
							onChange={handleDefaultInputChange}
							className="input-field"
							style={width ? { width: width - 32 } : {}}
							type="text"
							placeholder={placeholder}
							disabled={disabled}
							required={required}
						/>
					),
					label: (
						<>
							<label className="input-label">{label}</label>
							<input
								id={id}
								value={inputText}
								name={name}
								onChange={handleDefaultInputChange}
								className={`input-field  ${invalid ? 'input-invalid' : ''}`}
								style={width ? { width: width - 32 } : {}}
								type="text"
								placeholder={placeholder}
								disabled={disabled}
								readOnly={readOnly}
								required={required}
							/>
							<ReactValidationError error={error || ' '} />
						</>
					),
					search: (
						<div className="dropdown">
							{label && (
								<div className="tooltip-wrapper">
									<label className="input-label">{label}</label>
								</div>
							)}
							<div className="password-wrapper">
								<input
									className={`input-field  ${invalid ? 'input-invalid' : ''}`}
									style={width ? { width: width - 32 } : {}}
									type="text"
									placeholder={placeholder}
									value={inputText}
									name={name}
									onFocus={handleSearchFocus}
									onBlur={handleSearchBlur}
									onChange={handleSearch}
									disabled={disabled}
									readOnly={readOnly}
								/>
								<button
									className={`search-icon ${showCancelButton ? 'cancel-icon' : ''}`}
									onClick={(e: React.MouseEvent) => {
										e && e.preventDefault();
										showCancelButton && handleCancel(e);
									}}></button>
							</div>
							<ReactValidationError error={error || ' '} />
							<div
								id="myDropdown"
								className={`dropdown-content ${showSearchResults ? 'show' : ''}`}
								style={width ? { width: width - 32 } : {}}>
								{searchResults?.map((result, index) => (
									<a key={'resultOption' + index} onMouseDown={handleResultSelect}>
										{result}
									</a>
								))}
							</div>
						</div>
					),
					description: (
						<>
							<label className="input-label">{label}</label>
							<input
								className="input-field"
								style={width ? { width: width - 32 } : {}}
								type="text"
								name={name}
								placeholder={placeholder}
								disabled={disabled}
								required
							/>
						</>
					),
					password: (
						<>
							<div className="tooltip-wrapper">
								<label className="input-label">{label}</label>
								<span className="question-tooltip">?</span>
							</div>
							<div className="password-wrapper">
								<input
									className={`input-field ${invalid ? 'input-invalid' : ''}`}
									style={width ? { width: width - 32 } : {}}
									type={passwordDisplay ? 'text' : 'password'}
									name={name}
									placeholder={placeholder}
									disabled={disabled}
									required
								/>
								<button className="password-eye" onClick={handlePasswordDisplay}></button>
							</div>
							<ReactValidationError error={error || ' '} />
						</>
					),
					unit: (
						<>
							{label?.length ? (
								<div className="tooltip-wrapper">
									<label className="input-label">{label}</label>
									<span className="info-tooltip">!</span>
								</div>
							) : null}
							<div className="password-wrapper">
								<input
									onChange={handleUnitInputChange}
									onFocus={() => setUnitShift(true)}
									onBlur={handleUnitBlur}
									className="input-field input-unit"
									style={sliderShift ? { backgroundColor: 'transparent' } : {}}
									type="text"
									name={name}
									placeholder=""
									value={inputText}
									disabled={disabled}
									required
								/>
								<span className={unitShift ? 'input-unit-text-right' : 'input-unit-text-centered'}>{unit}</span>
							</div>
							<label className="input-error unit">{error}</label>
						</>
					),
					textarea: (
						<>
							<textarea
								className="input-field"
								style={width ? { width: width - 32 } : {}}
								name={name}
								value={value}
								placeholder={placeholder}
								onChange={handleDefaultInputChange}
								disabled={disabled}
								required={required}
							/>
						</>
					),
					address: (
						<>
							<label className="input-label">{label}</label>
							<input
								className="input-field"
								style={width ? { width: width - 32 } : {}}
								type="text"
								ref={autocompleteInputRef}
								name={name}
								defaultValue={value}
								placeholder={placeholder}
								disabled={disabled}
							/>
							<ReactValidationError error={error || ' '} />
						</>
					),
				}[type]
			}
		</div>
	);
}

function WrappedInput(props: InputProps): JSX.Element {
	const Wrapped =
		props.type === 'address'
			? GoogleApiWrapper({
					apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
			  })(Input as React.ComponentType<InputProps & IProvidedProps>)
			: Input;

	return <Wrapped {...props} />;
}

export default WrappedInput;
