import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import { AppDispatch } from 'store';
import DeviceCard from './components/DeviceCard';
import { WidgetDeviceViewProps } from './WidgetDeviceView.d';
import { Skeleton } from '@material-ui/lab';
import { More, TrashOutlined, WrenchFilled } from 'assets/icons';
import backboneApi from 'store/modules/backbone';
import showToast from 'services/utils/toaster';
import DeleteDialog from 'components/ConfirmationDialog';
import MaintenanceModal from 'components/ModalAddMaintenance/ModalAddMaintenance';
import DeviceEmptyView from './components/DeviceEmptyView';

function WidgetDeviceView(props: WidgetDeviceViewProps): JSX.Element {
	const { deviceData, maintenanceData } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const routeParams = useParams();
	const { id } = routeParams;

	const deviceCounter = deviceData?.length ? deviceData.length : 0;

	const [loading, setLoading] = useState<boolean>(true);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openModalMaintenance, setOpenModalMaintenance] = useState<boolean>(false);
	const [selectedDeviceUuid, setSelectedDeviceUuid] = useState<string>('');
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

	const open = Boolean(anchorEl);

	const containerRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 650);
	}, []);

	const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event && event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent | React.KeyboardEvent) => {
		event && event.stopPropagation();
		setAnchorEl(null);
	};

	const handleDeviceSelect = (uuid: string) => {
		setSelectedDeviceUuid(uuid);
	};

	const onConfirmDelete = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		onDeleteComponent(selectedDeviceUuid);
		setOpenDeleteModal(false);
	};

	const onDeleteComponent = async (uuid: string) => {
		try {
			await dispatch(backboneApi.endpoints.deleteSystemComponent.initiate({ systemId: id!, componentId: uuid }));
		} catch (err) {
			showToast.error(err.message);
		}
	};

	const onCancelDelete = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		setOpenDeleteModal(false);
	};

	return (
		<div className="m-widget-device-view">
			{loading ? (
				<div className="skeleton-container-headline">
					<Skeleton height={40} width={120} />
				</div>
			) : deviceCounter < 1 ? (
				<DeviceEmptyView />
			) : (
				<div className="headline-container" ref={containerRef}>
					<h4 className="headline-title">
						{deviceCounter > 1 ? `${deviceCounter} ${t('IndividualDevices')}` : `${deviceCounter} ${t('Device')}`}
					</h4>
					<IconButton onClick={(e) => handleMoreClick(e)}>
						<More className="more-icon" />
					</IconButton>
					<Menu
						className="menu-container"
						style={{ transform: 'translateY(40px) translateX(-65px)' }}
						anchorEl={anchorEl}
						open={open}
						container={() => containerRef.current}
						onClose={handleClose}>
						<MenuItem
							onClick={() => {
								if (selectedDeviceUuid !== '') {
									setAnchorEl(null);
									setOpenDeleteModal(true);
								}
							}}>
							<TrashOutlined className="menu-icon" />
							<span className="menu-text">{t('Delete Device')}</span>
						</MenuItem>
						<Divider />
						<MenuItem
							onClick={() => {
								setOpenModalMaintenance(true);
								setAnchorEl(null);
							}}>
							<WrenchFilled />
							<span className="menu-text">{t('Add maintenance')}</span>
						</MenuItem>
					</Menu>
				</div>
			)}
			<div data-uk-slider>
				<div className="uk-position-relative">
					<div className="uk-slider-container uk-light">
						<ul className="uk-slider-items uk-grid uk-grid-match">
							{deviceData?.map((device, idx) => (
								<li key={idx} className="uk-width-1-1">
									<div className="uk-cover-container">
										{loading ? (
											<div className="skeletons-container">
												<Skeleton height={400} />
												<Skeleton height={60} />
												<Skeleton height={60} />
											</div>
										) : (
											<DeviceCard deviceData={device} onActive={handleDeviceSelect} />
										)}
									</div>
								</li>
							))}
						</ul>
						<div className="slider-navigation-container">
							<a
								className="uk-position-center-left uk-position-small uk-hidden-hover"
								style={{ backgroundColor: 'transparent', width: '29px' }}
								href="#"
								data-uk-slidenav-previous
								data-uk-slider-item="previous"></a>
							<ul className="uk-slider-nav uk-dotnav"></ul>
							<a
								className="uk-position-center-right uk-position-small uk-hidden-hover"
								style={{ backgroundColor: 'transparent', width: '29px' }}
								href="#"
								data-uk-slidenav-next
								data-uk-slider-item="next"></a>
						</div>
					</div>
				</div>
			</div>
			<MaintenanceModal
				open={openModalMaintenance}
				stepper={maintenanceData.data.stepper}
				devices={maintenanceData.data.devices}
				handleClose={() => setOpenModalMaintenance(false)}></MaintenanceModal>
			<DeleteDialog
				open={openDeleteModal}
				title={t('Delete device')}
				content={t('Are you sure?')}
				onConfirm={onConfirmDelete}
				onCancel={onCancelDelete}
			/>
		</div>
	);
}

export default WidgetDeviceView;
