import { TextField } from '@material-ui/core';
import { CheckFilledCircle } from 'assets/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	disabled?: boolean;
}

function Header({ name, setName, disabled }: HeaderProps): JSX.Element {
	const { t } = useTranslation();

	return (
		<div className="header">
			<TextField
				id="device-name"
				label=""
				value={name}
				onChange={(e) => setName(e.target.value)}
				InputProps={{
					disableUnderline: true,
					style: {
						color: '#212d40',
						fontSize: '32px',
						fontFamily: 'Inter',
						fontWeight: 600,
						width: 600,
					},
				}}
				placeholder={t('FacilityName').toString() + '*'}
				InputLabelProps={{
					shrink: false,
				}}
				disabled={disabled}
			/>
		</div>
	);
}
export default Header;
