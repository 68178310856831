import { FONT_FAMILY } from "./constants";

export const typography = {
    fontSize: 16,
    fontFamily: FONT_FAMILY,
    fontWeights: {
        regular: 300,
        medium: 500,
        bold: 700,
    },
    useNextVariants: true,
    h1: {
        fontSize: "3rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
    },
    h2: {
        fontSize: "2rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
    },
    h3: {
        fontSize: "2rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
    },
    h4: {
        fontSize: "1.5rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
    },
    h5: {
        fontSize: "0.875rem",
        fontSizePx: 14,
    },
    h6: {
        fontSize: "0.9375rem",
        letterSpacing: 0.6,
    },
    body1: {
        fontSize: "1rem",
    },
    caption: {
        fontSize: "0.875rem",
        fontSizePx: 14,
        lineHeight: "0.875rem"
    },
    subtitle1: {
        fontSize: "1.375rem",
    },
    sectionHeadline: {
        fontSize: "2rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
    },
    sectionHeadlineBold: {
        fontSize: "1.7rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
    },
}