import React from 'react';
import { ModalAddMaintenanceCardProps, MoleculeStep1Props } from 'components/ModalAddMaintenance/ModalAddMaintenance.d';
import Card from './Card';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';

function Step1(props: MoleculeStep1Props): JSX.Element {
	const { cards } = props;
	const [selected, setSelected] = React.useState<Array<string>>([]);
	const [searchResults, setSearchResults] = React.useState<Array<ModalAddMaintenanceCardProps>>(cards);

	const { t } = useTranslation();

	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		e && e.stopPropagation();
		if (selected.includes(e.target.value)) {
			setSelected((current) => current.filter((item) => item !== e.target.value));
		} else {
			setSelected((current) => [...current, e.target.value]);
		}
	};

	const searchList = (value: string) => {
		if (value)
			setSearchResults([
				...cards.filter((card) => card.columns[0].value.toLowerCase().includes(value.toLowerCase())),
				...cards.filter((card) => card.columns[1].value.toLowerCase().includes(value.toLowerCase())),
			]);
		else setSearchResults(cards);
	};

	return (
		<div className="m-step1" id="step1">
			<span className="modal-title">{t('Select devices')}</span>
			<Input placeholder={t('Find device').toString()} type="search" width={360} setValue={searchList}></Input>
			{searchResults.map((card, index) => (
				<Card
					key={index}
					columns={card.columns}
					checked={selected.includes(card.checkboxValue)}
					onCheck={handleCheck}
					checkboxValue={card.checkboxValue}
				/>
			))}
		</div>
	);
}

export default Step1;
