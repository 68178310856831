import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AtomStatusTagProps } from './StatusTag.d';
import { AttentionFilled, NoConnectionWhite, CheckFilledCircle, WrenchWhite, LockpadFilled, NoWifi } from 'assets/icons';
import Tooltip from 'components/Tooltip/Tooltip';

function ReactStatusTag(props: AtomStatusTagProps): JSX.Element {
	const { t } = useTranslation();
	const { statusInfo, statusResult, iotPermission, text, color, type = 'medium' } = props;
	const [displayTooltip, setDisplayTooltip] = useState(false);

	const iconMapper = {
		ONLINE: iotPermission === false ? <NoWifi /> : (statusResult === "ERROR" ? <AttentionFilled /> : (statusResult === "MAINTENANCE" ? <WrenchWhite /> : <CheckFilledCircle />)),
		UNKNOWN: <NoConnectionWhite />,
		error: <AttentionFilled />,
		OFFLINE: !iotPermission ? <LockpadFilled /> : <NoWifi />,
		ARCHIVED: <NoWifi />,
	};

	const tooltipTextMapper = {
		UNKNOWN: t('Never had connection to Internet'),
		OFFLINE: !iotPermission ? t('No access') : t('Currently offline'),
		ARCHIVED: t('Currently offline'),
		ONLINE: !iotPermission && t('Currently offline'),
	};

	return (
		<div className="a-status-tag">
			{
				{
					medium: (
						<span onMouseEnter={() => setDisplayTooltip(true)} onMouseLeave={() => setDisplayTooltip(false)} className={`tag ${color ? color : ''}`}>
							<span className='iconSpan'>{statusInfo ? iconMapper[statusInfo] : null}</span>
							{displayTooltip && statusResult === "OFFLINE" &&
							<Tooltip type="info" text={tooltipTextMapper[statusInfo]} arrowPosition="top" />}
							{text}
						</span>
					),
					small: (
						<span className={`tag small ${color ? color : ''}`}>
							{statusInfo && iconMapper[statusInfo]}
							{text}
						</span>
					),
					large: (
						<span className="tag large">
							<span className="info-tooltip">!</span>
							{text}
						</span>
					),
				}[type]
			}
		</div>
	);
}
export default ReactStatusTag;
