import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextLink from 'components/TextLink/TextLink';
import AssignEmployeesModal from './modals/AssignEmployeesModal';
import { Chip } from '@material-ui/core';
import { Employee } from 'types/Employee';
import { Close, IllDragDrop, UserFilledCircle } from 'assets/icons';
import { Skeleton } from '@material-ui/lab';
import pimcoreApi from 'store/modules/pimcore';
import { useParams } from 'react-router-dom';
import showToast from 'services/utils/toaster';

function WidgetAssignEmployees(): JSX.Element {
	const { t } = useTranslation();
	const [employees, setEmployees] = useState<Array<Employee>>([]);
	const [assignedEmployees, setAssignedEmployees] = useState<Employee[]>([]);

	const { id } = useParams();

	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 550);
	}, []);

	const { data: assignments } = pimcoreApi.useGetDeviceAssignmentsQuery({ systemIds: [id!] });
	const [deleteEmployee, _] = pimcoreApi.useDeleteEmployeeMutation();

	useEffect(() => {
		if (!assignments) return;
		setEmployees(assignments);
		setAssignedEmployees(assignments.filter((emp: Employee) => emp.assigned));
	}, [assignments]);

	const onDeleteEmployee = async (employeeId: string, deviceId: string) => {
		try {
			deleteEmployee({ systemId: deviceId, employeeId });
		} catch (error) {
			showToast.error(error.message);
		}
	};

	return (
		<div className="m-assign-employees-widget">
			<div className="wrapper">
				<div className="headline-wrapper">
					<span className="headline">{t('Employee')}</span>
					<UserFilledCircle uk-toggle="target: #modal-form" className="add-employee-icon" />
				</div>
				{loading ? (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				) : (
					<>
						{assignedEmployees &&
							assignedEmployees.map((employee: Employee, index: number) => {
								return index < 2 ? (
									<Chip
										key={employee.uuid}
										label={`${employee.firstName} ${employee.lastName}`}
										className="employee-chip"
										deleteIcon={<Close className="close-icon" />}
										onDelete={() => onDeleteEmployee(employee.uuid, id!)}
									/>
								) : null;
							})}
						<div className="more-link" uk-toggle="target: #modal-form">
							{assignedEmployees && assignedEmployees.length > 2 ? (
								<TextLink
									type="underlined_small"
									text={
										assignedEmployees.length - 2 > 1
											? `+ ${assignedEmployees.length - 2} ${t('more assigned employees')}`
											: `+ ${assignedEmployees.length - 2} ${t('more assigned employee')}`
									}
									link="#"
								/>
							) : null}
						</div>
						{/* TODO RG: change placeholder with image after design is ready */}
						{!assignedEmployees.length ? (
							<div className="image-placeholder" uk-toggle="target: #modal-form">
								<IllDragDrop className="add-icon" />
							</div>
						) : null}
					</>
				)}
			</div>
			<AssignEmployeesModal modalId="modal-form" deviceIDs={[id!]} />
		</div>
	);
}

export default WidgetAssignEmployees;
