import React from 'react';
import { CheckboxProps } from './Checkbox.d';

function Checkbox(props: CheckboxProps): JSX.Element {
	const { label, disabled, checked, value, name, onChange } = props;

	return (
		<div className="a-checkbox">
			<label className={`label ${disabled ? 'label-disabled' : ''}`}>
				<input
					type="checkbox"
					className={`checkbox ${disabled ? 'checkbox-disabled' : ''}`}
					value={value}
					name={name}
					checked={checked}
					onChange={onChange}
				/>
				{label}
			</label>
		</div>
	);
}
export default Checkbox;
