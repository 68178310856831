import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';

const getApiUrls = (state: RootState) => state.heatingdevice.apiUrls;
const getHeader = (state: RootState) => state.heatingdevice.header;
const getInitialHeaderData = (state: RootState) => state.heatingdevice.initialHeaderData;
const getDevicesData = (state: RootState) => state.heatingdevice.devicesData;
const getDeviceTotals = (state: RootState) => state.heatingdevice.totalItems;
const getDeviceStatistics = (state: RootState) => state.heatingdevice.statistics;
const getDeviceData = (state: RootState) => state.heatingdevice.deviceData;
const getFilters = (state: RootState) => state.heatingdevice.filters;
const getStepperModalData = (state: RootState) => state.heatingdevice.stepperModalData;
const getStringFilters = (state: RootState) => {
	const filters: Record<string, string> = {};

	Object.keys(state.heatingdevice.filters).map((key) => {
		switch (key) {
			case 'offset':
				filters[key] = state.heatingdevice.filters[key]?.toString() || '0';
				break;
			case 'limit':
				filters[key] = state.heatingdevice.filters[key]?.toString() || '10';
				break;
			default:
				filters[key] = state.heatingdevice.filters[key];
				break;
		}
	});

	if (!filters.expand) {
		filters.expand = 'components';
	}

	const searchQuery = Object.keys(state.heatingdevice.filters).length > 0 ? new URLSearchParams(filters).toString() : '';
	return searchQuery;
};
const getCurrentEvents = (state: RootState) => state.heatingdevice.currentEvents;
const getMainComponents = (state: RootState) => state.heatingdevice.mainComponents;
const getOtherComponents = (state: RootState) => state.heatingdevice.otherComponents;
const getAddEmpModalData = (state: RootState) => state.heatingdevice.addEmpModalData;
const getEventsFilters = (state: RootState) => state.heatingdevice.eventsFilters;
const getSystemDevices = (state: RootState) => state.heatingdevice.systemDevices;
const getSummiteer = (state: RootState) => state.heatingdevice.summiteerData;

export const headerSelector = createSelector(getHeader, (header) => header);

export const summiteerSelector = createSelector(getSummiteer, (summiteer) => summiteer);

export const initialHeaderSelector = createSelector(getInitialHeaderData, (initialHeaderData) => initialHeaderData);

export const deviceListSelector = createSelector(getDevicesData, (devicesList) => devicesList);

export const eventsFiltersSelector = createSelector(getEventsFilters, (eventsFilters) => eventsFilters);

export const mainComponentsSelector = createSelector(getMainComponents, (components) => components);

export const otherComponentsSelector = createSelector(getOtherComponents, (components) => components);

export const addEmpModalDataSelector = createSelector(getAddEmpModalData, (addEmpModalData) => addEmpModalData);

export const deviceTotalsSelector = createSelector(getDeviceTotals, (deviceTotals) => deviceTotals);

export const deviceStatisticsSelector = createSelector(getDeviceStatistics, (stats) => stats);

export const apiUrlsSelector = createSelector(getApiUrls, (urls) => urls);

export const deviceDataSelector = createSelector(getDeviceData, (deviceData) => deviceData);

export const stringfiltersSelector = createSelector(getStringFilters, (filters) => filters);

export const filtersSelector = createSelector(getFilters, (filters) => filters);

export const currentEventsSelector = createSelector(getCurrentEvents, (currentEvents) => currentEvents);

export const systemDevicesSelector = createSelector(getSystemDevices, (modalAddedDevices) => modalAddedDevices);

export const stepperModalDataSelector = createSelector(getStepperModalData, (stepperModalData) => stepperModalData);
