import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import store from 'store';
import Initializer from './Initializer';
import theme from 'plugins/theme';
import Dashboard from 'pages/Dashboard';
import List from 'pages/List';
import Detail from 'pages/Detail';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './scss/app.scss';

function App() {
	const basename = useMemo(() => {
		if (process.env.NODE_ENV === 'development') {
			return '/';
		}

		const baseUrl = new RegExp(/.*\/mywolf\/[A-Za-z0-9_-]+(?=\/?)/);

		return baseUrl.exec(window.location.pathname)?.[0] || '/';
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ToastContainer limit={1} />
			<Router basename={basename}>
				<Provider store={store}>
					<Initializer>
						<Routes>
							<Route path="/" element={<Dashboard />} />
							<Route path="/list" element={<List />} />
							<Route path="/detail" element={<Detail />} />
							<Route path="/detail/:id" element={<Detail />} />
						</Routes>
					</Initializer>
				</Provider>
			</Router>
		</ThemeProvider>
	);
}

export default App;
