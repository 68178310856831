import React from 'react';
import { ButtonProps } from './Button.d';
export interface ButtonComponentProps extends ButtonProps {

	dataAttributes?: { [x in string]: string };
	classes?: string[];
	modifiers?: string[];
	isLoading?: boolean;

	onButtonClicked: (e: any) => void;
	toggleLoading?: () => void;
}

export class ButtonComponent extends React.Component<ButtonComponentProps, any> {
	private componentName = 'a-button';

	private button: React.RefObject<HTMLAnchorElement>;

	constructor(props: ButtonComponentProps) {
		super(props);
		this.button = React.createRef();
	}

	render(): JSX.Element {
		const href = this.props.href || '';
		const iconName = this.props.icon ? this.props.icon.name : null;
		const target = this.props.target || '_self';
		const title = this.props.title || '';
		const disabled = !!this.props.isDisabled;
		const download = this.props.download || null;
		const dataAttributes = this.props.dataAttributes || {};
		let classes = this.props.classes || [];
		classes = classes.length > 0 ? classes.map((c) => c.toString().trim()).filter((c) => c) : classes;

		let modifiers = this.props.modifiers || [];
		modifiers =
			modifiers.length > 0
				? modifiers
					.map((m) => m.toString().trim())
					.filter((m) => m)
					.map((m) => this.componentName + '--' + m)
				: modifiers;

		return (
			<a
				ref={this.button}
				href={href}
				target={target}
				title={title}
				onClick={this.props.onButtonClicked}
				data-disabled={disabled}
				download={download}
				className={[
					this.componentName,
					'uk-button uk-flex-inline uk-text-center',
					disabled || this.props.isLoading ? 'uk-disabled' : '',
					['primary', 'secondary', 'default', 'ghost'].includes(this.props.type) ? this.componentName + '--' + this.props.type : '',
					this.props.icon && this.props.icon.name ? 'has-icon' : '',
					!this.props.text ? this.componentName + '--icononly' : '',
					this.props.icon && this.props.icon.highlighted ? 'has-icon-highlighted' : '',
					this.props.icon && this.props.icon.position && ['left'].includes(this.props.icon.position) ? 'uk-flex-row-reverse' : '',
					this.props.isActive ? 'uk-active' : '',
					this.props.roundedCorners ? this.componentName + '--rounded' : '',
					['small', 'medium', 'large'].includes(this.props.size || '')
						? this.componentName + '--' + this.props.size
						: this.componentName + '--medium',
					this.props.isResponsive ? this.componentName + '--responsive' : '',
					classes && classes.length > 0 ? classes.join(' ') : '',
					modifiers && modifiers.length > 0 ? modifiers.join(' ') : '',
				]
					.filter((c) => c)
					.join(' ')}
				{...dataAttributes}>
				{this.props.text ? <span className={this.componentName + '__text'}>{this.props.text.trim()}</span> : ''}
				{iconName && !this.props.isLoading ? (
					<span className={[this.componentName + '__icon-wrapper', 'uk-preserve'].join(' ')} data-uk-icon={iconName}></span>
				) : (
					''
				)}
				{this.props.isLoading ? <div data-uk-spinner></div> : ''}
			</a>
		);
	}
}
