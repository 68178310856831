import { Button, TextField, makeStyles } from '@material-ui/core';
import { CheckFilledCircle, PenCircleFilled } from 'assets/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	disabled?: boolean;
}

const useStyles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	systemName: {
		color: '#212d40',
		fontSize: '32px',
		fontFamily: 'Inter',
		fontWeight: 600,
		width: 300,
	},
	nonEditable: {
		width: 'fit-content',
		maxWidth: 600,
		minWidth: 300,
		marginLeft: 16,
		marginRight: 16,
		padding: '1px 14px 1px 14px',
	},
	editNameButton: {
		marginRight: 16,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		width: 24,
		height: 24,
	},
});

function Header({ name, setName, disabled }: HeaderProps): JSX.Element {
	const { t } = useTranslation();
	const classes = useStyles();

	const [editing, setEditing] = useState<boolean>(false);

	return (
		<div className="header">
			{editing ? (
				<div className={classes.container}>
					<TextField
						id="device-name"
						label=""
						value={name}
						onChange={(e) => setName(e.target.value)}
						className={classes.systemName}
						InputProps={{
							disableUnderline: true,
							className: classes.systemName,
						}}
						placeholder={t('FacilityName').toString() + '*'}
						InputLabelProps={{
							shrink: false,
						}}
						disabled={disabled}
					/>
					<Button
						startIcon={<CheckFilledCircle className={classes.icon} />}
						variant="text"
						className={classes.editNameButton}
						color="secondary"
						disabled={disabled}
						disableRipple
						onClick={() => setEditing(false)}>
						{t('Save name')}
					</Button>
				</div>
			) : (
				<div className={classes.container}>
					<span className={`${classes.systemName} ${classes.nonEditable}`}>{name || `${t('FacilityName').toString()}*`}</span>
					<Button
						startIcon={<PenCircleFilled className={classes.icon} />}
						variant="text"
						className={classes.editNameButton}
						color="secondary"
						disableRipple
						disabled={disabled}
						onClick={() => setEditing(true)}>
						{t('Edit name')}
					</Button>
				</div>
			)}
		</div>
	);
}
export default Header;
