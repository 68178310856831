import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	boldLabel: {
		fontWeight: 'bold',
	},
	borderedRow: {
		border: '1px solid #ccc',
		padding: '10px',
		margin: '20px 0',
		borderRadius: '12px',
	},
	formColumn: {
		padding: '10px',
		'& .input-field': {
			width: 'calc(100% - 32px)',
		},
		'& .a-dropdown, & .dropdown': {
			width: '100%',
		},
	},
	rowTitle: {
		fontSize: '16px',
		fontWeight: 'normal',
		marginBottom: '10px',
		marginTop: '-20px',
		'& > span': {
			backgroundColor: '#fff',
			padding: '0 10px',
		},
	},
	toggle: {
		marginLeft: '10px',
	},
}));
