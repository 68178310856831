import DateFnsUtils from '@date-io/date-fns';
import { ListItemIcon, ListItemText, makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import ListEntry from './components/ListEntry';
import { MoleculeHistoryEventListProps } from './HistoryEventList.d';
import { DeviceEventType } from 'types/DeviceEvent';
import getEventMapping from 'services/utils/getEventMapping';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { eventsFiltersSelector } from 'store/modules/heatingdevice/selectors';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	eventTypeIcon: {
		width: '24px',
		height: '24px',
		padding: '4px',
		borderRadius: '8px',
		'& svg': {
			verticalAlign: 'top',
		},
		backgroundColor: '#ededed',
	},
	repair: {
		backgroundColor: '#f6b2b2',
		color: '#e10000',
	},
	installation: {
		backgroundColor: '#d5eacd',
		color: '#73b858',
	},
	commissioning: {
		backgroundColor: '#d5eacd',
		color: '#73b858',
	},
	maintenance: {
		backgroundColor: '#d2dee8',
		color: '#676b8d',
	},
	device_component_maintenance_registered: {
		backgroundColor: '#fef3cb',
		color: '#fcc040',
	},
	failure: {
		backgroundColor: '#f6b2b2',
		color: '#e10000',
	},
}));

function HistoryEventList(props: MoleculeHistoryEventListProps): JSX.Element {
	const { t } = useTranslation();
	const { events, isLoading } = props;
	const dispatch = useDispatch<AppDispatch>();
	const classes = useStyles();
	const eventsFilters = useSelector(eventsFiltersSelector);

	const filters = [
		{ name: t('Installation'), value: DeviceEventType.Installation },
		{ name: t('Device added'), value: DeviceEventType.DeviceAdded },
		{ name: t('Device removed'), value: DeviceEventType.DeviceRemoved },
		{ name: t('Device component added'), value: DeviceEventType.DeviceComponentAdded },
		{ name: t('Device component removed'), value: DeviceEventType.DeviceComponentRemoved },
		{ name: t('Maintenance'), value: DeviceEventType.DeviceComponentMaintenanceRegistered },
	];
	const [reachedEnd, setReachedEnd] = useState<boolean>(false);

	const observer = useRef<IntersectionObserver>();

	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && !isLoading && !reachedEnd && events) {
					dispatch(
						heatingDevicePortalActions.setEventsFilters({
							offset: 0,
							limit: eventsFilters?.limit && eventsFilters?.limit >= events.total ? events.total : (eventsFilters?.limit || 0) + 10,
						}),
					);
				}
			});
			if (node) observer.current.observe(node);
		},
		[reachedEnd, events, eventsFilters?.limit, isLoading, dispatch],
	);

	const handleSearch = (search: string) => {
		dispatch(heatingDevicePortalActions.setEventsFilters({ search }));
	};

	const handleDateChange = (date: MaterialUiPickersDate, field: string) => {
		date?.setDate(1);
		date?.setHours((date.getTimezoneOffset() / 60) * -1, 0, 0, 0);
		if (field === 'from') {
			dispatch(heatingDevicePortalActions.setEventsFilters({ creation_date_from: date?.toISOString() }));
			return;
		}
		date?.setDate(new Date(date?.getFullYear(), date?.getMonth() + 1, 0).getDate());
		date?.setHours((date.getTimezoneOffset() / 60) * -1 + 23, 59, 59, 999);
		dispatch(heatingDevicePortalActions.setEventsFilters({ creation_date_to: date?.toISOString() }));
	};

	const handleFilterChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		dispatch(heatingDevicePortalActions.setEventsFilters({ type: event.target.value as string[] }));
	};

	useEffect(() => {
		if (!events || !events.items.length) {
			setReachedEnd(false);
			return;
		}
		if (events.items.length === events.total) {
			setReachedEnd(true);
		}
	}, [events, eventsFilters?.limit]);

	useEffect(() => {
		dispatch(heatingDevicePortalActions.setEventsFilters({ offset: 0 }));
	}, [eventsFilters?.search, eventsFilters?.creation_date_from, eventsFilters?.creation_date_to, eventsFilters?.type, dispatch]);

	return (
		<div className="m-history-event-list">
			<div className="header-container">
				<Input placeholder={t('Search...').toString()} type="search" setValue={handleSearch} />
				<div className="date-filter-container">
					<Select
						className="select-field"
						multiple
						displayEmpty
						MenuProps={{
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							getContentAnchorEl: null,
						}}
						value={eventsFilters?.type || []}
						onChange={handleFilterChange}
						input={<OutlinedInput />}
						renderValue={(selected: any) => {
							if (selected.length === 0) {
								return <span>Filter</span>;
							}

							return selected
								.map((value: string) => value.split('_').join(' '))
								.map((value: string) => value.charAt(0).toUpperCase() + value.slice(1))
								.map((value: string) => t(value))
								.join(', ');
						}}>
						<MenuItem disabled value="">
							<span>Filter</span>
						</MenuItem>
						{filters.map((filter, index) => {
							return (
								<MenuItem key={index} value={filter.value}>
									<ListItemIcon>
										<span className={`${classes.eventTypeIcon} ${classes[filter.value]}`}>
											{React.createElement(getEventMapping(filter.value).icon)}
										</span>
									</ListItemIcon>
									<ListItemText primary={filter.name} />
								</MenuItem>
							);
						})}
					</Select>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							autoOk
							variant="inline"
							views={['year', 'month']}
							inputVariant="outlined"
							className="date-picker"
							InputLabelProps={{ shrink: false }}
							format="MMM"
							disableToolbar
							openTo="month"
							placeholder={t('Choose date').toString()}
							InputAdornmentProps={{ position: 'start' }}
							maxDate={
								new Date(
									new Date(eventsFilters?.creation_date_to || '').getTime() -
										new Date(eventsFilters?.creation_date_to || '').getTimezoneOffset() * -1 * 60000,
								)
							}
							value={new Date(eventsFilters?.creation_date_from || '')}
							onChange={(date: MaterialUiPickersDate) => handleDateChange(date, 'from')}
						/>
						<KeyboardDatePicker
							autoOk
							variant="inline"
							views={['year', 'month']}
							inputVariant="outlined"
							InputLabelProps={{ shrink: false }}
							format="MMM"
							disableToolbar
							openTo="month"
							placeholder={t('Choose date').toString()}
							InputAdornmentProps={{ position: 'start' }}
							minDate={new Date(eventsFilters?.creation_date_from || '')}
							value={
								new Date(
									new Date(eventsFilters?.creation_date_to || '').getTime() -
										new Date(eventsFilters?.creation_date_to || '').getTimezoneOffset() * -1 * 60000,
								)
							}
							onChange={(date: MaterialUiPickersDate) => handleDateChange(date, 'to')}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>
			<div className="event-list-container">
				{events &&
					events.items.map((event, index) => {
						if (events.items.length === index + 1) return <ListEntry ref={lastElementRef} key={index} event={event} />;
						return <ListEntry key={index} event={event} />;
					})}
				{isLoading && (
					<div className="loading">
						<Skeleton height={120} />
					</div>
				)}
			</div>
		</div>
	);
}

export default HistoryEventList;
