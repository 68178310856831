import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IComponent } from 'types/Component';
import TextLink from 'components/TextLink/TextLink';
import ModalAddWarranty from 'components/ModalAddWarranty';
import { AddDeviceModalData } from 'components/ModalAddWarranty/ModalAddWarranty.d';
import apiBaseUrls from 'constants/apiBaseUrls';
import { isPast } from 'date-fns';
import { IlluMainDevice, WarrantyLogo } from 'assets/icons';
import backboneApi from 'store/modules/backbone';

interface DeviceCard {
	deviceData: Partial<IComponent>;
	onActive: (string) => void;
}

function DeviceCard(props: DeviceCard): JSX.Element {
	const [openWarrantyModal, setOpenWarrantyModal] = useState<boolean>(false);
	const [imageNotFound, setImageNotFound] = useState<boolean>(false);

	const { deviceData, onActive } = props;
	const { t } = useTranslation();

	const { data: extraDeviceData } = backboneApi.useGetComponentDetailsQuery(deviceData.serial || '');

	return (
		<div className="device-card-container" onMouseEnter={() => onActive(deviceData.uuid)}>
			<section className="device-card-section-top">
				<div className="headline">
					<p>{extraDeviceData?.name}</p>
				</div>
				<div className="device-img">
					{!extraDeviceData?.images[0] || imageNotFound ? (
						<IlluMainDevice />
					) : (
						<img
							src={`${apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pim}${extraDeviceData?.images[0]}`}
							onError={() => setImageNotFound(true)}
						/>
					)}
					{deviceData.warranty_end_date && !isPast(new Date(deviceData.warranty_end_date)) ? (
						<WarrantyLogo className="warranty-img" />
					) : null}
				</div>
			</section>
			<section className="device-card-section-bottom">
				<div className="serial-number">
					{`${t('Serial number')}`}
					<span>{deviceData.serial ? deviceData.serial : <TextLink type="underlined_medium" text={'add'} link="#" />}</span>
				</div>
				<div className="installation">
					{`${t('Installation date')}`}
					<span>
						{deviceData.installation_date ? (
							new Date(deviceData.installation_date).toLocaleDateString('de-de', { year: 'numeric', month: '2-digit', day: '2-digit' })
						) : (
							<TextLink type="underlined_medium" text={'add'} link="#" />
						)}
					</span>
				</div>
				<div className="maintenance">
					{`${t('Maintenance')}`}
					<span>
						{deviceData.next_maintenance_date
							? new Date(deviceData.next_maintenance_date).toLocaleDateString('de-de', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
							  })
							: '-'}
					</span>
				</div>
				<div className="warranty">
					{`${t('Warranty')}`}
					<span>
						{deviceData.warranty_end_date ? (
							new Date(deviceData.warranty_end_date).toLocaleDateString('de-de', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
							})
						) : (
							<p className="warranty-text" onClick={() => setOpenWarrantyModal(true)}>
								Add
							</p>
						)}
					</span>
				</div>
				<ModalAddWarranty {...AddDeviceModalData} open={openWarrantyModal} handleClose={() => setOpenWarrantyModal(false)} />
			</section>
		</div>
	);
}

export default DeviceCard;
