import { HeaderProps } from './Header.d';

export const SystemMngHeaderData: HeaderProps = {
	title: 'Anlagenverwaltung',
	searchPlaceholder: 'Search..',
	welcome: 'Guten Abend, Christina',
	background: 'rgba(8, 97, 231, 0.732)',
	muster: 'Mustermann Musterfirma',
};

export const HeaderDetailViewData: HeaderProps = {
	title: 'Anlagenverwaltung',
	showSearchBar: false,
	searchPlaceholder: 'Suche nach Anlagenname',
	background: 'linear-gradient(198deg, rgba(68,89,112,1) 9%, rgba(142,172,198,1) 82%)',
	showBackButton: true,
	showTabs: true,
	titleEditable: true,
};
