import DateFnsUtils from '@date-io/date-fns';
import { Theme, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AddOutlinedCircle, TrashOutlined } from 'assets/icons';
import Input from 'components/Input';
import Toggle from 'components/Toggle';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { v4 as uuidv4 } from 'uuid';
import { IFormGroupComponents } from '../ModalAddSystem.d';
import backboneApi from 'store/modules/backbone';

const useStyles = makeStyles((theme: Theme) => ({
	addDevices: {
		width: '100%',
	},
}));

const AddSubcomponents = (props: any) => {
	const { empty } = props;
	const dispatch = useDispatch<AppDispatch>();
	const classes = useStyles();

	const [forms, setForms] = useState<Array<IFormGroupComponents>>(
		empty
			? []
			: [
				{
					id: uuidv4(),
					serial: '',
					material: true,
					name: '',
					installation_date: new Date().toISOString().split('T')[0],
				},
			],
	);

	const [trigger] = backboneApi.useLazyGetComponentDetailsQuery();

	useEffect(() => {
		props?.saveData(forms);
	}, [forms, props]);

	const handleCreateForm = (event: React.MouseEvent) => {
		event.preventDefault();
		setForms([
			...forms,
			{
				id: uuidv4(),
				serial: '',
				material: true,
				name: '',
				installation_date: new Date().toISOString().split('T')[0],
			},
		]);
	};

	const handleDelete = (index: number) => {
		const newForms = forms.filter((form) => form.id !== forms[index].id);
		setForms(newForms);
		dispatch(heatingDevicePortalActions.removeSystemDevice(index));
	};

	const handleSerialChange = async (index: number, newSerial: string) => {
		const newForms = [...forms];
		newForms[index].serial = newSerial;
		try {
			const data = await trigger(newSerial).unwrap();
			dispatch(heatingDevicePortalActions.addSystemDevice({ ...data, sku: newSerial }));
			newForms[index].name = data.name;
		} catch (e) {
			newForms[index].name = '';
		}
		setForms(newForms);
	};

	const handleNameChange = (index: number, newName: string) => {
		const newForms = [...forms];
		newForms[index].name = newName;
		setForms(newForms);
	};

	const handleInstallationDateChange = (index: number, newDate: any) => {
		const newForms = [...forms];
		newForms[index].installation_date = newDate.toISOString().split('T')[0];
		setForms(newForms);
	};

	const handleMaterialChange = (index: number, newMaterial: boolean) => {
		const newForms = [...forms];
		newForms[index].material = newMaterial;
		setForms(newForms);
	};

	return (
		<div className="add-devices">
			{forms.map((form, index) => (
				<fieldset key={form.id} className="m-form-group">
					<legend className="legend-title">{form.name.length ? form.name : 'Komponente ' + (index + 1)}</legend>
					<div className="formGroupContainer">
						<div className="header">
							<Toggle
								items={[t('Serial number'), t('Material number')]}
								value={form.material}
								setToggle={(material) => handleMaterialChange(index, material)}
							/>
							<TrashOutlined className="trashIcon" onClick={() => handleDelete(index)} />
						</div>
						<div className="inputContainer">
							<div className="row">
								<Input
									placeholder={form.material ? t('Serial number').toString() : t('Material number').toString()}
									type={'label'}
									setValue={(serial) => handleSerialChange(index, serial)}
									label={(form.material ? t('Serial number').toString() : t('Material number').toString()) + ' *'}
									error={props.errors[index]}
									invalid={!!props.errors[index]}
								/>
								<Input
									setValue={(name) => handleNameChange(index, name)}
									value={form.name}
									readOnly={true}
									placeholder={t('Device name').toString()}
									type={'search'}
									label={t('Device name').toString()}
								/>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<div className="dateContainer">
										<label className="input-label">{t('Installation date').toString() + ' *'}</label>
										<KeyboardDatePicker
											autoOk
											variant="inline"
											className="date-picker"
											inputVariant="outlined"
											InputLabelProps={{ shrink: false }}
											format={'dd. MMM yyyy'}
											value={new Date(form.installation_date)}
											maxDate={new Date(new Date().setDate(new Date().getDate() + 14))}
											InputAdornmentProps={{ position: 'start' }}
											onChange={(date) => handleInstallationDateChange(index, date)}
										/>
										{form.installation_date && new Date(form.installation_date).getFullYear() < new Date().getFullYear() - 1 ? (
											<span className="warning">{t('Registration possible, but not valid for loyalty point')}</span>
										) : null}
									</div>
								</MuiPickersUtilsProvider>
							</div>
						</div>
					</div>
				</fieldset>
			))}
			<span className="text-required">{'*' + t('Required')}</span>
			<button className="addFormButton" onClick={handleCreateForm}>
				<AddOutlinedCircle className="addIcon" />
				{forms && forms.length ? t('Add another component') : t('Add Component')}
			</button>
		</div>
	);
};

export default AddSubcomponents;
