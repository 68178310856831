import React from 'react';
import StepFourApproval from './components/StepFourApproval/StepFourApproval';
import { ApprovalProps } from './Approval.d';

function Approval(props: ApprovalProps): JSX.Element {
	return (
		<div className="m-step-four-approval">
			<StepFourApproval data={props} />
		</div>
	);
}

export default Approval;
