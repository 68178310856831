import { colors, palette } from "./colors";
import { INPUT_HEIGHT } from "./constants";

export const MuiButton = {
    root: {
        borderRadius: 0,
        border: 0,
        colorInherit: "#fff",
        color: "white",
        height: INPUT_HEIGHT,
        padding: "16px 40px",
        fontSize: "16px",
        boxShadow: "none",
    },
    contained: {
        boxShadow: "none",
    },
    outlined: {
        color: palette.common.black,
        '& svg': {
            fill: palette.common.black,
        },
        '&:hover, &:focus': {
            color: palette.common.white,
            backgroundColor: palette.primary.main,
            '& svg': {
                fill: palette.common.white,
            },
        },
    }
};

export const MuiFormControl = {
    root: {
        width: '280px',
        marginLeft: 16,
        marginRight: 16,
    },
};

export const MuiSelect = {
    root: {
    },
    select: {
        minWidth: 280,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            borderColor: palette.common.black,
        },
        '&:focus': {
            backgroundColor: 'transparent',
            borderColor: palette.common.black,
            borderWidth: 2,
        },
    },
    selectMenu: {
        height: INPUT_HEIGHT,
        padding: 12,
        paddingRight: 32,
    }
};

export const MuiInput = {
    root: {
        height: INPUT_HEIGHT,
        borderRadius: 0,
        '&:focus': {
            background: 'transparent',
        },
    },
    input: {
        padding: 14,
        '&:hover': {
            borderColor: palette.common.black,
        },
        '&:focus': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
            borderWidth: 2
        },
        "&::placeholder": {
            color: "textSecondary"
        },
    },
};

export const MuiOutlinedInput = {
    root: {
        height: INPUT_HEIGHT,
        borderRadius: 0,
        '&:focus': {
            background: 'transparent',
        },
    },
    input: {
        padding: 14,
        '&:hover': {
            borderColor: palette.common.black,
        },
        '&:focus': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
            borderWidth: 2
        },
        "&::placeholder": {
            color: "textSecondary"
        },
    },
    adornedEnd: {
        paddingRight: 0,

        '& .Mui-disabled > div': {
            borderColor: colors['silver-grey'],
            outline: 'none',
            boxSizing: 'content-box',
        },
    }
};

export const MuiMenu = {
    paper: {
        border: `1px solid ${colors["silver-grey"]}`,
        borderRadius: 0,
        boxShadow: "none",
        minHeight: 228
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 0,
        fontSize: "16px",
        boxShadow: "none",
    }
};

export const MuiMenuItem = {
    root: {
        height: INPUT_HEIGHT,
        fontSize: 16,
    },
};

export const MuiTableCell = {
    root: {
        height: INPUT_HEIGHT,
        padding: '0 8px'
    },
};
