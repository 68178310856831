import React, { useEffect, useState } from 'react';
import { Input, Tab, Tabs, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import type { HeaderProps } from './Header.d';
import { useTranslation } from 'react-i18next';
import { CheckFilledCircle, ImageIcon, NotificationFilled, PenCircleFilled, CloseFilledCircle, ArrowLeftShort, HeatPump } from 'assets/icons';
import { Skeleton } from '@material-ui/lab';
import customersApi from 'store/modules/customers';

const useStyles = makeStyles({
	tab: {
		minWidth: 'auto', // You could also use 0 here.
		flexShrink: 1,
		margin: '0 24px',
		padding: 0,
	},
	indicator: {
		backgroundColor: '#afc4d7 !important',
		height: '2.5px',
	},
});

function SystemHeader({
	title,
	searchPlaceholder,
	welcome,
	showSearchBar = false,
	background,
	muster,
	showBackButton = false,
	showTabs = false,
	titleEditable,
	handleTitleSubmit,
	setViewtab,
}: HeaderProps): JSX.Element {
	const [editingTitle, setEditingTitle] = useState(false);
	const [selectedTab, setSelectedTab] = useState<string>('Detailed View');
	const [loading, setLoading] = useState<boolean>(true);
	const [titleInput, setTitleInput] = useState<string>(title);
	const handleTabChange = (event: React.ChangeEvent, newValue: string) => {
		setSelectedTab(newValue);
		setViewtab && setViewtab(newValue);
	};
	const [goBackHistory, setGoBackHistory] = useState(1);
	const { data: customerData } = customersApi.useGetCustomerInfoQuery({});
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();
	const customerFullName = `${customerData?.first_name ?? ''} ${customerData?.last_name ?? ''}`.trim();

	useEffect(() => {
		setGoBackHistory(window.history.length);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, []);

	useEffect(() => {
		setTitleInput(title);
	}, [title]);

	if(location.pathname === '/list'){
		showBackButton = true;
	}

	return (
		<div className="mywolf-header">
			<div className="view-header header-container" style={{ background: background }}>
				<div className="header-actions">
					<div className="app-bar" style={{ position: 'relative' }}>
						{showBackButton ? (
							<span
								onClick={(e) => {
									window.history.go(goBackHistory - (window.history.length + 1));
									window.history.length;
								}}>
								<ArrowLeftShort className="back-icon" />
							</span>
						) : (
							<HeatPump className="display-icon" />
						)}
						{loading ? (
							<Skeleton width={200} height={30} />
						) : (
							<>
								{editingTitle ? (
									<>
										<Input
											onChange={(e) => {
												setTitleInput(e.target.value);
											}}
											type="text"
											defaultValue={title}
											color="secondary"
											className="app-bar-text"
										/>
										<span
											className="app-bar-text-edit"
											onClick={() => {
												setEditingTitle(false);
												handleTitleSubmit && handleTitleSubmit(titleInput);
											}}>
											<CheckFilledCircle className="edit-icon" />
										</span>
										<span
											className="app-bar-text-edit"
											onClick={() => {
												setEditingTitle(false);
											}}>
											<CloseFilledCircle className="close-icon" />
										</span>
									</>
								) : (
									<>
										<b className="app-bar-text">{titleInput}</b>
										{titleEditable && (
											<span
												className="app-bar-text-edit"
												onClick={() => {
													setEditingTitle(true);
												}}>
												<PenCircleFilled className="edit-icon" />
											</span>
										)}
									</>
								)}
							</>
						)}
					</div>
					{showSearchBar && (
						<div className="search-and-actions">
							<input className="header-searchbar" type="search" placeholder={searchPlaceholder} />
							<ImageIcon className="header-icons" />
							<NotificationFilled className="header-icons" />
						</div>
					)}
				</div>
				{showTabs && (
					<div className="header-tabs">
						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							aria-label="basic tabs example"
							classes={{ indicator: classes.indicator }}>
							<Tab className={classes.tab} label={t('Tab Overview')} value={'Detailed View'} />
							<Tab className={classes.tab} label={t('Tab History')} value={'Historie'} />
							{/* <Tab className={classes.tab} label={t('Tab Remote Controle')} value={'Einstellungen'} /> */}
							{/* to be readded after MVP */}
						</Tabs>
					</div>
				)}
				{(muster || welcome) && (
					<div className="header-title">
						{muster && (
							<div className="muster">
								<b>{muster}</b>
							</div>
						)}
						{welcome && (
							<div className="welcome">
								<h2>{customerFullName ? `${t('Welcome')}, ${customerFullName}` : t('Welcome')}</h2>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default SystemHeader;
