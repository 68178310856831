import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
.use(initReactI18next)
.init({
  fallbackLng: 'de',
  debug: false,
  react: {
    useSuspense: false
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});