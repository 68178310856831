import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'store';
import apiBaseUrls from 'constants/apiBaseUrls';
import pimcoreApi from '../pimcore';
import { heatingDevicePortalActions } from '../heatingdevice/reducer';

const baseQueryFactory = async (api, baseUrl: string) => {
	let apiToken = (api.getState() as RootState).heatingdevice.token;

	if (!apiToken) {
		const { data: tokenResponse } = await api.dispatch(pimcoreApi.endpoints.getToken.initiate('')).unwrap();
		if (tokenResponse && tokenResponse.token) {
			api.dispatch(heatingDevicePortalActions.setToken(tokenResponse.token));
			apiToken = tokenResponse.token;
		}
	}

	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${apiToken}`);
			return headers;
		},
	});
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].sap);

	let result = await baseQuery(args, api, extraOptions);
	if (result.error && (result.error.status === 'FETCH_ERROR' || result.error.status === 'PARSING_ERROR')) {
		baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].sap);
		result = await baseQuery(args, api, extraOptions);
	}
	return result;
};

export const sapApi = createApi({
	reducerPath: 'sapApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getSerial: builder.query<SAPSuccessResponse, string>({
			query: (serial: string) => ({
				url: `/serial/${serial}`,
				method: 'GET',
			}),
		}),
	}),
});

export const { getSerial } = sapApi.endpoints;

export default sapApi;
