import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	selectIcon: {
		width: 16,
		height: 16,
	},
	switch: {
		width: 64,
		height: 32,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform: 'translateX(32px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: '#2ECA45',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 28,
			height: 28,
		},
		'& .MuiSwitch-track': {
			borderRadius: 32 / 2,
			backgroundColor: '#c7cacf',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	},
}));
