import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Close } from 'assets/icons';

import { useTranslation } from 'react-i18next';
import { Theme, makeStyles } from '@material-ui/core';
import { Maintenance } from 'types/Maintenance';
import pimApi from 'store/modules/pim';
import DeviceImage from './DeviceImage';
import backboneApi from 'store/modules/backbone';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: 20,
		width: window.innerWidth * 0.3,
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: 24,
	},
	actionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	actionIcon: {
		width: 18,
		height: 18,
		color: '#212d40',
		marginLeft: 20,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		padding: 36,
		fontFamily: 'Inter',
		fontSize: 32,
		fontWeight: 600,
		textAlign: 'center',
		color: '#212d40',
	},
	subtitle: {
		fontFamily: 'Inter',
		fontSize: 24,
		fontWeight: 600,
		textAlign: 'left',
		color: '#404a59',
	},
	info: {
		fontFamily: 'Inter',
		fontSize: 18,
		fontWeight: 500,
		textAlign: 'left',
		color: '#404a59',
	},
	imageContainer: {
		marginLeft: '30%',
		marginRight: '30%',
	},
	darkRow: {
		display: 'flex',
		backgroundColor: '#f8f8fa',
		padding: 16,
		borderRadius: 12,
		justifyContent: 'space-between',
	},
	lightRow: {
		display: 'flex',
		backgroundColor: '#fff',
		padding: 16,
		borderRadius: 12,
		justifyContent: 'space-between',
	},
}));

const ModalMaintenanceDetails = (props: { open: boolean; handleClose: () => void; maintenance: Maintenance | null }) => {
	const { t } = useTranslation();

	const { open, handleClose } = props;
	const classes = useStyles();

	const { data: extraDeviceData } = backboneApi.useGetComponentDetailsQuery(props.maintenance?.number || '');

	const onModalClose = () => {
		handleClose();
	};

	return (
		<div>
			<Modal className={`${classes.modal} notes-modal`} open={open} onClose={onModalClose} container={document.getElementById('root')}>
				<div className={`${classes.paper}`}>
					<div className={classes.header}>
						<div className={classes.actionButtons}>
							<Close className={classes.actionIcon} onClick={onModalClose} />
						</div>
					</div>
					<span className={classes.title}>{t('Maintenance Report')}</span>
					<span className={classes.subtitle}>{extraDeviceData?.name || '-'}</span>
					<span className={classes.info}>SN: {props.maintenance?.number || '-'}</span>
					<div className={classes.imageContainer}>
						<DeviceImage sku={props.maintenance?.number || ''} />
					</div>
					<span className={classes.info}>{t('Maintenance kit')}</span>
					<br></br>
					<div className={classes.darkRow}>
						<div>{t('Serial / Material number')}:</div>
						<div>{props.maintenance?.number}</div>
					</div>
					<div className={classes.lightRow}>
						<div>{t('Performed by')}</div>
						<div>{props.maintenance?.creator_user_uuid}</div>
					</div>
					<div className={classes.darkRow}>
						<div>{t('Maintenance date')}:</div>
						<div>
							{props.maintenance
								? new Date(props?.maintenance?.date).toLocaleDateString('de-DE', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
								  })
								: '-'}
						</div>
					</div>
					<div className={classes.lightRow}>
						<div>{t('Report date')}</div>
						<div>
							{props.maintenance
								? new Date(props?.maintenance?.creation_date).toLocaleDateString('de-DE', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
								  })
								: '-'}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ModalMaintenanceDetails;
