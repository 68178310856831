import React, { useState } from 'react';
import { FormGroupProps } from 'components/types';
import AtomToggleReact from './Toggle';
import Input from 'components/Input';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';

function FormGroup(props: FormGroupProps): JSX.Element {
	const { formGroupTitle, toggle, inputSerialNumber, inputMaterialNumber, id, maintenanceDateLabel } = props;

	const { t } = useTranslation();

	const [maintenanceConfirmed, setMaintenanceConfirmed] = React.useState(false);
	const [toggleValue, setToggleValue] = useState<boolean>(true);
	const [selectedInstallationDate, setSelectedInstallationDate] = useState<Date | null>(new Date());

	return (
		<fieldset className="m-form-group">
			<legend className="legend-title">{formGroupTitle}</legend>
			<div className="formGroupContainerComponents">
				<div className="header">
					<AtomToggleReact {...toggle} value={toggleValue} setToggle={setToggleValue} />
				</div>
				<div className="inputContainer">
					<div className="row">
						{toggleValue ? (
							<Input name={`${id}-serialNumber`} placeholder="" type="default" {...inputSerialNumber} />
						) : (
							<Input name={`${id}-materialNumber`} placeholder="" type="default" {...inputMaterialNumber} />
						)}
					</div>
					<div className="row-single">
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<div className="dateContainer">
								<label className="input-label">{maintenanceDateLabel}</label>
								<KeyboardDatePicker
									autoOk
									variant="inline"
									inputVariant="outlined"
									InputLabelProps={{ shrink: false }}
									format="dd. MMM yyyy"
									value={selectedInstallationDate}
									InputAdornmentProps={{ position: 'start' }}
									onChange={(date) => setSelectedInstallationDate(date)}
									name={`${id}-maintenanceDate`}
								/>
							</div>
						</MuiPickersUtilsProvider>
					</div>
					<div className="confirmation">
						<h2 className="warning">{t('WARTUNGSBESTÄTIGUNG')}</h2>
						<Checkbox
							id="checkbox"
							label={
								<>
									{t(
										'Hiermit bestätige ich, dass ich die Wartung für das o.g. Gerat gemaß der von WOLF bereit gestellten',
									).toString()}
									&nbsp;
									<a className="checklist" href="https://www.wolf.eu/de-de/mywolf/shk-wartungslisten">
										{t('Checkliste')}
									</a>
									,&nbsp;
									{t('mit einem original WOLF Wartungskit durchgführt habe.').toString()}
								</>
							}
							name={`${id}-maintenanceConfirmed`}
							checked={maintenanceConfirmed}
							value={`${id}-maintenanceConfirmed`}
							onChange={(e) => setMaintenanceConfirmed(!maintenanceConfirmed)}
						/>
					</div>
				</div>
			</div>
		</fieldset>
	);
}

export default FormGroup;
