import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerDataProps } from './CustomerData.d';
import Checkbox from 'components/Checkbox';
import CustomerForm from './components/CustomerForm';
import AddressForm from './components/AddressForm';

export default function CustomerData({
	handleChange,
	deviceAddress,
	data,
	nextPressed,
	handleValidation,
}: CustomerDataProps): JSX.Element {
	const [addressIsDifferent, setAddressIsDifferent] = useState((data && data[0]?.alternative_location) || false);
	const [err, setErr] = useState<any>({});
	const { t } = useTranslation();

	useEffect(() => {
		if (!(data && data.length) || !handleValidation || !nextPressed) {
			return;
		}
		handleValidation(validateData(data[0]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, handleValidation, nextPressed]);

	const parseNestObject = (obj) => {
		const result = {};
		for (const key in obj) {
			const keys = key.split('.');
			let current = result;
			for (let i = 0; i < keys.length; i++) {
				if (i === keys.length - 1) {
					current[keys[i]] = obj[key];
				} else {
					current[keys[i]] = current[keys[i]] || {};
				}
				current = current[keys[i]];
			}
		}
		return result;
	};

	const validateData = (data: any) => {
		// eslint-disable-next-line prefer-const
		let err: any = { address: {}, location: {} };
		const tempData: any = parseNestObject(data);
		if (!tempData.firstname) {
			err.firstname = t('First name is required');
		}
		if (!tempData.lastname) {
			err.lastname = t('Last name is required');
		}
		if (!tempData.email || !tempData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
			err.email = t('A valid email is required');
		}
		if (!tempData.salutation) {
			err.salutation = t('A valid salutation is required');
		}
		if (!tempData.address.city) {
			err.address.city = t('City is required');
		}
		if (!tempData.address.country) {
			err.address.country = t('Country is required');
		}
		if (!tempData.address.house_number) {
			err.address.house_number = t('House number is required');
		}
		if (!tempData.address.street) {
			err.address.street = t('A valid street name is required');
		}
		if (!tempData.address.zip_code || !tempData.address.zip_code.match(/^[0-9]+$/)) {
			err.address.zip_code = t('A valid zip code is required');
		}
		if (
			err.firstname ||
			err.lastname ||
			err.email ||
			err.phone ||
			err.address.city ||
			err.address.country ||
			err.address.house_number ||
			err.address.street ||
			err.address.zip_code ||
			err.location.city ||
			err.location.country ||
			err.location.house_number ||
			err.location.street ||
			err.location.zip_code
		) {
			setErr(err);
			return false;
		}
		setErr({});
		return true;
	};

	return (
		<div className="m-system-management-customer-data">
			<CustomerForm customerData={data && data[0]} handleChange={handleChange} err={err} />
			<AddressForm
				formId="customer"
				formGroupHeader="Residence"
				customerData={data && data[0]}
				handleChange={handleChange}
				automatic={data && data[0]?.google_address}
				err={err.address}
			/>

			<Checkbox
				label={t('Device address is different than customer address').toString()}
				id="addressIsDifferent"
				name="alternative_location"
				value={addressIsDifferent.toString()}
				checked={addressIsDifferent}
				onChange={(e) => {
					setAddressIsDifferent(e.target.checked);
				}}
			/>

			{addressIsDifferent && (
				<AddressForm
					formId="device"
					formGroupHeader="Customer Data System Location"
					customerData={data && data[0]}
					automatic={data && data[0].google_location}
					deviceAddress={deviceAddress}
					handleChange={handleChange}
					err={err.location}
				/>
			)}
		</div>
	);
}
