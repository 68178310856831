import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: 20,
		width: window.innerWidth * 0.3,
	},
	expanded: {
		height: '100%',
		maxWidth: 'unset',
		width: '70%',
		borderRadius: 'unset',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: 24,
	},
	deleteNote: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	trashIcon: {
		width: 24,
		height: 24,
		padding: 3,
		color: '#6b718b',
	},
	deleteText: {
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'left',
		color: '#6b718b',
	},
	actionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	actionIcon: {
		width: 18,
		height: 18,
		color: '#212d40',
		marginLeft: 20,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	contentExpanded: {
		padding: '0 15%',
	},
	title: {
		padding: 36,
		fontFamily: 'Inter',
		fontSize: 32,
		fontWeight: 600,
		textAlign: 'center',
		color: '#212d40',
	},
	separator: {
		width: 'calc(100% + 64px)',
		marginLeft: '-32px',
	},
	titleExpanded: {
		padding: 'unset',
	},
	modalInfo: {
		width: '80%',
		maxWidth: '32em',
		margin: '0 auto',
		fontFamily: 'Inter',
		lineHeight: 1.31,
		textAlign: 'center',
		color: '#6b718b',
		flex: 3,
	},
	info: {
		fontFamily: 'Inter',
		fontSize: 16,
		textAlign: 'left',
		color: '#a2a2a2',
	},
	attachments: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
	},
	attachmentLabel: {
		fontFamily: 'Inter',
		fontSize: 16,
		textAlign: 'left',
		color: '#6b718b',
		cursor: 'pointer',
	},
	attachmentChip: {
		display: 'flex',
		justifyContent: 'left',
		width: 'fit-content',
		maxWidth: '100%',
		marginTop: 16,
		marginRight: 16,
		borderRadius: 6,
		backgroundColor: '#f8f8fa',
	},
	deleteIcon: {
		height: 8,
		color: '#212d40',
	},
	addAttachment: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 16,
		cursor: 'pointer',
		'& > svg': {
			width: 14,
			height: 14,
			marginRight: 6,
			marginLeft: 'unset',
			color: '#6b718b',
		},
	},
	addIcon: {
		color: '#436add',
		width: 96,
		height: 96,
	},
	submit: {
		display: 'flex',
		justifyContent: 'center',
		margin: 32,
	},
	dragAndDrop: {
		display: 'flex',
		flex: 1,
		marginTop: 24,
		padding: '24px 0',
		borderRadius: 12,
		justifyContent: 'center',
		gap: 24,
		outline: 'dashed 2px #dfe0eb',
	},
	dragAndDropText: {
		userSelect: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'& > span:first-child': {
			fontFamily: 'Inter',
			fontWeight: 500,
			lineHeight: 1.31,
			textAlign: 'left',
			color: '#212d40',
		},
		'& > span': {
			fontFamily: 'Inter',
			fontWeight: 500,
			lineHeight: 1.31,
			textAlign: 'left',
			color: '#c7cacf',
		},
	},
	uploadLabel: {
		fontFamily: 'Inter',
		lineHeight: 1.31,
		textAlign: 'left',
		color: '#6b718b',
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},
	},
}));

export default useStyles;
