import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import useStyles from './ModalNotes.styles';
import { ModalNotesProps } from './ModalNotes.d';
import { AddOutlinedCircle, Close, Expand, IllDragDrop, TrashOutlined } from 'assets/icons';
import WrappedInput from 'components/Input/Input';
import { Chip } from '@material-ui/core';
import { ButtonComponent } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import backboneApi from 'store/modules/backbone';
import { useParams } from 'react-router-dom';
import { Note, NotePayload } from 'components/WidgetNotes/WidgetNotes.d';
import BlobStorageService from 'services/api/BlobStorageService';
import attachmentsApi from 'store/modules/attachments';
import showToast from 'services/utils/toaster';

const ModalNotes = (props: ModalNotesProps) => {
	const { t } = useTranslation();

	const { open, note, handleClose } = props;
	const classes = useStyles();
	const routeParams = useParams();
	const { id } = routeParams;

	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
	const [fileList, setFileList] = useState<string[]>(note?.attachments || []);
	const [files, setFiles] = useState<File[]>([]);
	const [title, setTitle] = useState<string>('');
	const [text, setText] = useState<string>('');
	const [blobStorage, setBlobStorage] = useState<BlobStorageService | null>(null);

	const [trigger] = backboneApi.useLazyGetSystemNotesQuery();
	const [createNote, { isSuccess: noteCreated }] = backboneApi.usePostSystemNotesMutation();
	const [patchNote, { isSuccess: notePatched }] = backboneApi.usePatchSystemNotesMutation();
	const [deleteNote, { isSuccess: noteDeleted }] = backboneApi.useDeleteSystemNotesMutation();

	const { data: credentials } = attachmentsApi.useGetBlobStorageCredentialsQuery('');

	const onModalClose = () => {
		setTitle('');
		setText('');
		setFileList([]);
		setFiles([]);
		handleClose();
	};

	const onDelete = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		note && deleteNote({ systemId: id!, noteId: note.uuid });
	};

	const populateFileState = (files: File[]) => {
		const fileList = files.map((file) => file.name);
		const filesData = Array.from(files);
		setFiles((current) => [...current, ...filesData]);
		setFileList((current) => [...current, ...fileList]);
	};

	const handleFiles = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const files = ev.target.files;
		if (files) {
			populateFileState([...files]);
		}
	};

	const handleDownloadFile = (fileName: string | undefined) => {
		if (!blobStorage) {
			showToast.error(t('Unable to connect to Azure Blob Storage'));
			return;
		}
		fileName &&
			note?.uuid &&
			blobStorage?.downloadBlob(note?.uuid, fileName).then((res) => {
				if (!res) return;
				const link = document.createElement('a');
				link.href = URL.createObjectURL(res);
				link.download = fileName;
				link.click();
			});
	};

	const handleDeleteFile = (fileName: string | undefined) => {
		if (!fileName || !note?.uuid) return;
		if (!blobStorage) {
			showToast.error(t('Unable to connect to Azure Blob Storage'));
			return;
		}
		blobStorage?.deleteBlob(note.uuid, fileName).then((res) => {
			setFileList((current) => current.filter((file) => file !== fileName));
			setFiles((current) => current.filter((file) => file.name !== fileName));
		});
	};

	const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault();
	};

	const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault();
		const files = ev.dataTransfer.files;
		if (files) {
			const allowedTypes = ['image/*', '.pdf'];
			const filteredFiles = Array.from(files).filter((file) => {
				const fileType = file.type;
				const fileExtension = file.name.split('.').pop();
				return allowedTypes.includes(fileType) || allowedTypes.includes(`.${fileExtension}`);
			});
			populateFileState(filteredFiles);
		}
	};

	const handleSubmit = async (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		const payload: NotePayload = {
			title,
			text,
			is_important: false,
			is_repair: false,
			is_maintenance: false,
		};

		let response: Note = {} as Note;
		if (note) {
			try {
				response = await patchNote({
					systemId: id!,
					noteId: note.uuid,
					payload,
				}).unwrap();
			} catch (e) {
				showToast.error(e.message);
			}
		} else {
			try {
				response = await createNote({ systemId: id!, payload }).unwrap();
			} catch (e) {
				showToast.error(e.message);
			}
		}

		response.uuid &&
			files.forEach((file) => {
				blobStorage?.uploadBlob(response.uuid, file.name, file).then((res) => {
					trigger(id!);
				});
			});
	};

	useEffect(() => {
		if (credentials && open) {
			const { container_name, connection_string } = credentials;
			try {
				const BlobStorage = new BlobStorageService(connection_string, container_name);
				setBlobStorage(BlobStorage);
			} catch (err) {
				showToast.error(err.message);
			}
		}
	}, [credentials, open]);

	useEffect(() => {
		if (!note) {
			setTitle('');
			setText('');
			setFileList([]);
			setFiles([]);
			return;
		}

		setTitle(note.title);
		setText(note.text);
		const fileList = note.attachments ? note.attachments.map((attachment: string) => attachment.split('/').pop() || attachment) : [];
		setFileList(fileList);
	}, [note, open]);

	useEffect(() => {
		if (noteDeleted) {
			setDeleteDialogOpen(false);
			onModalClose();
		}
		if (noteCreated || notePatched) {
			onModalClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noteDeleted, noteCreated, notePatched]);

	return (
		<div>
			<Modal className={`${classes.modal} notes-modal`} open={open} onClose={onModalClose} container={document.getElementById('root')}>
				<div className={`${classes.paper} ${isExpanded && classes.expanded}`}>
					<div className={classes.header}>
						<div className={classes.deleteNote} onClick={() => setDeleteDialogOpen(true)}>
							{note && (
								<>
									<TrashOutlined className={classes.trashIcon} />
									<span className={classes.deleteText}>{t('Delete note')}</span>
								</>
							)}
						</div>
						<div className={classes.actionButtons}>
							<Expand className={classes.actionIcon} onClick={() => setIsExpanded((current) => !current)} />
							<Close className={classes.actionIcon} onClick={onModalClose} />
						</div>
					</div>
					<span className={`${classes.title} ${isExpanded && classes.titleExpanded}`}>{note ? t('Edit note') : t('Add note')}</span>
					{isExpanded && <hr className={classes.separator} />}
					<div className={`${classes.content} ${isExpanded && classes.contentExpanded}`}>
						{isExpanded && (
							<>
								<span className={classes.modalInfo}>
									Dokumentieren Sie ihre Wartungsarbeiten, Installationen oder Einbausituationen vor Ort. So haben Sie alles immer
									griffbereit.
								</span>
							</>
						)}
						<WrappedInput type="default" style={{ flex: 1 }} placeholder={t('Add title').toString()} value={title} setValue={setTitle} />
						<WrappedInput
							type="textarea"
							style={{ flex: 6 }}
							placeholder={t('Add description').toString()}
							value={text}
							setValue={setText}
						/>
						<div className={classes.attachments}>
							{fileList.length
								? fileList.map((attachment: string, index: number) => {
										return (
											<Chip
												key={`${attachment}-${index}`}
												label={<span className={classes.attachmentLabel}>{attachment}</span>}
												className={classes.attachmentChip}
												onClick={() => handleDownloadFile(attachment)}
												deleteIcon={<Close className={classes.deleteIcon} />}
												onDelete={() => handleDeleteFile(attachment)}
											/>
										);
								  })
								: null}
							{!isExpanded && (
								<div className={classes.addAttachment}>
									<AddOutlinedCircle className={classes.actionIcon} />
									<label htmlFor="file-upload" className={classes.attachmentLabel}>
										{t('Attach a file')}
									</label>
									<input id="file-upload" type="file" accept="image/*,.pdf" onChange={handleFiles} multiple />
								</div>
							)}
						</div>
						{isExpanded && (
							<div className={classes.dragAndDrop} onDragOver={handleDragOver} onDrop={handleDrop}>
								<IllDragDrop className={classes.addIcon} />
								<div className={classes.dragAndDropText}>
									<span>{t('Drag files here')}</span>
									<span>{t('or')}...</span>
									<label htmlFor="file-upload-expanded" className={classes.uploadLabel}>
										{t('choose a file')}
									</label>
									<input id="file-upload-expanded" type="file" accept="image/*,.pdf" onChange={handleFiles} multiple />
								</div>
							</div>
						)}
						<div className={classes.submit}>
							<ButtonComponent type="primary" text={t('Save changes')} title={t('Save changes')} onButtonClicked={handleSubmit} />
						</div>
					</div>
				</div>
			</Modal>
			<ConfirmationDialog
				open={deleteDialogOpen}
				title={`${t('Delete note')}?`}
				content={t('Are you sure?')}
				onConfirm={onDelete}
				onCancel={(ev: React.MouseEvent) => {
					ev && ev.preventDefault();
					setDeleteDialogOpen(false);
				}}
			/>
		</div>
	);
};

export default ModalNotes;
