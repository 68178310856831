import Button from '@material-ui/core/Button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FloatingButtonProps, IFloatingMenuButton } from './FloatingButton.d';

function FloatingButton(props: FloatingButtonProps): JSX.Element {
	const { menuButtons, text, icon, type, disabled, onClick, bottom } = props;

	const floatingMenuRef = useRef<HTMLDivElement>(null);
	const floatingButtonRef = useRef<HTMLButtonElement>(null);
	const [menuVisible, setMenuVisible] = useState(false);

	const closeMenu = () => {
		floatingMenuRef.current ? (floatingMenuRef.current.style.visibility = 'hidden') : null;
		floatingButtonRef.current ? floatingButtonRef.current.classList.remove('floating-button-menu__transition') : null;
	};

	const openMenu = useCallback(() => {
		if (!disabled) {
			floatingButtonRef.current ? floatingButtonRef.current.classList.add('floating-button-menu__transition') : null;
			floatingMenuRef.current ? (floatingMenuRef.current.style.visibility = 'visible') : null;
		}
	}, [disabled]);

	const onMenuLeave = () => {
		window.innerWidth > 960 ? setMenuVisible(false) : setTimeout(() => setMenuVisible(false), 300);
	};

	const onButtonEnter = () => {
		setMenuVisible((current) => !current);
	};

	useEffect(() => {
		menuVisible ? openMenu() : closeMenu();
	}, [menuVisible, openMenu]);

	return (
		<div className="a-floating-button">
			{
				{
					default: (
						<>
							<button
								type="button"
								style={{ bottom: bottom }}
								className={`floating-button ${disabled ? 'disabled' : ''}`}
								onClick={!disabled ? onClick : undefined}>
								<span className="a-floating-button__text">{text}</span>
								<span className="a-floating-button__icon">{icon}</span>
							</button>
						</>
					),
					menu: (
						<>
							<div
								ref={floatingMenuRef}
								tabIndex={0}
								className="a-floating-button__menu"
								onPointerLeave={onMenuLeave}
								onTouchEnd={onMenuLeave}>
								{menuButtons &&
									menuButtons.map((button: IFloatingMenuButton, index: number) => {
										return (
											<Button
												key={index}
												onClick={button.onClick}
												className="menu-button"
												disabled={button.disabled}
												// refactor to use new icons
												endIcon={button.icon}>
												{button.text}
											</Button>
										);
									})}
							</div>
							<button
								ref={floatingButtonRef}
								type="button"
								onPointerEnter={onButtonEnter}
								className={`floating-button-menu ${disabled ? 'disabled' : ''}`}>
								<span className="a-floating-button__icon">{icon}</span>
							</button>
						</>
					),
				}[type]
			}
		</div>
	);
}

export default FloatingButton;
