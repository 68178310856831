import React, { useEffect, useState } from 'react';
import InputLabel from 'components/InputLabel/InputLabel';
import ReactValidationError from 'components/ValidationError/ValidationError';
import { DropdownProps } from './Dropdown.d';

function Dropdown(props: DropdownProps): JSX.Element {
	const {
		label,
		name,
		items,
		placeholder,
		feedback,
		feedbackValue,
		disabled,
		tooltip,
		error,
		invalid,
		width,
		defaultValue,
		setOption,
		onChange,
		dropdownPosition,
		setFormTouched,
	} = props;

	const [displayOptions, setDisplayOptions] = useState(false);
	const [dropdownText, setDropdownText] = useState('');
	const [isInputValid, setIsInputValid] = useState(false);
	const [displayFeedback, setDisplayFeedback] = useState(false);
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		if (defaultValue) {
			const defaultItem = Object.values(items).find((element) => element.value === defaultValue);
			setDropdownText(defaultItem?.text || '');
			setOption?.(defaultItem?.value || '');
			setIsInputValid(true);
		} else {
			setDropdownText('');
			setOption?.('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	useEffect(() => {
		if (items?.length) {
			const defaultItem = Object.values(items).find((element) => element.value === defaultValue);

			setDropdownText(defaultItem?.text || '');
			setOption?.(defaultItem?.value || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items?.length]);

	const handleDropdownClick = (e) => {
		setDisplayOptions(!displayOptions);
	};

	const handleDropdownBlur = (e) => {
		if (!touched) {
			setFormTouched?.();
			setTouched(true);
		}
		setDisplayOptions(false);
		if (e.target.value.length) setIsInputValid(true);
	};

	const handleOptionClick = (text: string, value: string | number) => {
		setDropdownText(text);
		setDisplayFeedback(true);
		setOption?.(value);
		onChange?.(value);
	};

	return (
		<div className="a-dropdown">
			<div className="dropdown">
				<InputLabel text={label || ''} tooltip={tooltip || undefined} />
				<div className="dropdown-wrapper">
					<div className="input-wrapper">
						<input
							className={`input-field ${invalid ? 'input-invalid' : ''} ${isInputValid ? 'valid' : ''}`}
							style={width ? { width: width - 32 } : {}}
							type="text"
							placeholder={placeholder}
							value={dropdownText}
							name={name}
							onClick={handleDropdownClick}
							onBlur={handleDropdownBlur}
							disabled={disabled}
							readOnly
							required
						/>
						<button className={`${displayOptions ? 'arrow-up-icon' : 'arrow-down-icon'}`}></button>
						{feedback?.length && feedbackValue?.length ? (
							<div className={displayFeedback ? '' : 'hide'}>
								<span className="feedback">{feedback}&nbsp;</span>
								<span className="feedback feedback-value">{feedbackValue}</span>
							</div>
						) : null}
					</div>
					<div
						id="myDropdown"
						className={`dropdown-content ${displayOptions ? 'show' : ''} ${dropdownPosition === 'top' ? 'dropup' : ''}`}
						style={width ? { minWidth: width } : {}}>
						{items?.length
							? items.map((item, index) => (
								<a
									key={`optionText${index}`}
									rel={String(item.value)}
									onMouseDown={() => handleOptionClick(item.text, item.value)}>
									{item.text}
								</a>
							))
							: null}
					</div>
				</div>
				<ReactValidationError error={error || ''} />
			</div>
		</div>
	);
}
export default Dropdown;
