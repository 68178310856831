import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactValidationError from 'components/ValidationError/ValidationError';
import { ComplexVdi, LockpadFilled, TrashOutlined } from 'assets/icons';

const Approval = (props: any) => {
	const [firstConsent, setFirstConsent] = useState(false);
	const [secondConsent, setSecondConsent] = useState(false);
	const [thirdConsent, setThirdConsent] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		props?.saveData({
			firstConsent,
			secondConsent,
			thirdConsent,
			uploadedFiles,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstConsent, secondConsent, uploadedFiles]);

	const handleUploadFiles = (files: any) => {
		const uploaded: any = [...uploadedFiles];
		files.some((file: any) => {
			if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
				uploaded.push(file);
			}
		});
		setUploadedFiles(uploaded);
	};

	const handleSelectedFileEvent = (e: any) => {
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		handleUploadFiles(chosenFiles);
	};

	const dropHandler = (ev: any) => {
		ev.preventDefault();
		const dragedFiles = [...ev.dataTransfer.files];
		handleUploadFiles(dragedFiles);
	};

	const dragOverHandler = (ev: any) => {
		ev.preventDefault();
	};

	const handleDeleteFile = (file: any) => {
		const newUploadedFiles = uploadedFiles.filter((f: any) => f.name !== file.name);
		setUploadedFiles(newUploadedFiles);
	};

	return (
		<div className="step-approval-container">
			<div className="step-approval-content">
				<fieldset className="sections-container">
					<legend>{t('Consents').toString()}</legend>
					<div className="err-container">
						<ReactValidationError error={!firstConsent ? '* ' + t('Required').toString() : ''} />
					</div>
					<label className="consent-label" htmlFor="first">
						<span className="checkbox-input">
							<input
								type="checkbox"
								name="first"
								id="first"
								checked={firstConsent}
								onChange={() => setFirstConsent(!firstConsent)}
								required
							/>
						</span>
						<span className="consent-text">
							{`${t(
								'I want to apply for the 5-year warranty for my customer and confirm that I have informed him about the warranty conditions and the data protection declaration',
							)}.`}
						</span>
					</label>
					<div className="err-container">
						<ReactValidationError error={!secondConsent ? '* ' + t('Required').toString() : ''} />
					</div>
					<label className="consent-label" htmlFor="second">
						<span className="checkbox-input">
							<input
								type="checkbox"
								name="second"
								id="second"
								checked={secondConsent}
								onChange={() => setSecondConsent(!secondConsent)}
								required
							/>
						</span>
						<span className="consent-text">
							{`${t(
								"I hereby confirm that I have the customer's consent to pass on the data collected using this registration form to WOLF GmbH. The data is used, stored and managed by WOLF GmbH to process the 5-year guarantee",
							)}.`}
						</span>
					</label>
					<label className="consent-label" htmlFor="third">
						<span className="checkbox-input">
							<input type="checkbox" name="third" id="third" checked={thirdConsent} onChange={() => setThirdConsent(!thirdConsent)} />
						</span>
						<span className="consent-text">
							{`${t(
								'I agree that WOLF GmbH may use my data collected using this registration form for advertising purposes. If I do not give my consent or revoke it, this will have no effect on the registration for the 5-year guarantee',
							)}.`}
						</span>
					</label>
				</fieldset>
				<fieldset className="sections-container">
					<legend>{t('Upload contract').toString()}</legend>
					<div className="approval-upload-container">
						<label onDrop={dropHandler} onDragOver={dragOverHandler} className="file-upload">
							<input type="file" accept="application/pdf" onChange={handleSelectedFileEvent} multiple />
							<ComplexVdi className="upload-icon" />
							<div className="file-text-container">
								<span className="file-text">{t('Drag files here').toString()}</span>
								<span className="file-text">{t('or').toString()}</span>
								<span className="file-text underline">{t('Choose files').toString()}</span>
							</div>
						</label>
					</div>
					<div className="err-container">
						<ReactValidationError error={!uploadedFiles.length ? '* ' + t('Required').toString() : ''} />
					</div>
					<div className="encrypted-section">
						<span className="encrypted-text">{t('Maximum file size: 10MB').toString()}</span>
						<div className="encrypted-container">
							<span className="encrypted-text">{t('Encrypted').toString()}</span>
							<LockpadFilled className="icon-size" />
						</div>
					</div>
					<div className="chosen-file-section">
						{uploadedFiles.map((file: any, idx) => (
							<div key={idx} className="chosen-file">
								<span className="chosen-file-name">{file.name}</span>
								<span className="chosen-file-size">
									{file.size && (file.size / (1024 * 1024)).toFixed(2)} Megabyte
									<div className="delete-icon" onClick={() => handleDeleteFile(file)}>
										<TrashOutlined />
									</div>
								</span>
							</div>
						))}
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default Approval;
