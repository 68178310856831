export interface MoleculeModalFormProps {
	id: string;
	headline: string;
	formUrl: string;
	content: string;
	eventNameSuccess?: string;
	eventNameFailure?: string;
	alertItems?: Array<any>;
	children?: JSX.Element | Array<JSX.Element>;
	handleFormSubmit?: ((ev: SubmitEvent) => any);
}

const MyMoleculeModalForm: MoleculeModalFormProps = {
	id: 'modal-form',
	headline: 'Änderung beantragen',
	formUrl: 'https://fetch.mock/m-modal-form/update',
	content: '',
	eventNameSuccess: 'success',
	eventNameFailure: 'failure',
};
export default MyMoleculeModalForm;

export const MyMoleculeModalFormWithInvalidData: MoleculeModalFormProps = {
	...MyMoleculeModalForm,
	formUrl: 'https://fetch.mock/m-modal-form/updateFailedValidation',
	content: '',
};
