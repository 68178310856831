import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
		padding: 0,
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: 10,
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: '#f2f2f5',
		},
		'&::-webkit-scrollbar-thumb': {
			height: '40%',
			border: '2px solid #f2f2f5',
			borderRadius: 6,
			backgroundColor: '#c7cacf',
		},
	},
	dialogActions: {
		margin: 0,
		padding: theme.spacing(1),
		backgroundColor: '#f8f8fa',
	},
	dialogPaper: {
		borderRadius: 20,
		minWidth: '80vw',
		height: '80vh',
		userSelect: 'none',
	},
	modal: {
		position: 'relative',
		width: '300px',
		height: '200px',
		overflow: 'hidden',
		border: '1px solid #ccc',
		background: '#fff',
	},
	slider: {
		display: 'flex',
		transition: 'transform 1.5s ease',
	},
	step: {
		minWidth: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		padding: '10px 15px',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		color: '#ffffff',
		border: 'none',
		cursor: 'pointer',
		opacity: 0.7,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
		},
		'&:disabled': {
			opacity: 0.4,
			cursor: 'not-allowed',
		},
		'&:nth-child(2)': {
			left: 0,
		},
		'&:nth-child(3)': {
			right: 0,
		},
	},
}));

export default useStyles;
