import React, { useState } from 'react';
import { ComplexVdi, LockpadFilled } from 'assets/icons';

const Step2 = () => {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [multipleInput, setMultipleInput] = useState(false);

	const handleUploadFiles = (files: any) => {
		let uploaded: any = [...uploadedFiles];
		if (!multipleInput) {
			uploaded = [];
			files.some((file: any) => {
				uploaded.push(file);
			});
		} else if (multipleInput) {
			files.some((file: any) => {
				if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
					uploaded.push(file);
				}
			});
		}
		setUploadedFiles(uploaded);
	};

	const dropHandler = (ev: any) => {
		ev.preventDefault();
		const dragedFiles = [...ev.dataTransfer.files];
		handleUploadFiles(dragedFiles);
	};

	const dragOverHandler = (ev: any) => {
		ev.preventDefault();
	};

	const handleSelectedFileEvent = (e: any) => {
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		handleUploadFiles(chosenFiles);
	};

	return (
		<>
			<div className="approval-upload-container">
				<label onDrop={dropHandler} onDragOver={dragOverHandler} className="file-upload">
					<input type="file" accept="application/pdf" onChange={handleSelectedFileEvent} multiple={multipleInput} />
					<ComplexVdi className="upload-icon" />
					<div className="file-text-container">
						<span className="file-text">{'Dateien hierher ziehen'}</span>
						<span className="file-text">{'oder'}</span>
						<span className="file-text underline">{'Dateien auswählen'}</span>
					</div>
				</label>
			</div>
			<div className="encrypted-section">
				<span className="encrypted-text">{'File Size 10 MB'}</span>
				<div className="encrypted-container">
					<span className="encrypted-text">{'Encrypted'}</span>
					<LockpadFilled className="icon-size" />
				</div>
			</div>
			<div className="chosen-file-section">
				{uploadedFiles.map((file: any, idx) => (
					<div key={idx} className="chosen-file">
						<span className="chosen-file-name">{file.name}</span>
						<span className="chosen-file-size">{file.size && (file.size / (1024 * 1024)).toFixed(2)} Megabyte</span>
					</div>
				))}
			</div>
		</>
	);
};

export default Step2;
