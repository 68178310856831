import React, { ChangeEvent } from 'react';
import { ProgressBarLargeProps } from 'components/ProgressBarLarge/ProgressBarLarge.d';
import { IHeatingDevice } from 'types/IHeatingDevice';
import { HeatingDeviceStatusEnum } from 'types/HeatingDeviceStatusEnum';
import { DeviceOwner } from 'types/DeviceOwner';

export interface MoleculeMaintenanceModalProps {
	stepper: ProgressBarLargeProps;
	devices: Array<IHeatingDevice>;
	open: boolean;
	handleClose?: () => void;
}

export interface AtomToggleProps {
	id?: string;
	items?: string[];
	value?: boolean;
	setToggle?: (value: boolean) => void;
}

export interface MoleculeStep1Props {
	cards: Array<ModalAddMaintenanceCardProps>;
}

export interface MoleculeStep2Props {
	cards: Array<ModalAddMaintenanceCardProps>;
}

interface CardColumn {
	label: string;
	value: string;
	required?: boolean;
}

export interface ModalAddMaintenanceCardProps {
	columns: Array<CardColumn>;
	checkboxValue: string;
	checked?: boolean;
	onCheck?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface SuccessStepProps {
	maintenanceCount: number;
	setActiveStep: (step: number) => void;
}

const Stepper: ProgressBarLargeProps = {
	steps: ['Geräte', 'Wartungen'],
	activeStep: 0,
	failedSteps: [1],
};

const deviceOwner: DeviceOwner = {
	salutation: 'Herr',
	firstname: 'Max',
	lastname: 'Mustermann',
	company: '',
	phone: '',
	email: '',
	address: {
		street: '',
		house_number: '',
		zip_code: '',
		city: '',
	},
};

export const MaintenanceModalData = {
	data: {
		stepper: Stepper,
		open: false,
		devices: [
			{
				uuid: '07891017891123456',
				serial: '0 789101 7891 123456',
				type: 'Device',
				name: 'Wärmepumpe CHA-10',
				status: HeatingDeviceStatusEnum['UNKNOWN'],
				manufacture_date: '2021-01-01',
				installation_date: '2021-01-01',
				commissioning_date: '2021-01-01',
				warranty_end_date: '2021-01-01',
				warranty_duration: 1,
				is_iot_permission_granted: true,
				is_failure: false,
				is_maintenance: false,
				owner: deviceOwner,
				maintenanceDate: '2021-01-10',
			},
			{
				uuid: '07891017891123456',
				serial: '0 789101 7891 123457',
				type: 'Device',
				name: 'Wärmepumpe CHA-06',
				status: HeatingDeviceStatusEnum['UNKNOWN'],
				manufacture_date: '2021-01-01',
				installation_date: '2021-01-01',
				commissioning_date: '2021-01-01',
				warranty_end_date: '2021-01-01',
				warranty_duration: 1,
				is_iot_permission_granted: true,
				is_failure: false,
				is_maintenance: false,
				owner: deviceOwner,
				maintenanceDate: '2023-01-10',
			},
			{
				uuid: '07891017891123456',
				serial: '0 xxxxxx 7891 321458',
				type: 'Device',
				name: 'Wärmepumpe CHA-09',
				status: HeatingDeviceStatusEnum['UNKNOWN'],
				manufacture_date: '2021-01-01',
				installation_date: '2021-01-01',
				commissioning_date: '2021-01-01',
				warranty_end_date: '2021-01-01',
				warranty_duration: 1,
				is_iot_permission_granted: true,
				is_failure: false,
				is_maintenance: false,
				owner: deviceOwner,
				maintenanceDate: '2026-10-12',
			},
		],
	},
};

export const MyMoleculeMaintenanceModalTranslations = {
	Devices: 'Geräte',
	Maintenance: 'Wartungen',
	'Product name': 'Produktname',
	'Serial number': 'Seriennummer',
	'Material number': 'Materialnummer',
	Owner: 'Inhaber',
	'Maintenance due': 'Wartung fällig',
	Back: 'Zurück',
	Next: 'Weiter',
	'Select devices': 'Geräte auswählen',
	'Find device': 'Gerat suchen',
	'Add maintenance': 'Wartung hinzufügen',
	'Serial number maintenance kit': 'Seriennummer Wartungskit',
	'Material number maintenance kit': 'Materialnummer Wartungskit',
	'Maintenance date': 'Wartungsdatum',
	'Maintenance has been added': 'Wartungen wurden hinzufügen',
	'maintenances have been added successfully': 'Wartungen wurden erfolgreich hinzugefügt',
	'Add more maintenance': 'Weitere Wartungen hinzufügen',
	'To overview': 'Zur Übersicht',
};
