import React, { useCallback, useEffect, useState } from 'react';
import { HistoryCalendarRepresentationProps } from './HistoryCalendarRepresentation.d';
import { DeviceEvent } from 'types/DeviceEvent';
import { useDispatch } from 'react-redux';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { AppDispatch } from 'store';
import { Skeleton } from '@material-ui/lab';
import getEventMapping from 'services/utils/getEventMapping';
import { Tooltip } from '@material-ui/core';

const MAX_COLUMNS: number = 12;
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//HCRepresentation is a component that is used to display the `history calendar representation`
function HCRepresentation(props: HistoryCalendarRepresentationProps): JSX.Element {
	const { events } = props;

	const dispatch = useDispatch<AppDispatch>();

	const [selectedMonths, setSelectedMonths] = React.useState<number[]>([]);
	const [year, setYear] = React.useState<number>(new Date().getFullYear());
	const [loading, setLoading] = useState<boolean>(true);
	const [eventCount, setEventCount] = useState<Record<string, number>[]>([]);
	const [eventsMatrix, setEventsMatrix] = useState<DeviceEvent[][]>([]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 650);
	}, []);

	useEffect(() => {
		if (!selectedMonths.length) {
			const creation_date_from = new Date(`${year}-01-01`);
			creation_date_from.setHours((creation_date_from.getTimezoneOffset() / 60) * -1, 0, 0, 0);

			const creation_date_to = new Date(`${year}-12-31`);
			creation_date_to.setHours((creation_date_to.getTimezoneOffset() / 60) * -1 + 23, 59, 59, 999);
			dispatch(
				heatingDevicePortalActions.setEventsFilters({
					creation_date_from: creation_date_from.toISOString(),
					creation_date_to: creation_date_to.toISOString(),
				}),
			);
			return;
		}

		const sortedMonths = selectedMonths.sort((a, b) => a - b);

		const lastDayOfMonth = new Date(year, sortedMonths[sortedMonths.length - 1] + 1, 0).getDate();
		const creation_date_from = new Date(`${year}-${sortedMonths[0] + 1}-1`);
		creation_date_from.setHours((creation_date_from.getTimezoneOffset() / 60) * -1, 0, 0, 0);

		const creation_date_to = new Date(`${year}-${sortedMonths[sortedMonths.length - 1] + 1}-${lastDayOfMonth}`);
		creation_date_to.setHours((creation_date_to.getTimezoneOffset() / 60) * -1 + 23, 59, 59, 999);
		dispatch(
			heatingDevicePortalActions.setEventsFilters({
				creation_date_from: creation_date_from.toISOString(),
				creation_date_to: creation_date_to.toISOString(),
			}),
		);
	}, [selectedMonths, year, dispatch]);

	const handleMonthSelect = (month: number) => {
		const sortedSelectedMonths = selectedMonths.sort((a, b) => a - b);
		const firstElement = sortedSelectedMonths[0];
		const lastElement = sortedSelectedMonths[sortedSelectedMonths.length - 1];
		if (sortedSelectedMonths.includes(month)) {
			if (month === firstElement || month === lastElement) {
				setSelectedMonths(sortedSelectedMonths.filter((selectedMonth) => selectedMonth !== month));
			} else setSelectedMonths([month]);
		} else {
			if (sortedSelectedMonths.length) {
				if (Math.abs(firstElement - month) === 1 || Math.abs(lastElement - month) === 1) {
					setSelectedMonths([...sortedSelectedMonths, month]);
				} else setSelectedMonths([month]);
			} else setSelectedMonths([month]);
		}
	};

	const getEvents = useCallback(
		(month: number) => {
			const eventCount: Record<string, number> = {};

			let filteredEvents = events.filter((event: DeviceEvent) => {
				return new Date(event.creation_date).getMonth() === month;
			});

			filteredEvents = filteredEvents.reduce((accumulator: DeviceEvent[], event: DeviceEvent) => {
				const existingEvent = accumulator.find((e) => e.type === event.type);

				if (existingEvent) {
					eventCount[event.type] = (eventCount[event.type] || 0) + 1;
				} else {
					eventCount[event.type] = 1;
					accumulator.push(event);
				}
				return accumulator;
			}, []);

			filteredEvents.sort((a: DeviceEvent, b: DeviceEvent) => {
				return new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime();
			});

			return { filteredEvents, eventCount };
		},
		[events],
	);

	const transpose = (matrix: any[][]) => {
		const n = matrix.length;
		for (let i = 0; i < n / 2; i++) {
			for (let j = i; j < n - i - 1; j++) {
				const temp = matrix[i][j];
				matrix[i][j] = matrix[n - 1 - j][i];
				matrix[n - 1 - j][i] = matrix[n - 1 - i][n - 1 - j];
				matrix[n - 1 - i][n - 1 - j] = matrix[j][n - 1 - i];
				matrix[j][n - 1 - i] = temp;
			}
		}
		return matrix.reverse();
	};

	const createMatrix = useCallback(
		(events: DeviceEvent[]) => {
			const matrix: any[][] = [];
			const eventCount = [{}];
			for (let i = 0; i < MAX_COLUMNS; i++) {
				matrix.push([]);
			}
			MONTHS.forEach((_, index) => {
				const newEvents = getEvents(index);
				matrix[index] = newEvents.filteredEvents;
				eventCount[index] = newEvents.eventCount;
			});
			const transformedMatrix = transpose(matrix).map((row) => row.reverse());
			return { transformedMatrix, eventCount };
		},
		[getEvents],
	);

	useEffect(() => {
		const { transformedMatrix, eventCount } = createMatrix(events);
		setEventsMatrix(transformedMatrix);
		setEventCount(eventCount);
	}, [createMatrix, events]);

	const checkForEmptyRows = (row: Array<DeviceEvent>) => {
		let valid = false;
		row.forEach((event: DeviceEvent) => {
			if (event) {
				valid = true;
			}
		});
		return valid;
	};

	return (
		<div className="m-history-calendar-representation" id="historyCalendarRepresentation">
			<div className="year-selector">
				{loading ? (
					<Skeleton width={70} height={40} />
				) : (
					<>
						<div onClick={() => setYear(year - 1)} className="arrow">
							{'<'}
						</div>
						<div className="year">{year}</div>
						<div onClick={() => setYear(year + 1)} className={`arrow ${year < new Date().getFullYear() ? '' : 'unavailable'}`}>
							{'>'}
						</div>
					</>
				)}
			</div>
			{loading ? (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			) : (
				<table className="history-calendar-graph-container">
					<tbody>
						{eventsMatrix.map((row, index) =>
							checkForEmptyRows(row) ? (
								<tr key={index} className="dotted">
									{row.map((event: DeviceEvent, indexEvent) => {
										return event ? (
											<td key={indexEvent} className={`cell`}>
												<Tooltip title={getEventMapping(event.type).displayName} placement="top">
													<div className={`burger`} style={{ backgroundColor: getEventMapping(event.type).color }}>
														<span className="event-count">{eventCount[indexEvent][event.type]}</span>
													</div>
												</Tooltip>
											</td>
										) : (
											<td key={indexEvent}></td>
										);
									})}
								</tr>
							) : null,
						)}
						<tr>
							{MONTHS.map((month: string, index: number) => (
								<td key={index}>
									<div
										onClick={() => handleMonthSelect(index)}
										className={`month-selector ${selectedMonths.includes(index) ? 'selected' : null}`}>
										{month}
									</div>
								</td>
							))}
						</tr>
					</tbody>
				</table>
			)}
		</div>
	);
}
export default HCRepresentation;
