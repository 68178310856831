import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { IconButton } from '@material-ui/core';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 16,
			backgroundColor: '#f8f8fa',
		},
		closeButton: {
			position: 'absolute',
			color: '#6b718b',
			right: 16,
			top: 16,
			height: 46,
			width: 46,
		},
		nextButton: {
			color: 'white',
			backgroundColor: '#212d40',
			borderRadius: 0,
			margin: 16,
			textTransform: 'none',
			fontFamily: 'Inter',
			fontSize: 16,
			fontWeight: 500,
			padding: '10px 32px',
			'&:hover': {
				backgroundColor: '#212d40',
			},
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close style={{ width: 16, color: '#6b718b' }} />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default DialogTitle;
