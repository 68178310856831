import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Input from 'components/Input';
import { useStyles } from '../hooks/useStyles';
import { LocationAddress } from 'types/LocationAddress';
import Toggle from 'components/Toggle';
import { AddCustomerFormData } from '../CustomerData.d';
import Dropdown from 'components/Dropdown/Dropdown';

export interface AddressFormProps {
	formId: string;
	formGroupHeader: string;
	automatic?: boolean;
	customerData?: AddCustomerFormData;
	deviceAddress?: LocationAddress;
	handleChange?: () => void;
	err?: any;
}

function AddressForm({ formId, formGroupHeader, customerData, automatic, handleChange, err }: AddressFormProps): JSX.Element {
	const classes = useStyles();
	const { t } = useTranslation();
	const [toggle, setToggle] = useState<boolean>(!automatic);
	const [googlePlace, setGooglePlace] = useState<any>(null);

	const getAddressComponents = (address) => {
		if (!address) {
			return null;
		}
		const result: any = {
			house_number: null,
			street: null,
			city: null,
			country: null,
			zip_code: null,
		};

		for (let i = 0; i < address['address_components'].length; i++) {
			const component = address['address_components'][i];

			if (component.types.includes('street_number')) {
				result.house_number = parseInt(component.long_name);
			} else if (component.types.includes('route')) {
				result.street = component.long_name;
			} else if (component.types.includes('locality')) {
				result.city = component.long_name;
			} else if (component.types.includes('country')) {
				result.country = component.long_name;
			} else if (component.types.includes('postal_code')) {
				result.zip_code = component.long_name;
			}
		}
		return result;
	};

	return (
		<Grid container className={classes.borderedRow}>
			<Grid item xs={12} md={12} className={classes.rowTitle}>
				<span>{t(formGroupHeader)}</span>
			</Grid>
			<Grid item xs={12} md={12} className={classes.toggle}>
				<Toggle items={['Manual', 'Automatic']} value={toggle} setToggle={setToggle} />
			</Grid>
			{toggle ? (
				<>
					<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
						<div>
							<Input
								type="label"
								id="address"
								name={formId === 'customer' ? 'address.street' : 'location.street'}
								value={
									formId === 'customer'
										? customerData && customerData['address.street']
										: customerData && customerData['location.street']
								}
								onChange={handleChange}
								label={t('Street').toString()}
								placeholder={t('Street').toString()}
								error={err?.street}
								invalid={!!err?.street}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
						<Input
							type="label"
							id="addressNr"
							name={formId === 'customer' ? 'address.house_number' : 'location.house_number'}
							value={
								formId === 'customer'
									? customerData && customerData['address.house_number']
									: customerData && customerData['location.house_number']
							}
							onChange={handleChange}
							label={t('House number').toString()}
							placeholder={t('House number').toString()}
							error={err?.house_number}
							invalid={!!err?.house_number}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
						<Input
							type="label"
							id="zip"
							name={formId === 'customer' ? 'address.zip_code' : 'location.zip_code'}
							value={
								formId === 'customer'
									? customerData && customerData['address.zip_code']
									: customerData && customerData['location.zip_code']
							}
							onChange={handleChange}
							label={t('ZIP').toString()}
							placeholder={t('ZIP').toString()}
							error={err?.zip_code}
							invalid={!!err?.zip_code}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
						<Input
							type="label"
							id="city"
							name={formId === 'customer' ? 'address.city' : 'location.city'}
							value={
								formId === 'customer' ? customerData && customerData['address.city'] : customerData && customerData['location.city']
							}
							onChange={handleChange}
							label={t('ORT').toString()}
							placeholder={t('ORT').toString()}
							error={err?.city}
							invalid={!!err?.city}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
						<Dropdown
							items={[{ value: 'Germany', text: 'Germany' }]}
							name={formId === 'customer' ? 'address.country' : 'location.country'}
							label={t('Country').toString()}
							placeholder={t('Country').toString()}
							defaultValue={
								formId === 'customer'
									? customerData && customerData['address.country']
									: customerData && customerData['location.country']
							}
							error={err?.country}
							invalid={!!err?.country}
						/>
					</Grid>
				</>
			) : (
				<Grid item xs={12} sm={12} md={12} className={classes.formColumn}>
					<Input
						type="address"
						id="address"
						value={googlePlace?.formatted_address || ''}
						placeholder={t(formGroupHeader).toString()}
						// TODO RG: Find a use for the place parameter later on when sending data to backend
						onPlaceSelected={(place: google.maps.places.PlaceResult) => setGooglePlace(place)}
						error={err ? Object.values(err).join('. ') : ''}
					/>
					<input
						readOnly
						type="hidden"
						name={formId === 'customer' ? 'address.street' : 'location.street'}
						value={getAddressComponents(googlePlace)?.street || ''}
					/>
					<input
						readOnly
						type="hidden"
						name={formId === 'customer' ? 'address.house_number' : 'location.house_number'}
						value={getAddressComponents(googlePlace)?.house_number || ''}
					/>
					<input
						readOnly
						type="hidden"
						name={formId === 'customer' ? 'address.zip_code' : 'location.zip_code'}
						value={getAddressComponents(googlePlace)?.zip_code || ''}
					/>
					<input
						readOnly
						type="hidden"
						name={formId === 'customer' ? 'address.city' : 'location.city'}
						value={getAddressComponents(googlePlace)?.city || ''}
					/>
					<input
						readOnly
						type="hidden"
						name={formId === 'customer' ? 'address.country' : 'location.country'}
						value={getAddressComponents(googlePlace)?.country || ''}
					/>
				</Grid>
			)}
		</Grid>
	);
}
export default AddressForm;
