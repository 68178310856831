import React, { useEffect, useState } from 'react';
import DeviceTitle from './DeviceTitle';

const Filters = (props: { serials: Array<string>; setFilter: (value: string) => void }) => {
	const [serials, setSerials] = useState<Array<{ value: string; count: string }>>([]);
	const [selectedSerial, setSelectedSerial] = useState<string>('');
	useEffect(() => {
		const countMap = props.serials.reduce((acc, currentValue) => {
			acc[currentValue] = (acc[currentValue] || 0) + 1;
			return acc;
		}, {});

		const result = Object.keys(countMap).map((key) => ({ value: key, count: countMap[key] }));
		setSerials(result);
	}, [props.serials]);
	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end', margin: 16 }}>
			{serials.map((serial, index) => (
				<div
					key={index}
					onClick={() => {
						if (serial.value === selectedSerial) {
							setSelectedSerial('');
							props.setFilter('');
						} else {
							setSelectedSerial(serial.value);
							props.setFilter(serial.value);
						}
					}}
					style={{
						backgroundColor: 'white',
						padding: '10px 16px',
						margin: 8,
						border: selectedSerial === serial.value ? 'solid 1px black' : 'solid 1px #c7cacf',
						borderRadius: 12,
						cursor: 'pointer',
					}}>
					<DeviceTitle sku={serial.value} /> ({serial.count})<br></br>
					{serial.value}
				</div>
			))}
		</div>
	);
};

export default Filters;
