import React, { useState } from 'react';
import Card from './Card';
import ROUTES from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { StackableCardProps } from '../CurrentEvents.d';
import { DeviceEvent } from 'types/DeviceEvent';

function StackableCards({ data, detailedView }: StackableCardProps): JSX.Element {
	const [collapse, setCollapse] = useState(false);
	const classname = detailedView && 'detailed-view';
	const navigate = useNavigate();

	const handleCardOnClick = (cardLink) => {
		if (!collapse && data.length > 1) {
			setCollapse(true);
			return;
		}
		navigate(`${ROUTES.DETAIL}${data[0].device_uuid}`);
	};

	return (
		<div className={`stackable-wrapper ${classname}`} style={{ minHeight: data.length > 5 ? '12rem' : '10rem' }}>
			{!detailedView && <div className="stack-button">{collapse && <button onClick={() => setCollapse(false)}>x</button>}</div>}
			{data.map((obj: DeviceEvent, index: number) => {
				return (
					<Card
						key={index}
						event={obj}
						style={{
							position: detailedView ? 'initial' : collapse ? 'initial' : 'absolute',
							top: index * 10,
							zIndex: detailedView ? 'initial' : collapse ? 'initial' : data.length - index,
							marginBottom: detailedView ? '17px' : undefined,
							cursor: 'pointer',
						}}
						onClickCard={() => handleCardOnClick('#')}
					/>
				);
			})}
		</div>
	);
}

export default StackableCards;
