import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import 'services/translations';
import { IHeatingDeviceProps } from 'types/IHeatingDeviceProps';
import { MYWOLF_INIT_DATA_OBJECT_ID } from 'constants/index';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';

function Initializer(props: { children: React.ReactNode }) {
	const { i18n } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const { setInitialHeaderData, setSummiteerData } = heatingDevicePortalActions;

	const initData: IHeatingDeviceProps = useMemo(() => {
		if (process.env.NODE_ENV === 'development') {
			const data = require('constants/initData').default;
			return data;
		}

		const data = document.getElementById(MYWOLF_INIT_DATA_OBJECT_ID)!.innerText;
		try {
			const dataObject = JSON.parse(data);
			return dataObject;
		} catch (e) {
			return [];
		}
	}, []);

	useEffect(() => {
		if (initData.translations) {
			i18n.addResources('de', 'translation', initData.translations);
		}
		dispatch(setSummiteerData(initData.summiteer));
		dispatch(setInitialHeaderData(initData.header));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initData]);

	return <>{props.children}</>;
}

export default Initializer;
