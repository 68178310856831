import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceEventType } from 'types/DeviceEvent';
import { ListEntryProps } from '../HistoryEventList.d';
import getEventMapping from 'services/utils/getEventMapping';

function formatDate(date: Date) {
	const d = new Date(date);
	let month = '' + (d.getMonth() + 1);
	let day = '' + d.getDate();
	const year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [day, month, year].join('.');
}

const ListEntry = React.forwardRef(function ListEntry(props: ListEntryProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element {
	const { event } = props;

	const { t } = useTranslation();

	const [date, setDate] = useState<Date>(new Date(event.creation_date));

	useEffect(() => {
		setDate(new Date(event.creation_date));
	}, [event.creation_date]);

	return (
		<div className="entry-container" ref={ref}>
			<div className="icon-container">
				<span className={`event-type-icon ${event.type}`}>{React.createElement(getEventMapping(event.type).icon)}</span>
				<div className="vertical-bar" />
			</div>
			<div className="column margin-left">
				<span className="type">{t(`${getEventMapping(event.type).displayName}`)}</span>
				<span className="details">{formatDate(date)}</span>
			</div>
			<div className="column">
				<span className="title">{t('Time')}</span>
				<span className="details">{`${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${(
					'0' + date.getSeconds()
				).slice(-2)}`}</span>
			</div>
			<div className="column">
				<span className="title">{event.event_details.status || 'UNKNOWN'}</span>
				<span className="details">{event.event_details.message || '-'}</span>
			</div>
			<div className="column">
				<span className="title">{t('Device')}</span>
				<span className="details">{event.entity_type || '-'}</span>
			</div>
			<div className="column">
				<span className="title">
					{event.type === DeviceEventType.DeviceComponentMaintenanceRegistered
						? t('Carried out')
						: event.type === 'settings'
						? t('Changed by')
						: t('Reported by')}
				</span>
				<span className="details">{event.operator || '-'}</span>
			</div>
		</div>
	);
});

export default ListEntry;
