import React, { ChangeEvent, FormEvent, memo, useEffect, useRef, useState } from 'react';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import ModalForm from '../../m-modal-form/m-modal-form.component';
import { AssignEmployeesModalProps, EmployeeCardProps } from '../WidgetAssignEmployees.d';
import { Employee } from 'types/Employee';
import { DropdownProps } from 'components/Dropdown/Dropdown.d';
import Checkbox from 'components/Checkbox/Checkbox';
import AddEmployeeModal from './AddEmployeeModal';
import { useTranslation } from 'react-i18next';
import { Button, Theme, withStyles } from '@material-ui/core';
import pimcoreApi from 'store/modules/pimcore';

function EmployeeCard(props: EmployeeCardProps): JSX.Element {
	const { employee, onChange } = props;

	return (
		<div className={`employee-card ${employee.assigned ? 'assigned' : ''}`}>
			<div className="inner-wrapper">
				<Checkbox label="" disabled={false} checked={!!employee.assigned} name={employee.uuid} onChange={onChange}></Checkbox>
				<div className="name-email">
					<span className="employee-name">{`${employee.firstName} ${employee.lastName}`}</span>
					<span className="employee-email">{employee.email}</span>
				</div>
				<span className="employee-role">{employee.role}</span>
			</div>
		</div>
	);
}

const SubmitButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

function AssignEmployeesModal(props: AssignEmployeesModalProps): JSX.Element {
	const { modalId, deviceIDs } = props;
	const [roles, setRoles] = useState<DropdownProps['items'] | undefined>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [filterOption, setFilterOption] = useState<string | number>('');
	const [hasExtractedRoles, setHasExtractedRoles] = useState<boolean>(false);
	const [employees, setEmployees] = useState<Employee[]>([]);
	const { t } = useTranslation();

	const [assignEmployees, _] = pimcoreApi.useAssignEmployeesMutation();
	const { data: assignments } = pimcoreApi.useGetDeviceAssignmentsQuery(
		{ systemIds: deviceIDs, search: searchTerm, filter: filterOption.toString() },
		{
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		employees && employees.length ? setHasExtractedRoles(true) : null;
		const uniqueByRole = [...new Map(employees?.map((emp) => [emp.role, emp])).values()];
		const mappedRoles = uniqueByRole.map((emp: Employee) => {
			return {
				value: emp.role,
				text: emp.role,
			};
		});
		mappedRoles.unshift({
			value: 'all',
			text: t('All'),
		});

		hasExtractedRoles ? null : setRoles(mappedRoles);
	}, [employees, hasExtractedRoles, t]);

	useEffect(() => {
		if (!assignments) return;
		setEmployees(assignments);
	}, [assignments]);

	const onAddEmployee = () => {
		setSearchTerm('');
		setFilterOption('');
	};

	const handleAssignEmployee = (e: ChangeEvent<HTMLInputElement>) => {
		const foundEmployeeIdx = employees?.findIndex((emp: Employee) => emp.uuid === e.target.name);

		if (foundEmployeeIdx !== undefined && foundEmployeeIdx !== -1) {
			const newEmployees = JSON.parse(JSON.stringify(employees));
			newEmployees[foundEmployeeIdx].assigned = !newEmployees[foundEmployeeIdx].assigned;
			setEmployees(newEmployees);
		}
	};

	const handleSubmitAssignEmployees = (e: FormEvent<HTMLFormElement>) => {
		e && e.preventDefault();
		const formData = new FormData(e.target as HTMLFormElement);
		Promise.all(
			deviceIDs.map((deviceId) => {
				assignEmployees({ systemId: deviceId, payload: formData });
			}),
		);

		setSearchTerm('');
		setFilterOption('');
	};

	return (
		<>
			<ModalForm id={modalId} headline="" formUrl="" content="">
				<h4 className="uk-text-center modal-heading">{t('Assign employees')}</h4>
				<form onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmitAssignEmployees(e)}>
					<div className="modal-body">
						<div className="modal-input-wrapper">
							<Input
								type="search"
								placeholder={t('Search employee').toString() ?? ''}
								width={286}
								value={searchTerm}
								setValue={setSearchTerm}></Input>
							<Dropdown
								placeholder={t('Role').toString()}
								items={roles || []}
								width={210}
								defaultValue={filterOption}
								setOption={setFilterOption}></Dropdown>
						</div>
						<div className="scrollable-wrapper">
							{employees?.map((employee, index) => {
								return <EmployeeCard key={index} employee={employee} onChange={handleAssignEmployee} />;
							})}
						</div>
					</div>
					<div className="uk-modal-footer uk-text-center">
						<SubmitButton type="submit" variant="contained" color="primary">
							{t('Save assignment')}
						</SubmitButton>
					</div>
				</form>
			</ModalForm>
			<AddEmployeeModal modalId="add-employee-modal" parentModalId={modalId} onAddEmployee={onAddEmployee} />
		</>
	);
}

export default memo(AssignEmployeesModal);
