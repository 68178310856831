import { Button, Theme, withStyles } from '@material-ui/core';
import FormGroup from 'components/FormGroup/FormGroup';
import apiBaseUrls from 'constants/apiBaseUrls';
import React from 'react';
import { ComponentDetails } from 'types/Component';

const NextButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const PrevButton = withStyles((theme: Theme) => ({
	root: {
		color: '#212d40',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'solid 1px #212d40',
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

function SuccessStep(props: { devices: Array<ComponentDetails>; customerData?: any }): JSX.Element {
	const { devices, customerData } = props;

	const formatSerialNumber = (serialNumber?: string): string => {
		const formattedString = serialNumber
			? [
				serialNumber.substring(0, 1), // First group of 1 digit
				serialNumber.substring(1, 6), // Second group of 6 digits
				serialNumber.substring(7, 3), // Third group of 3 digits
				serialNumber.substring(10, 6), // Fourth group of 6 digits
			].join(' ')
			: '-';

		return formattedString;
	};

	const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
		const target = event.target as HTMLImageElement;
		target.src = 'https://placehold.co/96x96';
	};

	return (
		<div className="m-success-step-temp" id="success-step">
			<div className="confetti-container">
				<div className="circle"></div>
				<div className="circle2"></div>
				<div className="circle3"></div>
				<div className="circle4"></div>
				<div className="circle5"></div>
				<div className="circle6"></div>
				<div className="circle7"></div>
				<div className="circle8"></div>
				<div className="circle9"></div>
				<div className="circle10"></div>
				<div className="circle11"></div>
				<div className="circle12"></div>
				<div className="circle13"></div>
				<div className="circle14"></div>
				<div className="circle15"></div>
				<div className="circle16"></div>
				<div className="circle17"></div>
				<div className="five-pointed-star"></div>
			</div>
			<h2 className="headline">Anlagen wurde hinzugefügt</h2>
			<h3 className="earned-points-subheadline">200 Punkte für Gipfelstürmer gesammelt</h3>
			<h2 className="headline headline-small">Zusammenfassung</h2>
			<div className="devices-container">
				{devices.map((device, index) => (
					<FormGroup formGroupTitle={`Device ${index + 1}`} key={index}>
						<div className="column">
							<span className="title">Name</span>
							<span className="content">{device.name}</span>
						</div>
						<div className="column">
							<span className="title">Seriennummer</span>
							<span className="content">{formatSerialNumber(device.sku)}</span>
						</div>
						<div className="column">
							<img
								className="device-image"
								src={`${apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pim}${device.images[0]}`}
								onError={handleImageError}
							/>
						</div>
					</FormGroup>
				))}
			</div>
			<div className="customer-container">
				<FormGroup formGroupTitle="Kundendaten">
					<div className="column">
						<span className="title">Name</span>
						<span className="content">{`${customerData?.firstname} ${customerData?.lastname}`}</span>
					</div>
					<div className="column">
						<span className="title">Telefon</span>
						<span className="content">{customerData?.phone}</span>
					</div>
					<div className="column">
						<span className="title">E-Mail</span>
						<span className="content">{customerData?.email}</span>
					</div>
					<div className="column">
						<span className="title">Ort</span>
						<span className="content">{customerData?.['address.city']}</span>
					</div>
					<div className="column">
						<span className="title">5-Jahres Garantie</span>
						<span className="content">Ja</span>
					</div>
				</FormGroup>
			</div>
		</div>
	);
}
export default SuccessStep;
