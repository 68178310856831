import { IlluMainDevice } from 'assets/icons';
import FormGroup from 'components/FormGroup';
import apiBaseUrls from 'constants/apiBaseUrls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { systemDevicesSelector } from 'store/modules/heatingdevice/selectors';
import { ComponentDetails } from 'types/Component';
import { SuccessProps } from '../ModalAddSystem.d';

const Success = (props: SuccessProps) => {
	const { customerData, title, withHeadline, loyaltyPoints } = props;
	const devices = useSelector(systemDevicesSelector);
	const { t } = useTranslation();

	const [imageNotFound, setImageNotFound] = React.useState<boolean>(false);

	const generateRandomStyle = () => {
		const size = Math.floor(Math.random() * 7) + 6;
		const backgroundColors = ['#e8453c', '#f9bb2d', '#6b8dff', '#3aa757', '#bdccd4'];
		const randomColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)];

		return {
			height: `${size}px`,
			width: `${size}px`,
			backgroundColor: randomColor,
			borderRadius: '50%',
			display: 'inline-block',
			position: 'absolute',
			top: `${Math.random() * 100}%`,
			left: `${Math.random() * 100}%`,
		} as const;
	};

	return (
		<div className="m-success-step" id="success-step">
			<div className="confetti-container">
				{Array.from({ length: 20 }, (_, index) => (
					<div key={index} style={generateRandomStyle()}></div>
				))}
				<div className="five-pointed-star"></div>
			</div>
			<h2 className="headline">{title}</h2>
			{withHeadline && (
				<h3 className="earned-points-subheadline">
					{loyaltyPoints} {t('Points collected for summiteer')}
				</h3>
			)}
			<h2 className="headline headline-small">{t('Summary')}</h2>
			<div className="devices-container">
				{devices &&
					devices.map((device: ComponentDetails, index: number) => (
						<FormGroup formGroupTitle={device.name} key={index}>
							<div className="column">
								<span className="title">{t('Name')}</span>
								<span className="content">{device.name}</span>
							</div>
							<div className="column">
								<span className="title">{t('Serial number')}</span>
								<span className="content">{device.sku || '-'}</span>
							</div>
							<div className="column">
								{!device.images[0] || imageNotFound ? (
									<IlluMainDevice />
								) : (
									<img
										className="device-image"
										src={`${apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pim}${device.images[0]}`}
										onError={() => setImageNotFound(true)}
									/>
								)}
							</div>
						</FormGroup>
					))}
			</div>
			{customerData && (
				<div className="customer-container">
					<FormGroup formGroupTitle="Kundendaten">
						<div className="column">
							<span className="title">{t('Name')}</span>
							<span className="content">{`${customerData?.firstName} ${customerData?.lastName}`}</span>
						</div>
						<div className="column">
							<span className="title">{t('Phone')}</span>
							<span className="content">{customerData?.phone.length ? customerData.phone : ' - '}</span>
						</div>
						<div className="column">
							<span className="title">{t('E-Mail')}</span>
							<span className="content">{customerData?.email}</span>
						</div>
						<div className="column">
							<span className="title">{t('City')}</span>
							<span className="content">{customerData?.customerCity}</span>
						</div>
						<div className="column">
							<span className="title">{t('5-year warranty')}</span>
							<span className="content">Ja</span>
						</div>
					</FormGroup>
				</div>
			)}
		</div>
	);
};

export default Success;
