import React, { useEffect, useState } from 'react';
import './StepFourApproval.scss';
import { ComplexVdi, LockpadFilled, TrashOutlined } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { stepperModalDataSelector } from 'store/modules/heatingdevice/selectors';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import ReactValidationError from 'components/ValidationError/ValidationError';
import { t } from 'i18next';

interface StepFourApprovalProps {
	data: {
		editTo: string;
		title: string;
		consentsTag: string;
		consentsFirstText: string;
		consentsSecondText: string;
		consentsThirdText: string;
		consentsFirst: boolean;
		consentsSecond: boolean;
		consentsThird: boolean;
		fileUploadTag: string;
		fileUpload: string;
		oder: string;
		selectFiles: string;
		fileSize: string;
		encrypted: string;
		data?: any;
		nextPressed?: boolean;
		handleValidation?: (isValid: boolean) => void;
	};
}

const StepFourApproval = (props: StepFourApprovalProps): JSX.Element => {
	const stepperModalData = useSelector(stepperModalDataSelector);
	const dispatch = useDispatch();
	const [uploadedFiles, setUploadedFiles] = useState(stepperModalData.uploadedFiles || []);
	const [multipleInput, setMultipleInput] = useState(false);
	const [firstConsent, setFirstConsent] = useState(props.data.consentsFirst);
	const [secondConsent, setSecondConsent] = useState(props.data.consentsSecond);
	const [thirdConsent, setThirdConsent] = useState(props.data.consentsThird);

	const handleUploadFiles = (files: any) => {
		let uploaded: any = [...uploadedFiles];
		if (!multipleInput) {
			uploaded = [];
			files.some((file: any) => {
				uploaded.push(file);
			});
		} else if (multipleInput) {
			files.some((file: any) => {
				if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
					uploaded.push(file);
				}
			});
		}
		setUploadedFiles(uploaded);
	};

	useEffect(() => {
		dispatch(heatingDevicePortalActions.setStepperModalData({ uploadedFiles }));
	}, [uploadedFiles, dispatch]);

	const handleSelectedFileEvent = (e: any) => {
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		handleUploadFiles(chosenFiles);
	};

	const dropHandler = (ev: any) => {
		ev.preventDefault();
		const dragedFiles = [...ev.dataTransfer.files];
		handleUploadFiles(dragedFiles);
	};

	const dragOverHandler = (ev: any) => {
		ev.preventDefault();
	};

	const handleDeleteFile = (file: any) => {
		const newUploadedFiles = uploadedFiles.filter((f: any) => f.name !== file.name);
		setUploadedFiles(newUploadedFiles);
	};

	useEffect(() => {
		if (
			!(props.data.data && props.data.data.length) ||
			!props.data.handleValidation ||
			!props.data.nextPressed ||
			!firstConsent ||
			!secondConsent ||
			!uploadedFiles.length
		) {
			return;
		}
		props.data.handleValidation(true);
	}, [firstConsent, props.data, props.data.data, props.data.handleValidation, props.data.nextPressed, secondConsent, uploadedFiles]);

	return (
		<div className="step-approval-container">
			<div className="step-approval-content">
				<fieldset className="sections-container">
					<legend>{props.data.consentsTag}</legend>
					<div className="err-container">
						<ReactValidationError error={!firstConsent ? '* ' + t('Required') : ''} />
					</div>
					<label className="consent-label" htmlFor="first">
						<span className="checkbox-input">
							<input
								type="checkbox"
								name="first"
								id="first"
								checked={firstConsent}
								onChange={() => setFirstConsent(!firstConsent)}
								required
							/>
						</span>
						<span className="consent-text">{props.data.consentsFirstText}</span>
					</label>
					<div className="err-container">
						<ReactValidationError error={!secondConsent ? '* ' + t('Required') : ''} />
					</div>
					<label className="consent-label" htmlFor="second">
						<span className="checkbox-input">
							<input
								type="checkbox"
								name="second"
								id="second"
								checked={secondConsent}
								onChange={() => setSecondConsent(!secondConsent)}
								required
							/>
						</span>
						<span className="consent-text">{props.data.consentsSecondText}</span>
					</label>
					<label className="consent-label" htmlFor="third">
						<span className="checkbox-input">
							<input type="checkbox" name="third" id="third" checked={thirdConsent} onChange={() => setThirdConsent(!thirdConsent)} />
						</span>
						<span className="consent-text">{props.data.consentsThirdText}</span>
					</label>
				</fieldset>
				<fieldset className="sections-container">
					<legend>{props.data.fileUploadTag}</legend>
					<div className="approval-upload-container">
						<label onDrop={dropHandler} onDragOver={dragOverHandler} className="file-upload">
							<input type="file" accept="application/pdf" onChange={handleSelectedFileEvent} multiple={multipleInput} />
							<ComplexVdi className="upload-icon" />
							<div className="file-text-container">
								<span className="file-text">{props.data.fileUpload}</span>
								<span className="file-text">{props.data.oder}</span>
								<span className="file-text underline">{props.data.selectFiles}</span>
							</div>
						</label>
					</div>
					<div className="err-container">
						<ReactValidationError error={!uploadedFiles.length ? '* ' + t('Required') : ''} />
					</div>
					<div className="encrypted-section">
						<span className="encrypted-text">{props.data.fileSize}</span>
						<div className="encrypted-container">
							<span className="encrypted-text">{props.data.encrypted}</span>
							<LockpadFilled className="icon-size" />
						</div>
					</div>
					<div className="chosen-file-section">
						{uploadedFiles.map((file: any, idx) => (
							<div key={idx} className="chosen-file">
								<span className="chosen-file-name">{file.name}</span>
								<span className="chosen-file-size">
									{file.size && (file.size / (1024 * 1024)).toFixed(2)} Megabyte
									<div className="delete-icon" onClick={() => handleDeleteFile(file)}>
										<TrashOutlined />
									</div>
								</span>
							</div>
						))}
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default StepFourApproval;
