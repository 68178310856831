import React, { useState, useEffect } from 'react';
import { ColumnDef, Row } from '@tanstack/react-table';
import StatusTag from 'components/m-status-tag';
import { multipleEntriesIcon, responsiveStatusIcons } from '../constants/icons';
import type { IHeatingDevice, IHeatingDeviceShowableColumns } from 'types/IHeatingDevice';
import { IComponent } from 'types/Component';
import { useTranslation } from 'react-i18next';
import { Typography, Tooltip } from '@material-ui/core';
import { More, WrenchFilled } from 'assets/icons';

interface UseColumnsProps {
	data: IHeatingDevice[] | IComponent[];
	withExpander: boolean | undefined;
	withSelection: boolean | undefined;
	columnNames: Record<string, string>;
	showColumns?: IHeatingDeviceShowableColumns;
	handleMoreClick: (e: React.MouseEvent<Element, MouseEvent>, row: Row<Record<string, unknown>>, position: DOMRect) => void;
}

export default function useColumns({
	data,
	withExpander,
	withSelection,
	columnNames,
	showColumns,
	handleMoreClick,
}: UseColumnsProps): ColumnDef<any>[] {
	const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

	const { t } = useTranslation();

	useEffect(() => {
		data &&
			data[0] &&
			(() => {
				setColumns([]);
				withSelection &&
					setColumns([
						{
							id: 'selection',
							header: ({ table }) => {
								return (
									<div>
										<input
											type="checkbox"
											checked={table.getIsAllPageRowsSelected()}
											onChange={table.getToggleAllRowsSelectedHandler()}
											onClick={(e) => e && e.stopPropagation()}
										/>
									</div>
								);
							},
							cell: ({ row }) => {
								return (
									<div>
										<input
											type="checkbox"
											checked={row.getIsSelected()}
											disabled={!row.getCanSelect()}
											onChange={row.getToggleSelectedHandler()}
											onClick={(e) => e && e.stopPropagation()}
										/>
									</div>
								);
							},
						},
					]);
				Object.keys(data[0]).map((key) => {
					if (!showColumns?.[key]) return;

					if (key === 'status') {
						setColumns((columns) => [
							...columns,
							{
								header: 'Status',
								cell: ({ row }) => {
									const { status, is_failure, is_maintenance, is_iot_permission_granted } = row.original as IHeatingDevice;

									return window.innerWidth > 960 ? (
										// quick fix for the status tags, should be removed once we settle on a convention
										<StatusTag type="pill" {...{ status, is_failure, is_maintenance, is_iot_permission_granted }} />
									) : (
										<img src={responsiveStatusIcons[(row.original as IHeatingDevice).status]} />
									); // TODO make icon
								},
								accessorKey: 'status',
							},
						]);

						// add expander column after status column if withExpander is true
						withExpander &&
							setColumns((columns) => [
								...columns,
								{
									id: 'expander',
									cell: ({ row }) =>
										row.getCanExpand() ? (
											<span
												style={{
													paddingLeft: `${row.depth * 2}rem`,
													cursor: 'pointer',
												}}
												onClick={row.getToggleExpandedHandler()}>
												{row.getIsExpanded() ? <i className="chevron-down" /> : <i className="chevron-right" />}
											</span>
										) : (
											<span>{row.depth ? <div className="row-expanded-arrow" /> : null}</span>
										),
								},
							]);
						return;
					}

					if (key === 'name') {
						setColumns((columns) => [
							...columns,
							{
								id: 'name',
								accessorKey: 'name',
								header: 'Systemname',
								cell: ({ row }) => {
									const name = row.original.name ? row.original.name : t('Missing Data');

									return row.depth === 0 ? (
										<Tooltip title={name}>
											<Typography noWrap>{name}</Typography>
										</Tooltip>
									) : null;
								},
							},
						]);
						return;
					}

					if (key === 'is_maintenance') {
						setColumns((columns) => [
							...columns,
							{
								id: 'is_maintenance',
								accessorKey: 'maintenance',
								header: 'Maintenance',
								cell: ({ row }) => {
									const maintenance = row.original.next_maintenance_date || t('Missing Data');

									return row.depth === 0 ? (
										<Tooltip title={maintenance}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Typography noWrap>{maintenance}</Typography>
												{row.original.is_maintenance && (
													<WrenchFilled style={{ color: '#00c5b9', height: 16, width: 16, margin: '0 6px' }} />
												)}
											</div>
										</Tooltip>
									) : null;
								},
							},
						]);
						return;
					}

					if (key === 'owner') {
						setColumns((columns) => [
							...columns,
							{
								id: 'owner',
								accessorKey: 'owner',
								header: 'Inhaber',
								cell: ({ row }) => {
									const ownerData = row.original as IHeatingDevice;

									const ownerName =
										ownerData.owner?.firstname !== 'string' &&
										ownerData.owner?.lastname !== 'string' &&
										ownerData.owner?.firstname &&
										ownerData.owner?.lastname
											? `${ownerData.owner.firstname} ${ownerData.owner.lastname}`
											: t('Missing Data');

									return row.depth === 0 ? (
										<Tooltip title={ownerName}>
											<Typography noWrap>{ownerName}</Typography>
										</Tooltip>
									) : null;
								},
							},
						]);
						return;
					}

					if (key === 'location') {
						setColumns((columns) => [
							...columns,
							{
								id: 'location',
								header: 'Address',
								accessorKey: 'location',
								cell: ({ row }) => {
									const locationData = row.original as IHeatingDevice;

									const addressParts = [
										locationData.location?.street,
										locationData.location?.house_number,
										locationData.location?.zip_code,
										locationData.location?.city,
									].filter((part) => part);

									const composedAddress = addressParts.length > 0 ? addressParts.join(' ') : t('Missing Data');

									return row.depth === 0 ? (
										<Tooltip title={composedAddress}>
											<Typography style={{ width: '256px' }}>{composedAddress}</Typography>
										</Tooltip>
									) : null;
								},
							},
						]);
						return;
					}

					if (key === 'description') {
						setColumns((columns) => [
							...columns,
							{
								header: 'Beschreibung',
								cell: ({ row, getValue }) =>
									row.getCanExpand() ? (
										<span>
											{getValue() as React.ReactNode}
											<img className="multiple-entries-icon" src={multipleEntriesIcon} />
										</span>
									) : (
										<span>{getValue() as React.ReactNode}</span>
									),
								accessorKey: 'description',
							},
						]);
						return;
					}

					if (key === 'type_description') {
						setColumns((columns) => [
							...columns,
							{
								header: t('Device').toString(),
								cell: ({ row, getValue }) => {
									const value = getValue();
									return row.getCanExpand() ? null : value === '' ? (
										<span>{t('Missing Data')}</span>
									) : (
										<span>{value as React.ReactNode}</span>
									);
								},
								accessorKey: 'type_description',
							},
						]);
						return;
					}

					if (key === 'serial') {
						setColumns((columns) => [
							...columns,
							{
								header: t('Serial number').toString(),
								cell: ({ row, getValue }) => {
									const value = getValue();
									return row.getCanExpand() ? null : value === '' ? (
										<span>{t('Missing Data')}</span>
									) : (
										<span>{value as React.ReactNode}</span>
									);
								},
								accessorKey: 'serial',
							},
						]);
						return;
					}

					if (key === 'subRows' || key === 'statusCode') {
						return;
					}
					const column = {
						header: t(columnNames[key]), // todo replace with translation
						accessorKey: key,
					};
					setColumns((columns) => [...columns, column]);
				});

				setColumns((columns) => [
					...columns,
					{
						id: 'more',
						cell: ({ row }) => (
							<More
								className="more-icon"
								tabIndex={-1}
								onClick={(e) => handleMoreClick(e, row, e.currentTarget.getBoundingClientRect())}
							/>
						),
					},
				]);
			})();
	}, [data, columnNames, withExpander, withSelection, showColumns, handleMoreClick, t]);
	return columns;
}
