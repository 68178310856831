export interface EventDetailsComponents {
	uuid: string;
	serial: string;
	type: string;
	type_description: string;
}

export interface EventDetails {
	name: string;
	status: string;
	components: Array<EventDetailsComponents>;
	installation_date: string;
	serial: string;
	type_description: string;
	maintenance_date: string;
	maintenance_kit_serial: string;
	code: string;
	message: string;
}

export interface DeviceEvent {
	device_uuid: string;
	creation_date: string;
	type: DeviceEventType | string;
	entity_type: string;
	event_details: EventDetails;
	device?: string;
	operator?: string;
	status?: string;
	message?: string;
}

export interface DeviceEventResponse {
	offset: number;
	limit: number;
	total: number;
	items: Array<DeviceEvent>;
}

export interface DeviceEventQueryParams {
	offset?: number;
	limit?: number;
	sort?: Array<string>;
	device_uuid?: string;
	type?: Array<DeviceEventType> | Array<string>;
	creation_date_from?: string;
	creation_date_to?: string;
	search?: string;
	filters?: string;
}

export enum DeviceEventType {
	Installation = 'installation',
	Commissioning = 'commissioning',
	Decommissioning = 'decommissioning',
	Maintenance = 'maintenance',
	Repair = 'repair',
	Dismantling = 'dismantling',
	Recycling = 'recycling',
	WarrantyGranted = 'warranty_granted',
	WarrantyExpired = 'warranty_expired',
	DeviceAdded = 'device_added',
	DeviceRemoved = 'device_removed',
	DeviceComponentAdded = 'device_component_added',
	DeviceComponentRemoved = 'device_component_removed',
	DeviceComponentMaintenanceRegistered = 'device_component_maintenance_registered',
	Failure = 'failure',
}
