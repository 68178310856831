import { IlluMainDevice } from 'assets/icons';
import apiBaseUrls from 'constants/apiBaseUrls';
import React, { useState } from 'react';
import backboneApi from 'store/modules/backbone';

const DeviceImage = (props: { sku: string }) => {
	const { data: extraDeviceData } = backboneApi.useGetComponentDetailsQuery(props.sku || '');
	const [imageNotFound, setImageNotFound] = useState<boolean>(false);

	return (
		<>
			{!extraDeviceData?.images[0] || imageNotFound ? (
				<IlluMainDevice style={{ width: '100%', padding: '16px 0' }} />
			) : (
				<img
					className="device-img"
					src={`${apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pim}${extraDeviceData?.images[0]}`}
					onError={() => setImageNotFound(true)}
				/>
			)}
		</>
	);
};

export default DeviceImage;
