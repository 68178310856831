import { AttentionFilled, CheckFilledCircle, EditSquare, RevisionOutlined, WrenchFilled } from 'assets/icons';
import { t } from 'i18next';
import { FunctionComponent, SVGProps } from 'react';
import { DeviceEventType } from 'types/DeviceEvent';

export default function getEventMapping(eventType: string): {
	color: string;
	displayName: string;
	icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
} {
	switch (eventType) {
		case DeviceEventType.Installation:
			return {
				color: '#d5eacd',
				displayName: t('Installation'),
				icon: CheckFilledCircle,
			};
		case DeviceEventType.Commissioning:
			return {
				color: '#d5eacd',
				displayName: t('Commissioning'),
				icon: CheckFilledCircle,
			};
		case DeviceEventType.Maintenance:
			return {
				color: '#d2dee8',
				displayName: t('Maintenance'),
				icon: RevisionOutlined,
			};
		case DeviceEventType.DeviceAdded:
			return {
				color: '#ededed',
				displayName: t('Device added'),
				icon: EditSquare,
			};
		case DeviceEventType.DeviceRemoved:
			return {
				color: '#ededed',
				displayName: t('Device removed'),
				icon: EditSquare,
			};
		case DeviceEventType.DeviceComponentAdded:
			return {
				color: '#ededed',
				displayName: t('Device component added'),
				icon: EditSquare,
			};
		case DeviceEventType.DeviceComponentRemoved:
			return {
				color: '#ededed',
				displayName: t('Device component removed'),
				icon: EditSquare,
			};
		case DeviceEventType.DeviceComponentMaintenanceRegistered:
			return {
				color: '#fef3cb',
				displayName: t('Maintenance'),
				icon: WrenchFilled,
			};
		case DeviceEventType.Failure:
			return {
				color: '#f6b2b2',
				displayName: t('Failure'),
				icon: AttentionFilled,
			};
		case DeviceEventType.Repair:
			return {
				color: '#fef3cb',
				displayName: t('Repair'),
				icon: RevisionOutlined,
			};
		default:
			return {
				color: '#ededed',
				displayName: t('Changes to the system'),
				icon: EditSquare,
			};
	}
}
