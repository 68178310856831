import React, { useState } from 'react';
import { IModalAddSystemProps } from './ModalAddSystem.d';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DialogTitle from './components/DialogTitle';
import LargeProgressBar from 'components/ProgressBarLarge';
import { t } from 'i18next';
import PrevButton from './components/PrevButton';
import NextButton from './components/NextButton';
import Header from './components/Header';
import AddMainComponents from './steps/AddMainComponents';
import AddSubcomponents from './steps/AddSubcomponents';
import useStyles from './styles';
import CustomerData from './steps/CustomerData';
import Approval from './steps/Approval';
import Success from './steps/Success';
import backboneApi from 'store/modules/backbone';
import { useDispatch } from 'react-redux';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { WarrantySourceEnum } from 'types/WarrantySourceEnum';
import WarrantyRequest from 'types/WarrantyRequest';
import warrantyApi from 'store/modules/warranty';

const ModalAddSystem = (props: IModalAddSystemProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [failedSteps, setFailedSteps] = useState<Array<number>>([]);
	const [verifiedSteps, setVerifiedSteps] = useState<Array<number>>([]);
	const [activeStep, setActiveStep] = useState(0);
	const [systemName, setSystemName] = useState('');
	const [mainComponents, setMainComponents] = useState<Array<any>>([]);
	const [errorMainComponents, setErrorMainComponents] = useState<Array<any>>([]);
	const [subComponents, setSubComponents] = useState([]);
	const [errorSubcomponents, setErrorSubcomponents] = useState<Array<any>>([]);
	const [customerData, setCustomerData] = useState<any>(null);
	const [errorCustomerData, setErrorCustomerData] = useState<any>(null);
	const [approvalData, setApprovalData] = useState<any>(null);
	const [success, setSuccess] = useState(false);

	const [postSystem] = backboneApi.usePostSystemsMutation();
	const [postWarrantyRequest] = warrantyApi.usePostWarrantyMutation();

	const transformMainComponents = (components: Array<any>) => {
		return components.map((component) => {
			return {
				serial: component.serial,
				material_number: component.material_number,
				type: component.type,
				type_description: component.type_description,
				software_version: component.software_version,
				installation_date: component.installation_date,
				is_main_component: true,
				commissioning_date: component.commissioning_date,
			};
		});
	};

	const handleSubmit = () => {
		const commonPayload = {
			commisioning_date: mainComponents[0].commissioning_date,
			name: systemName,
			alternative_location: customerData ? customerData.addressIsDifferent : false,
			owner: {
				salutation: customerData.salutation,
				company: customerData.company || '',
				firstname: customerData.firstName,
				lastname: customerData.lastName,
				phone: customerData.phone,
				email: customerData.email,
				address: {
					street: customerData.customerStreet,
					house_number: customerData.customerHouseNumber,
					zip_code: customerData.customerZipCode,
					city: customerData.customerCity,
					country: customerData.customerCountry,
				},
			},
			components: [...transformMainComponents(mainComponents), ...transformSubComponents(subComponents)],
		};

		const payload = customerData.addressIsDifferent
			? {
					...commonPayload,
					location: {
						street: customerData.differentCustomerStreet,
						house_number: customerData.differentCustomerHouseNumber,
						zip_code: customerData.differentCustomerZipCode,
						city: customerData.differentCustomerCity,
						country: customerData.differentCustomerCountry,
					},
			  }
			: commonPayload;

		const postSystemPromise = postSystem(payload).unwrap();
		const alt_location = customerData.addressIsDifferent
			? {
					alt_location: true,
					alt_location_address_city: customerData.differentCustomerCity,
					alt_location_address_country: customerData.differentCustomerCountry,
					alt_location_address_house_number: customerData.differentCustomerHouseNumber,
					alt_location_address_street: customerData.differentCustomerStreet,
					alt_location_address_zip_code: customerData.differentCustomerZipCode,
			  }
			: undefined;
		const warrantyRequest: WarrantyRequest = {
			source: WarrantySourceEnum.PP,
			devices: [...transformMainComponents(mainComponents), ...transformSubComponents(subComponents)].map((component) => {
				return {
					serial_number: component.serial,
					installation_date: component.installation_date,
				};
			}),
			owner_address_city: customerData.customerCity,
			owner_address_country: customerData.customerCountry,
			owner_address_house_number: customerData.customerHouseNumber,
			owner_address_street: customerData.customerStreet,
			owner_address_zip_code: customerData.customerZipCode,
			owner_company: customerData.company,
			owner_email: customerData.email,
			owner_firstname: customerData.firstName,
			owner_lastname: customerData.lastName,
			owner_phone: customerData.phone,
			owner_title: customerData.salutation,
		};
		const postWarrantyPromise = postWarrantyRequest(alt_location ? { ...alt_location, ...warrantyRequest } : warrantyRequest).unwrap();
		return Promise.all([postSystemPromise, postWarrantyPromise]);
	};

	const handleSkipSubmit = () => {
		return postSystem({
			commisioning_date: mainComponents[0].commissioning_date,
			name: systemName,
			alternative_location: customerData ? customerData.addressIsDifferent : false,
			components: [...transformMainComponents(mainComponents), ...transformSubComponents(subComponents)],
		}).unwrap();
	};

	const transformSubComponents = (components: Array<any>) => {
		return components.map((component) => {
			return {
				serial: component.serial,
				material_number: component.material_number,
				type: component.type,
				type_description: component.type_description,
				software_version: component.software_version,
				installation_date: component.installation_date,
				is_main_component: false,
				commissioning_date: component.commissioning_date,
			};
		});
	};

	const handleNext = async (skip?: boolean) => {
		switch (activeStep) {
			case 0:
				{
					const errors: Array<any> = [];
					const onlyNumbersRegex = /^\d+$/;
					mainComponents.forEach(async (component: any, index) => {
						if (!component.serial) {
							errors[index] = t('This field is required');
						} else {
							if (!onlyNumbersRegex.test(component.serial) || !component.name.length) {
								errors[index] = t('Invalid serial number');
							}
						}
					});

					if (errors.length) {
						setFailedSteps([...failedSteps, activeStep]);
						setErrorMainComponents(errors);
					} else {
						setVerifiedSteps([...verifiedSteps, activeStep]);
						setFailedSteps(failedSteps.filter((value) => value !== activeStep));
						setActiveStep(activeStep + 1);
						setErrorMainComponents([]);
					}
				}
				break;

			case 1:
				{
					const errors: Array<any> = [];
					const onlyNumbersRegex = /^\d+$/;
					subComponents.forEach((component: any, index) => {
						if (!component.serial) {
							errors[index] = t('This field is required');
						} else {
							if (!onlyNumbersRegex.test(component.serial) || !component.name.length) {
								errors[index] = t('Invalid serial number');
							}
						}
					});

					if (errors.length) {
						setFailedSteps([...failedSteps, activeStep]);
						setErrorSubcomponents(errors);
					} else {
						setVerifiedSteps([...verifiedSteps, activeStep]);
						setFailedSteps(failedSteps.filter((value) => value !== activeStep));
						setActiveStep(activeStep + 1);
						setErrorSubcomponents([]);
					}
				}
				break;
			case 2:
				{
					const errors: any = {};
					if (skip) {
						setCustomerData(null);
						handleSkipSubmit()
							.then(() => {
								setVerifiedSteps([...verifiedSteps, activeStep]);
								setFailedSteps(failedSteps.filter((value) => value !== activeStep));
								setActiveStep(4);
								setSuccess(true);
								setErrorCustomerData(null);
							})
							.catch(() => {
								setFailedSteps([...failedSteps, activeStep]);
							});
						break;
					}
					if (!customerData) {
						setFailedSteps([...failedSteps, activeStep]);
						break;
					} else {
						if (customerData.customerAddressType) {
							if (!customerData.firstName?.length) {
								errors.firstName = t('This field is required');
							}
							if (!customerData.lastName?.length) {
								errors.lastName = t('This field is required');
							}
							if (!customerData.email?.length) {
								errors.email = t('This field is required');
							}
							if (!customerData.customerStreet?.length) {
								errors.customerStreet = t('This field is required');
							}
							if (!customerData.customerHouseNumber?.length) {
								errors.customerHouseNumber = t('This field is required');
							}
							if (!customerData.customerZipCode?.length) {
								errors.customerZipCode = t('This field is required');
							}
							if (!customerData.customerCity?.length) {
								errors.customerCity = t('This field is required');
							}
							if (!customerData.customerCountry?.length) {
								errors.customerCountry = t('This field is required');
							}
						}
						if (customerData.addressIsDifferent && customerData.differentCustomerAddressType) {
							if (!customerData.differentCustomerStreet?.length) {
								errors.differentCustomerStreet = t('This field is required');
							}
							if (!customerData.differentCustomerHouseNumber?.length) {
								errors.differentCustomerHouseNumber = t('This field is required');
							}
							if (!customerData.differentCustomerZipCode?.length) {
								errors.differentCustomerZipCode = t('This field is required');
							}
							if (!customerData.differentCustomerCity?.length) {
								errors.differentCustomerCity = t('This field is required');
							}
							if (!customerData.differentCustomerCountry?.length) {
								errors.differentCustomerCountry = t('This field is required');
							}
						}
					}
					if (Object.keys(errors).length) {
						setFailedSteps([...failedSteps, activeStep]);
						setErrorCustomerData(errors);
					} else {
						setVerifiedSteps([...verifiedSteps, activeStep]);
						setFailedSteps(failedSteps.filter((value) => value !== activeStep));
						setActiveStep(activeStep + 1);
						setErrorCustomerData(null);
					}
				}
				break;
			case 3: {
				if (!approvalData) {
					setFailedSteps([...failedSteps, activeStep]);
					break;
				} else if (!approvalData.firstConsent || !approvalData.secondConsent || !approvalData.uploadedFiles.length || !systemName.length) {
					setFailedSteps([...failedSteps, activeStep]);
					break;
				} else {
					setVerifiedSteps([...verifiedSteps, activeStep]);
					setFailedSteps(failedSteps.filter((value) => value !== activeStep));
					handleSubmit()
						.then((res) => {
							setSuccess(true);
							setActiveStep(activeStep + 1);
						})
						.catch((err) => {
							setFailedSteps([...failedSteps, activeStep]);
						});
				}
				break;
			}
		}
	};

	const closeModal = () => {
		setFailedSteps([]);
		setVerifiedSteps([]);
		setActiveStep(0);
		setSystemName('');
		setMainComponents([]);
		setErrorMainComponents([]);
		setSubComponents([]);
		setErrorSubcomponents([]);
		setCustomerData(null);
		setErrorCustomerData(null);
		setApprovalData(null);
		setSuccess(false);
	};

	const handleClose = () => {
		dispatch(heatingDevicePortalActions.clearSystemDevices());
		closeModal();
		props.handleClose();
	};

	const handlePrev = () => {
		if (activeStep > 0) {
			setActiveStep(activeStep - 1);
		}
	};

	const handleNavigation = (step: number) => {
		if (step < activeStep) {
			setActiveStep(step);
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			PaperProps={{
				className: classes.dialogPaper,
			}}>
			{!success && (
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					<div style={{ maxWidth: '80%', margin: 'auto' }}>
						<LargeProgressBar
							steps={[t('Geräte'), t('Komponenten'), t('Kunde'), t('Zustimmung')]}
							failedSteps={failedSteps}
							activeStep={activeStep}
							setActiveStep={(step) => handleNavigation(step)}
						/>
					</div>
				</DialogTitle>
			)}
			<DialogContent className={classes.dialogContent}>
				{!success && <Header name={systemName} setName={setSystemName} disabled={activeStep === 3 || success} />}
				<div className={classes.slider} style={{ transform: `translateX(calc(-${activeStep * 80}vw))` }}>
					<div className={classes.step}>
						<AddMainComponents saveData={setMainComponents} errors={errorMainComponents} />
					</div>
					<div className={classes.step}>
						<AddSubcomponents empty saveData={setSubComponents} errors={errorSubcomponents} />
					</div>
					<div className={classes.step}>
						<CustomerData saveData={setCustomerData} errors={errorCustomerData} />
					</div>
					<div className={classes.step}>
						<Approval saveData={setApprovalData} />
					</div>
					<div className={classes.step}>
						<Success customerData={customerData} title={t('System was added')} withHeadline={false} />
					</div>
				</div>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				{activeStep > 0 && (
					<PrevButton onClick={handlePrev} variant="contained" color="primary" disabled={activeStep === 0}>
						{t('Back')}
					</PrevButton>
				)}
				{activeStep === 2 && (
					<PrevButton onClick={() => handleNext(true)} variant="contained" color="primary">
						{t('Skip')}
					</PrevButton>
				)}
				<NextButton type="submit" onClick={() => handleNext()} variant="contained" color="primary" disabled={activeStep === 4}>
					{t('Next')}
				</NextButton>
			</DialogActions>
		</Dialog>
	);
};

export default ModalAddSystem;
