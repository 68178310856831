import { configureStore, combineReducers } from '@reduxjs/toolkit';
import heatingdeviceReducer from './modules/heatingdevice/reducer';
import uiReducer from './modules/ui/reducer';
import backboneApi from './modules/backbone';
import pimcoreApi from './modules/pimcore';
import iotApi from './modules/iot';
import attachmentsApi from './modules/attachments';

// import { errorMiddleware } from './middleware/errorHandler';
import pimApi from './modules/pim';
import sapApi from './modules/sap';
import customersApi from './modules/customers';
import warrantyApi from './modules/warranty';

const rootReducer = combineReducers({
	heatingdevice: heatingdeviceReducer,
	ui: uiReducer,
	[backboneApi.reducerPath]: backboneApi.reducer,
	[pimcoreApi.reducerPath]: pimcoreApi.reducer,
	[pimApi.reducerPath]: pimApi.reducer,
	[iotApi.reducerPath]: iotApi.reducer,
	[attachmentsApi.reducerPath]: attachmentsApi.reducer,
	[sapApi.reducerPath]: sapApi.reducer,
	[customersApi.reducerPath]: customersApi.reducer,
	[warrantyApi.reducerPath]: warrantyApi.reducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
			// .concat(errorMiddleware)
			.concat(backboneApi.middleware)
			.concat(pimcoreApi.middleware)
			.concat(attachmentsApi.middleware)
			.concat(pimApi.middleware)
			.concat(sapApi.middleware)
			.concat(iotApi.middleware)
			.concat(customersApi.middleware)
			.concat(warrantyApi.middleware),
	devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export default store;
