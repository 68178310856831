import { HistoryFilled } from 'assets/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SummiteerWidgetProps } from './summiteer.d';
import { Skeleton } from '@material-ui/lab';

function SummiteerWidget(props: SummiteerWidgetProps): JSX.Element {
	const { summiteerPoints, newPoints, backgroundImg, fontColor, cardLink, loading, enrolled, cardText, cardBottomText } = props;
	const { t } = useTranslation();


	return (
		<div className="m-widget-summiteer">
			<a href={cardLink}>
				<div
					className="summiteer-container"
					style={{
						backgroundImage: `url(${backgroundImg})`,
					}}>
					{loading ? (
						<div className="skeleton-container">
							<Skeleton height={60} />
						</div>
					) : (
						<h3 className="customer-points" style={{ color: fontColor }}>
							{enrolled ? summiteerPoints : cardText && t(cardText)}
						</h3>
					)}
					{enrolled ? <div className="icon-container">
						<HistoryFilled />
						<span style={{ color: fontColor }}>{t('Summiteer')}</span>
					</div> : null}
					<div className="new-points-container">
						<section className="divider"></section>
						{loading ? (
							<div className="skeleton-container">
								<Skeleton height={40} />
								<Skeleton width={180} height={40} />
							</div>
						) : enrolled ? (<>
							<p style={{ color: fontColor }}>{newPoints}</p>
							<p style={{ color: fontColor }}>{t('Summiteer Points since last login')}</p>
						</>)
							: (<>
								<p style={{ color: fontColor }}>{cardBottomText && t(cardBottomText)}</p>
							</>)}
					</div>
				</div>
			</a>
		</div>
	);
}

export default SummiteerWidget;
