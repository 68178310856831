import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Header, DeviceTable, SummiteerWidget, WidgetCurrentEvents, FloatingButton, ConfirmationDialog as DeleteDialog } from 'components';

import { HeaderProps } from 'components/types';
import ROUTES from 'constants/routes';
import { PlusFilled } from 'assets/icons';
import backboneApi from 'store/modules/backbone';
import { headerSelector, initialHeaderSelector, summiteerSelector } from 'store/modules/heatingdevice/selectors';
import { IHeatingDeviceShowableColumns } from 'types/IHeatingDevice';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { AppDispatch } from 'store';

import ModalAddSystem from 'components/ModalAddSystem/ModalAddSystem';
import showToast from 'services/utils/toaster';

function Dashboard(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [openAddSystemModal, setOpenAddSystemModal] = useState<boolean>(false);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<Row<Record<string, unknown>>>({} as Row<Record<string, unknown>>);
	const [filters, setFilters] = useSearchParams('expand=components');

	const { setHeaderProps } = heatingDevicePortalActions;

	const initialHeaderProps = useSelector(initialHeaderSelector);
	const headerProps = useSelector(headerSelector);
	const summiteerProps = useSelector(summiteerSelector);

	dispatch(setHeaderProps(initialHeaderProps));

	const { data: devicesData, isLoading: systemsLoading } = backboneApi.useGetSystemsQuery(filters.toString());
	const { data: allDevices } = backboneApi.useGetSystemsQuery('offset=0&limit=0&expand=components');
	const [deleteSystem] = backboneApi.useDeleteSystemMutation();
	const [deleteComponent] = backboneApi.useDeleteSystemComponentMutation();

	const columnOrder = ['expander', 'name', 'is_maintenance', 'type_description', 'serial', 'owner', 'location'];
	const showColumns: IHeatingDeviceShowableColumns = {
		// To be added back when the filter boxes will be used & the status will be anything else other than Offline
		// status: true,
		name: true,
		is_maintenance: true,
		type_description: true,
		serial: true,
		owner: true,
		location: true,
	};
	const buttons: Array<any> = [
		{
			name: t('Delete'),
			onClick: (e: React.MouseEvent<HTMLDivElement>): void => {
				e && e.stopPropagation();
				setOpenDeleteModal(true);
			},
		},
	];

	const onConfirm = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		onDelete(selectedRow.id, selectedRow.parentId);
		setOpenDeleteModal(false);
	};

	const onCancel = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		setOpenDeleteModal(false);
	};

	const onDelete = async (uuid: string, parentId?: string) => {
		if (parentId) {
			deleteComponent({ systemId: parentId, componentId: uuid }).catch((err) => {
				showToast.error(err.message);
			});
			return;
		}
		deleteSystem(uuid).catch((err) => {
			showToast.error(err.message);
		});
	};
	/**** Uncomment when filter boxes will be used. Type needs to be defined. */
	// const handleFilterChange = (filters: any) => {
	// 	navigate(`${ROUTES.LIST}?${new URLSearchParams(filters).toString()}`);
	// };

	const handleSortBy = useCallback(
		(sortBy: Array<string>) => {
			if (sortBy.length) {
				setFilters((prev) => {
					prev.set('sort', sortBy.join(','));
					return prev;
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters],
	);

	const handleMoreClick = (e: React.MouseEvent, row: Row<Record<string, unknown>>) => {
		e && e.stopPropagation();
		setSelectedRow(row);
	};

	const handleSearch = (searchBy: string, search: string) => {
		if (!search) {
			setFilters((prev) => {
				prev.delete(searchBy);
				return prev;
			});
			return;
		}
		setFilters((prev) => {
			prev.set(searchBy, search);
			return prev;
		});
	};

	return (
		<div className="dashboard-container">
			<Header {...(headerProps as HeaderProps)} />
			<div className="container">
				{/**** Uncomment when the filter boxes will be useful   */}
				{/* <div className="filters-container">
					<StatusFilters onFilter={handleFilterChange} />
				</div> */}
				<div className="mid-size-content">
					<div className="table-container">
						<DeviceTable
							withExpander
							withSearch
							withHeader
							title={t('My Devices').toString()}
							buttons={buttons}
							headerLinkUrl={`${ROUTES.LIST}`}
							textLinkType={'underlined_medium'}
							onRowClick={(_, row) => {
								navigate(`${ROUTES.DETAIL}${row.original.uuid}`);
							}}
							data={devicesData}
							allData={allDevices}
							onMoreClick={handleMoreClick}
							onSortBy={handleSortBy}
							onSearch={debounce(handleSearch, 700)}
							columnOrder={columnOrder}
							isLoading={systemsLoading}
							showColumns={showColumns}
							defaultSortBy={filters.get('sort')}
							onHeaderLinkClick={(e) => {
								e.preventDefault();
								navigate(`${ROUTES.LIST}`);
							}}
							type="dashboard"
						/>
					</div>
					<div className="widgets-container">
						<div className="widget-container-gap">
							<SummiteerWidget {...summiteerProps} />
						</div>
						<div className="widget-container-gap">
							<WidgetCurrentEvents
								detailedView={false}
								headline={t('Current events')}
								showAll={{ link: '#', label: t('Show All') }}
								stacksTimeString={[t('Today'), t('Yesterday'), t('This Week'), t('Last Two Weeks')]}
							/>
						</div>
					</div>
				</div>
			</div>
			<FloatingButton
				type="default"
				text={t('Add system')}
				icon={<PlusFilled />}
				onClick={() => {
					setOpenAddSystemModal(true);
				}}
			/>
			<ModalAddSystem open={openAddSystemModal} handleClose={() => setOpenAddSystemModal(false)} />
			<DeleteDialog open={openDeleteModal} title={t('Delete device')} content={t('Are you sure?')} onConfirm={onConfirm} onCancel={onCancel} />
		</div>
	);
}

export default Dashboard;
