import React, { useCallback, useEffect } from 'react';
import { AddComponentFormData, AddComponentsProps, ValidationErrors } from './AddComponent.d';
import FormGroup from './components/FormGroup';
import { AddOutlinedCircle } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

function AddComponent(props: AddComponentsProps): JSX.Element {
	const { findMaterialNumberLink, isEditModal, prefilledData, data, nextPressed, handleValidation } = props;

	const { t } = useTranslation();

	const [forms, setForms] = React.useState(
		data
			? data.map((item: AddComponentFormData) => ({
				id: uuidv4(),
				serial: item.serial,
				name: item.type_description,
				installation_date: new Date(item.installation_date),
			}))
			: [
				{
					id: uuidv4(),
					serial: '',
					name: '',
					installation_date: new Date(),
				},
			],
	);

	useEffect(() => {
		if (isEditModal) {
			setForms([
				{
					id: 0,
					serial: prefilledData?.serial as string,
					name: prefilledData?.name as string,
					installation_date: prefilledData?.installationDate as Date,
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [name, setName] = React.useState<string>('');
	const [errors, setErrors] = React.useState<Array<any>>([]);

	const handleDelete = () => {
		if (forms.length > 1) {
			setForms(forms.slice(0, forms.length - 1));
		}
	};
	const handleCreateForm = () => {
		setForms([
			...forms,
			{
				id: uuidv4(),
				serial: '',
				name: '',
				installation_date: new Date(),
			},
		]);
	};

	const validateData = useCallback(
		(data: Array<AddComponentFormData>): boolean => {
			let err: Array<ValidationErrors> = [];
			data.forEach((item: AddComponentFormData, index: number) => {
				err[index] = {};
				if (!item.serial || !/^\d+$/.test(item.serial)) {
					err[index].serial = t('Valid serial number needed');
				}
			});
			setErrors(err);
			err = err.filter((error) => Object.keys(error).length !== 0);
			return !err.length;
		},
		[t],
	);

	useEffect(() => {
		if (!handleValidation || !nextPressed) return;
		if (data && data.length) {
			const hasData = data.some((item: AddComponentFormData) => item.serial && item.type_description);
			if (hasData) {
				handleValidation(validateData(data));
				return;
			}
			handleValidation(true);
		}
	}, [data, handleValidation, validateData, nextPressed]);

	return (
		<div className="m-system-management-add-components">
			{forms.map((form, index: number) => {
				return (
					<FormGroup
						key={index}
						id={form.id}
						errors={errors[index]}
						formGroupTitle={form.name || `${t('Component')} ${index + 1}`}
						toggle={{ id: 'toggleIdentifier' + index, items: [t('Serial number'), t('Material number')] }}
						inputName={{
							placeholder: t('ComponentName').toString() ?? undefined,
							label: t('ComponentName').toString() ?? undefined,
							type: 'label',
							value: form.name,
							width: 350,
						}}
						inputSerialNumber={{
							placeholder: t('Serial number').toString() ?? undefined,
							label: t('Serial number').toString() ?? undefined,
							type: 'label',
							value: form.serial,
							width: 350,
						}}
						inputMaterialNumber={{
							placeholder: t('Material number').toString() ?? undefined,
							label: t('Material number').toString() ?? undefined,
							type: 'label',
							width: 350,
						}}
						installationDate={form.installation_date}
						installationDateLabel={t('InstallationDate').toString()}
						defaultInstallationDate={form.installation_date ?? new Date()}
						firstUseDateLabel={t('FirstUseDate').toString()}
						deleteAction={() => handleDelete()}
						findMaterialNumberText={t('FindMaterialNumberText').toString()}
						findMaterialNumberLink={findMaterialNumberLink}
						findMaterialNumberLinkText={t('FindMaterialNumber').toString()}
					/>
				);
			})}
			<button className="addFormButton" onClick={handleCreateForm}>
				<AddOutlinedCircle className="addIcon" />
				{t('AddAnotherComponent')}
			</button>
		</div>
	);
}
export default AddComponent;
