import React, { useEffect } from 'react';
import { HistoryStatisticsProps } from './HistoryStatistics.d';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import { eventsFiltersSelector } from 'store/modules/heatingdevice/selectors';
import { Skeleton } from '@material-ui/lab';
import { DeviceEvent, DeviceEventType } from 'types/DeviceEvent';
import getEventMapping from 'services/utils/getEventMapping';

//will be replaced by translation
const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
const supportedEventTypes = [
	DeviceEventType.Installation,
	DeviceEventType.Commissioning,
	DeviceEventType.Maintenance,
	DeviceEventType.Repair,
	DeviceEventType.Failure,
	DeviceEventType.DeviceComponentMaintenanceRegistered,
];

function HistoryStats(props: HistoryStatisticsProps): JSX.Element {
	const { events } = props;

	const [filteredEvents, setFilteredEvents] = React.useState<Array<DeviceEvent>>(events);
	const [calculatedEvents, setCalculatedEvents] = React.useState<Array<{ name: string; count: number }>>([]);
	const [sum, setSum] = React.useState<number>(0);

	const [startMonth, setStartMonth] = React.useState<number>(1);
	const [endMonth, setEndMonth] = React.useState<number>(12);

	const [loading, setLoading] = React.useState<boolean>(true);

	const eventsFilters = useSelector(eventsFiltersSelector);

	const innerRadius = '65%';
	const outerRadius = '80%';

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 650);
	}, []);

	useEffect(() => {
		if (!eventsFilters) {
			setStartMonth(1);
			setEndMonth(12);
			return;
		}

		let startDate: Date;
		let endDate: Date;

		if (eventsFilters && eventsFilters.creation_date_from && eventsFilters.creation_date_to) {
			startDate = new Date(eventsFilters.creation_date_from);
			startDate.setTime(startDate.getTime() - startDate.getTimezoneOffset() * -1 * 60 * 1000);
			endDate = new Date(eventsFilters.creation_date_to);
			endDate.setTime(endDate.getTime() - endDate.getTimezoneOffset() * -1 * 60 * 1000);
			setStartMonth(startDate.getMonth() + 1);
			setEndMonth(endDate.getMonth() + 1);
		}

		if (eventsFilters?.creation_date_from != undefined && eventsFilters?.creation_date_to != undefined)
			setFilteredEvents(events.filter((event: DeviceEvent) => new Date(event.creation_date) >= startDate && new Date(event.creation_date) <= endDate));
	}, [eventsFilters, events]);

	useEffect(() => {
		const filteredEvents: Array<{ name: string; count: number }> = events
			.filter((event: DeviceEvent) => new Date(event.creation_date).getMonth() + 1 >= startMonth && new Date(event.creation_date).getMonth() + 1 <= endMonth)
			.map((obj: DeviceEvent) => ({ name: supportedEventTypes.includes(obj.type as DeviceEventType) ? obj.type : 'other' }))
			.reduce((acc: Array<{ name: string; count: number }>, obj) => {
				const index = acc.findIndex((item: { name: string }) => item.name === obj.name);
				if (index !== -1) {
					acc[index].count++;
				} else {
					acc.push({ name: obj.name, count: 1 });
				}
				return acc;
			}, []);
		setCalculatedEvents(filteredEvents);
		setSum(filteredEvents.reduce((acc, obj) => acc + obj.count, 0));
	}, [filteredEvents]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="m-history-statistics" id="historyStatistics">
			{loading ? (
				<div className="headline-container">
					<Skeleton width={200} height={40} />
				</div>
			) : (
				<span className="headline">
					{startMonth === endMonth ? monthNames[startMonth - 1] : `${monthNames[startMonth - 1]} - ${monthNames[endMonth - 1]}`}
				</span>
			)}
			<div className="chart-container">
				<div className="container">
					{loading ? (
						<div className="overlay">
							<Skeleton width={40} height={40} />
						</div>
					) : (
						<span className="overlay">{sum}</span>
					)}
					<span className="label">Ereignisse</span>
					<ResponsiveContainer height={200}>
						<PieChart>
							<Pie
								data={calculatedEvents}
								dataKey="count"
								nameKey="name"
								fill="#8884d8"
								startAngle={90}
								endAngle={-270}
								innerRadius={innerRadius}
								outerRadius={outerRadius}>
								{calculatedEvents.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={getEventMapping(entry.name).color} />
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className="list-container">
					<ul className="list">
						{loading ? (
							<>
								<li>
									<Skeleton width={130} />
								</li>
								<li>
									<Skeleton width={130} />
								</li>
								<li>
									<Skeleton width={130} />
								</li>
								<li>
									<Skeleton width={130} />
								</li>
								<li>
									<Skeleton width={130} />
								</li>
							</>
						) : (
							<>
								{calculatedEvents.map((entry, index) => (
									<li key={index} style={{ display: 'flex', alignItems: 'center' }}>
										<div className="circle" style={{ backgroundColor: getEventMapping(entry.name).color }}></div>
										<span className="event-label">{getEventMapping(entry.name).displayName}</span>
										<span className="event-count">{entry.count}</span>
									</li>
								))}
							</>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
}
export default HistoryStats;
