import React from 'react';
import StatusBar from './components/StatusBar';
import { StatusBarProps } from './StatusBar.d';

function SystemHeader(props: StatusBarProps): JSX.Element {
	return (
		<div className="m-system-management-status-bar">
			<StatusBar {...props} />
		</div>
	);
}

export default SystemHeader;
