import { FetchBaseQueryError, FetchBaseQueryMeta, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiBaseUrls from 'constants/apiBaseUrls';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { heatingDevicePortalActions } from '../heatingdevice/reducer';

type PimcoreResponse = {
	success: boolean;
	statusCode: number;
	data: {
		token: string;
	};
	message: string | null;
};

// Define a service using a base URL and expected endpoints
export const pimcoreApi = createApi({
	reducerPath: 'pimcoreApi',
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pimcore,
	}),
	tagTypes: ['Assignments'],
	endpoints: (builder) => ({
		getToken: builder.query<PimcoreResponse, any>({
			query: () => `/token`,
			onQueryStarted: (_, { dispatch }) => {
				if (process.env.NODE_ENV === 'development') {
					dispatch(heatingDevicePortalActions.setToken('token_here')); // DON'T PUSH TOKEN TO REPO
				}
			},
		}),
		getDeviceAssignments: builder.query<any, { systemIds: string[]; search?: string; filter?: string }>({
			queryFn: async ({ systemIds, search, filter }, _api, _extraOptions, fetchWithBQ) => {
				const params: Record<string, string> = {};
				if (search) {
					params.search = search;
				}
				if (filter) {
					params.filter = filter;
				}
				const searchParams = new URLSearchParams(params);
				const results: QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta>[] = [];
				for (const id of systemIds) {
					const url = `/devices/${id}/assignments?${searchParams}`;
					const result = await fetchWithBQ(url);
					results.push(result);
				}
				const hasErrors = results.some((result) => result.error);
				return hasErrors
					? { error: { status: 'CUSTOM_ERROR', error: 'Fetching assignments for one of the systems failed' } }
					: {
							data: results
								.flat()
								.map((result) => result.data.data)
								.flat(),
					  };
			},
			providesTags: ['Assignments'],
		}),
		getAddModalData: builder.query<any, string>({
			query: () => `/get-modal-data`,
		}),
		assignEmployees: builder.mutation<any, { systemId: string; payload: any }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}/assignments`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['Assignments'],
		}),
		addEmployee: builder.query<any, string>({
			query: () => `/add-employee`,
		}),
		deleteEmployee: builder.mutation<any, { systemId: string; employeeId: string }>({
			query: ({ systemId, employeeId }) => ({
				url: `/devices/${systemId}/assignments/${employeeId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Assignments'],
		}),
	}),
});

export const { getToken, getDeviceAssignments, getAddModalData, assignEmployees, addEmployee, deleteEmployee } = pimcoreApi.endpoints;

export default pimcoreApi;
