export const DeviceTableTranslations = {
	Components: 'Komponenten',
	'Add Component': 'Komponente hinzufügen',
	'My Devices': 'Meine Anlagen',
	'Device Search': 'Anlagensuche',
	'All Devices': 'Alle anzeigen',
	List: 'Liste',
	Grid: 'Raster',
	Actions: 'Mehrfachaktionen',
	'Perform action': 'Aktion ausführen',
	Assign: 'Zuweisen',
	Edit: 'Bearbeiten',
	Delete: 'Löschen',
	Seriennummer: 'Seriennummer',
	Type: 'Typ',
	'Manufacture date': 'Manufacture date',
	'Installation date': 'Installation date',
	'Commissioning date': 'Commissioning date',
	Name: 'Name',
};

export const DeviceTableColumnNames = {
	status: 'Status',
	description: 'Beschreibung',
	device: 'Gerät',
	serial: 'Seriennummer',
	owner: 'Inhaber',
	address: 'Adresse',
	uuid: 'Uuid',
	type: 'Gerät',
	is_failure: 'Is failure',
	is_maintenance: 'Maintenance',
	manufacture_date: 'Manufacture date',
	installation_date: 'Installation date',
	commissioning_date: 'Commissioning date',
	name: 'Systemname',
	is_iot_permission_granted: 'Is iot permission granted',
	component: 'Komponente',
	serialNumber: 'Seriennummer',
	softwareVersion: 'Software Version',
	software_version: 'Software Version',
	installedAt: 'Installiert am',
};
