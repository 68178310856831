import React from 'react';
import { ModalAddMaintenanceCardProps } from '../ModalAddMaintenance.d';
import Checkbox from 'components/Checkbox';

function Card(props: ModalAddMaintenanceCardProps): JSX.Element {
	const { columns, checkboxValue, checked, onCheck } = props;

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onCheck && onCheck(event);
	};

	return (
		<div className={`m-card ${checked ? 'checked' : ''}`} id="step1">
			<Checkbox
				label=""
				checked={checked || false}
				name={checkboxValue.toString()}
				value={checkboxValue.toString()}
				onChange={handleCheckboxChange}
			/>
			{columns.map((column, index) => (
				<div className="card-column" key={index}>
					<div className="column-label">{column.label}</div>
					<div className="column-value">{column.value}</div>
				</div>
			))}
		</div>
	);
}
export default Card;
