import React from 'react';
import { ChipFiltersProps } from './ChipFilters.d';
import './ChipFilters.scss';
import { Chip } from '@material-ui/core';
import { WrenchFilled } from 'assets/icons';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';

const ChipFilters = (props: ChipFiltersProps) => {
	const { onFilter, data } = props;
	const [filters, _] = useSearchParams();
	return (
		<div className="SM-chip-filters">
			<Chip
				label={`${t('All')} (${data?.items.length})`}
				className={`filter-chip ${filters?.get('is_maintenance') ? '' : 'selected'}`}
				onClick={() => onFilter(false)}
			/>
			<Chip
				icon={<WrenchFilled className="icon" />}
				label={`${t('Maintenance due')} (${(data?.items as Array<any>)?.filter((item) => item.is_maintenance).length})`}
				className={`filter-chip ${filters?.get('is_maintenance') ? 'selected' : ''}`}
				onClick={() => onFilter(true)}
			/>
		</div>
	);
};

export default ChipFilters;
