import React, { useState } from 'react';
import useStyles from 'components/ModalAddSystem/styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import LargeProgressBar from 'components/ProgressBarLarge';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/ModalAddSystem/components/DialogTitle';
import { ModalAddComponentProps } from './ModalAddComponent.d';
import AddMainComponents from 'components/ModalAddSystem/steps/AddMainComponents';
import NextButton from 'components/ModalAddSystem/components/NextButton';
import Success from 'components/ModalAddSystem/steps/Success';
import AddSubcomponents from 'components/ModalAddSystem/steps/AddSubcomponents';
import { useDispatch } from 'react-redux';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { IFormGroupComponents } from 'components/ModalAddSystem/ModalAddSystem.d';
import backboneApi from 'store/modules/backbone';
import { useParams } from 'react-router-dom';

function ModalAddComponent(props: ModalAddComponentProps): JSX.Element {
	const { open, variation } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { id } = useParams();
	const [postSystemComponents] = backboneApi.usePostSystemComponentsMutation();

	const [activeStep, setActiveStep] = useState<number>(0);
	const [failedSteps, setFailedSteps] = useState<Array<number>>([]);
	const [components, setComponents] = useState<Array<IFormGroupComponents>>([]);
	const [errorComponents, setErrorComponents] = useState<Array<{ [key: string]: string }>>([]);

	const transformComponent = (component: IFormGroupComponents) => {
		const transformedComponent: any = {
			serial: component.serial,
			type: component.name,
			type_description: component.name,
			installation_date: component.installation_date,
			is_main_component: variation === 'main',
		};

		if (component.commissioning_date) {
			transformedComponent.commissioning_date = component.commissioning_date;
		}

		if (component.material === false) {
			transformedComponent.material_number = component.serial;
			delete transformedComponent.serial;
		}

		return transformedComponent;
	};

	const handleNavigation = (step: number) => {
		if (step < activeStep) {
			setActiveStep(step);
		}
	};

	const handleClose = () => {
		setActiveStep(0);
		setFailedSteps([]);
		setComponents([]);
		setErrorComponents([]);
		dispatch(heatingDevicePortalActions.clearSystemDevices());
		props.handleClose();
	};

	const handleSubmit = () => {
		if (activeStep === 1) {
			handleClose();
			return;
		}

		const errors: Array<any> = [];
		const componentPromises: Array<Promise<any>> = [];
		const onlyNumbersRegex = /^\d+$/;
		components.forEach((component: any, index) => {
			if (!component.serial) {
				errors[index] = t('This field is required');
			} else {
				if (!onlyNumbersRegex.test(component.serial) || !component.name.length) {
					errors[index] = t('Invalid serial number');
				}
			}
		});

		if (errors.length) {
			setFailedSteps([...failedSteps, activeStep]);
			setErrorComponents(errors);
		} else {
			components.forEach((component) => {
				componentPromises.push(postSystemComponents({ systemId: id!, payload: transformComponent(component) }).unwrap());
			});
			Promise.all(componentPromises).then(() => {
				setFailedSteps(failedSteps.filter((value) => value !== activeStep));
				setActiveStep(activeStep + 1);
				setErrorComponents([]);
			});
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			PaperProps={{
				className: classes.dialogPaper,
			}}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				<div style={{ maxWidth: '80%', margin: 'auto' }}>
					<LargeProgressBar
						steps={variation === 'main' ? [t('IndividualDevices')] : [t('Components')]}
						failedSteps={failedSteps}
						activeStep={activeStep}
						setActiveStep={(step) => handleNavigation(step)}
					/>
				</div>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.slider} style={{ transform: `translateX(calc(-${activeStep * 80}vw))` }}>
					<div className={classes.step}>
						{variation === 'main' ? (
							<AddMainComponents saveData={setComponents} errors={errorComponents} />
						) : (
							<AddSubcomponents saveData={setComponents} errors={errorComponents} />
						)}
					</div>
					<div className={classes.step}>
						<Success title={t('Component was added')} />
					</div>
				</div>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<NextButton
					type="submit"
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary"
					disabled={activeStep === 1 || !components.length}>
					{activeStep === 1 ? t('Finish') : t('Save')}
				</NextButton>
			</DialogActions>
		</Dialog>
	);
}

export default ModalAddComponent;
