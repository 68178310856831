import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useParameters } from 'hooks/useParameters';
import { patchSystem } from 'store/modules/backbone';
import {
	SystemHeader,
	WidgetCurrentEvents,
	StatusBar,
	AssignEmployeesWidgetComponent,
	DeviceViewWidget,
	CustomerDetailsWidgetComponent,
	ComponentListWidget,
	NotesWidget,
	HistoryEventsList,
	CalendarRepresentation,
	HistoryStatistics,
	ModalRCFunctions,
	ModalRCDevices,
	AtomFloatingButtonComponent,
	ModalAddWarranty,
	MaintenanceModal,
	ModalAddComponent,
	ModalNotes,
	WidgetMaintenances,
} from 'components';

import { IFloatingMenuButton, HeaderProps, SystemDetailsProps, MaintenanceModalData, Note } from 'components/types';
import { IComponent } from 'types/Component';
import backboneApi from 'store/modules/backbone';
import { AddOutlinedCircle, IllDragDrop, PlusFilled } from 'assets/icons';
import { useParams, useNavigate } from 'react-router-dom';
import iotApi from 'store/modules/iot';
import { eventsFiltersSelector, headerSelector, initialHeaderSelector } from 'store/modules/heatingdevice/selectors';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';

function SystemDetails(props: SystemDetailsProps): JSX.Element {
	const { customerDetailsProps, statusBarProps, header } = props;
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const eventsFilters = useSelector(eventsFiltersSelector);

	const [viewTab, setViewTab] = useState('Detailed View');

	const initialHeaderProps = useSelector(initialHeaderSelector);
	const headerProps = useSelector(headerSelector);
	const { setHeaderProps } = heatingDevicePortalActions;

	const [openAddMainComponentModal, setopenAddMainComponentModal] = useState<boolean>(false);

	const [openAddSubcomponentModal, setopenAddSubcomponentModal] = useState<boolean>(false);

	const [openModalRCFunctions, setOpenModalRCFunctions] = useState<boolean>(false);
	const [ModalRCFunctionsTitle, setModalRCFunctionsTitle] = useState<string>('');
	const [ModalRCFunctionsIcon, setModalRCFunctionsIcon] = useState<ReactElement | undefined>(undefined);

	const [openModalRCDevices, setOpenModalRCDevices] = useState<boolean>(false);
	const [ModalRCDevicesSubmenu, setModalRCDevicesSubmenu] = useState<{ name: string; pages: any[] }>({ name: '', pages: [] });

	const [openModalAddWarranty, setOpenModalAddWarranty] = useState<boolean>(false);
	const [modalAddWarrantyPrefilledData, setModalAddWarrantyPrefilledData] = useState<any>(null);
	const [openModalNotes, setOpenModalNotes] = useState<boolean>(false);
	const [noteToEdit, setNoteToEdit] = useState<Note | undefined>(undefined);
	const [openModalMaintenance, setOpenModalMaintenance] = useState<boolean>(false);
	const [endpoint, setEndpoint] = useState<string>('');
	const [selectedCard, setSelectedCard] = useState<number>(-1);

	const { width } = useWindowDimensions();
	const small = width < 1820;
	const medium = width >= 1820 && width < 2560;
	const large = width >= 2560;
	const { t } = useTranslation();

	const routeParams = useParams();
	const { id } = routeParams;
	if (!id) navigate('/404');

	const { data: deviceData } = backboneApi.useGetSystemQuery(id!);
	const { data: components, isLoading: isComponentsLoading, isFetching: isComponentsFetching } = backboneApi.useGetSystemComponentsQuery(id!);
	const { data: currentEventsData, isLoading: eventsLoading, isFetching: eventsFetching } = backboneApi.useGetCurrentEventsQuery(eventsFilters);
	const { data: notesData } = backboneApi.useGetSystemNotesQuery(id!);
	// not needed at the moment
	// const { data: parameterData } = iotApi.useGetParametersQuery(id as string);
	const { data: maintenanceData } = backboneApi.useGetMaintenancesQuery({});

	useEffect(() => {
		if (deviceData) {
			setModalAddWarrantyPrefilledData(deviceData.owner);
			dispatch(
				setHeaderProps({
					title: deviceData.name,
					handleTitleSubmit: (title: string) => {
						dispatch(patchSystem.initiate({ systemId: deviceData.uuid, payload: { name: title } }));
					},
					background: initialHeaderProps.background,
					showBackButton: true,
					showTabs: true,
					titleEditable: true,
				}),
			);
		}
	}, [deviceData, dispatch, setHeaderProps, initialHeaderProps]);

	// not needed at the moment
	// const parameters = useParameters(parameterData);

	const menuButtons: Array<IFloatingMenuButton> = [
		{
			icon: <AddOutlinedCircle />,
			text: t('Add device'),
			onClick: () => {
				setopenAddMainComponentModal(true);
			},
		},
		{
			icon: <AddOutlinedCircle />,
			text: t('Add component'),
			onClick: () => {
				setopenAddSubcomponentModal(true);
			},
		},
		{
			icon: <AddOutlinedCircle />,
			text: t('Add note'),
			onClick: () => {
				setNoteToEdit(undefined);
				setOpenModalNotes(true);
			},
		},
		{
			icon: <AddOutlinedCircle />,
			text: t('MaintenanceReport'),
			onClick: () => {
				setOpenModalMaintenance(true);
			},
		},
	];

	const handleNoteEdit = (note: Note) => {
		setNoteToEdit(note);
		setOpenModalNotes(true);
	};

	return (
		<div className="o-system-details">
			<SystemHeader {...(headerProps as HeaderProps)} setViewtab={setViewTab} />
			<div className="detail-container">
				{viewTab === 'Detailed View' && (
					<>
						<div className="mid-size-container">
							<div className="row">
								<div className="column column-10">
									{statusBarProps ? (
										<StatusBar
											{...statusBarProps}
											onClickWarranty={() => setOpenModalAddWarranty(true)}
											onPerformMaintenance={() => setOpenModalMaintenance(true)}
											components={components ? components.items : []}
										/>
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="column column-4 column-max column-start">
									<DeviceViewWidget
										deviceData={components?.items?.filter((component: IComponent) => component.is_main_component)}
										maintenanceData={MaintenanceModalData}
									/>
								</div>
								<div className="column column-4 column-max">
									<div className="column column-4 column-max">
										{customerDetailsProps && deviceData && deviceData.owner ? (
											<CustomerDetailsWidgetComponent {...{ ...customerDetailsProps, ...{ customer: deviceData.owner } }} />
										) : null}
									</div>
									<div className="column column-2 assign-emp-widget">
										<AssignEmployeesWidgetComponent />
									</div>
								</div>
								<div className="column column-4 column-max column-end">
									<WidgetCurrentEvents
										systemId={id!}
										detailedView={false}
										headline={t('Current events')}
										showAll={{
											link: '#',
											label: t('Show All'),
										}}
										stacksTimeString={[t('Today'), t('Yesterday'), t('This Week'), t('Last Two Weeks')]}
									/>
								</div>
							</div>
							<div className="title-row">
								<h4>{t('Technical Details')}</h4>
							</div>
							<div className="row">
								<ComponentListWidget
									data={components}
									translations={{}}
									onConfirmClick={() => {
										console.log('delete');
									}}
									isComponentListWidget
								/>
							</div>
							{maintenanceData && maintenanceData.items && maintenanceData.total > 0 && (
								<>
									<div className="title-row">
										<h4>{`${t('Maintenance reports')}`}</h4>
									</div>
									<div className="row">
										<div className="notes-wrapper">
											<WidgetMaintenances maintenances={maintenanceData} />
										</div>
									</div>
								</>
							)}
							<div className="title-row">
								<h4>{`${t('Notes')}`}</h4>
							</div>
							<div className="row">
								{notesData && notesData.length ? (
									<div className="notes-wrapper">
										<NotesWidget notes={notesData} onEdit={handleNoteEdit} />
									</div>
								) : (
									<div
										className="image-placeholder"
										onClick={() => {
											setNoteToEdit(undefined);
											setOpenModalNotes(true);
										}}>
										<IllDragDrop className="add-icon" />
									</div>
								)}
							</div>
						</div>
					</>
				)}
				{viewTab === 'Historie' && (
					<div className="history-view-container">
						<div className="widgets-container">
							<div className="calendar-representation-card">
								<CalendarRepresentation events={(currentEventsData && currentEventsData.items) || []} />
							</div>
							<div className="history-statistics-card">
								<HistoryStatistics events={(currentEventsData && currentEventsData.items) || []} />
							</div>
						</div>
						<div className="history-list-container">
							<HistoryEventsList events={currentEventsData} isLoading={eventsLoading || eventsFetching} />
						</div>
					</div>
				)}
			</div>
			<ModalAddComponent open={openAddMainComponentModal} variation="main" handleClose={() => setopenAddMainComponentModal(false)} />
			<ModalAddComponent open={openAddSubcomponentModal} variation="sub" handleClose={() => setopenAddSubcomponentModal(false)} />
			<ModalNotes open={openModalNotes} handleClose={() => setOpenModalNotes(false)} note={noteToEdit} />

			<ModalAddWarranty
				prefilledData={[modalAddWarrantyPrefilledData, {}]}
				open={openModalAddWarranty}
				setOpen={function (): void {
					throw new Error('Function not implemented.');
				}}
				handleClose={() => setOpenModalAddWarranty(false)}></ModalAddWarranty>
			<MaintenanceModal
				open={openModalMaintenance}
				stepper={MaintenanceModalData.data.stepper}
				devices={MaintenanceModalData.data.devices}
				handleClose={() => setOpenModalMaintenance(false)}></MaintenanceModal>
			<AtomFloatingButtonComponent type="menu" icon={<PlusFilled />} text="" menuButtons={menuButtons} />
		</div>
	);
}

export default memo(SystemDetails);
