import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialogProps } from './ConfirmationDialog.d';
import { ButtonComponent } from 'components/Button/Button';
import { Close } from 'assets/icons';

const useStyles = makeStyles((theme: Theme) => ({
	dialogTitle: {
		fontFamily: 'Saira',
		fontWeight: 600,
		fontSize: '34px',
	},
	closeButton: {
		position: 'absolute',
		color: '#6b718b',
		right: 12,
		top: 8,
	},
	closeIcon: {
		width: 16,
		color: '#000',
	},
}));

function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
	const { t } = useTranslation();
	const classes = useStyles();
	const { open, title, content, onConfirm, onCancel } = props;

	return (
		<Dialog open={open} disablePortal PaperProps={{ style: { width: '100%' } }}>
			<DialogTitle disableTypography className={classes.dialogTitle}>
				{title}
				<IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
					<Close className={classes.closeIcon} />
				</IconButton>
			</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<ButtonComponent onButtonClicked={onCancel} text={t('No')} title={t('No')} type="secondary" />
				<ButtonComponent onButtonClicked={onConfirm} text={t('Yes')} title={t('Yes')} type="primary" />
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationDialog;
