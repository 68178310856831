import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import {
	useReactTable,
	getExpandedRowModel,
	Row,
	getCoreRowModel,
	flexRender,
	ExpandedState,
	SortingState,
	PaginationState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import UIkit from 'uikit';
import { v4 as uuidv4 } from 'uuid';
import { DeviceTableProps } from './react/model/DeviceTable';
import Input from 'components/Input';
import TextLink from 'components/TextLink/TextLink';
import Tooltip from 'components/Tooltip/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import useColumns from './react/hooks/useColumns';
import { ButtonComponent } from 'components/Button/Button';
import { DeviceTableColumnNames } from './DeviceTable.d';
import { IHeatingDevice } from 'types/IHeatingDevice';
import { IComponent } from 'types/Component';
import { ArrowUpDown, AddOutlinedCircle, ArrowLeft, ArrowLeftSmall, ArrowRightSmall } from 'assets/icons';
import Dropdown from 'components/Dropdown/Dropdown';
import AssignEmployeesModal from 'components/WidgetAssignEmployees/modals/AssignEmployeesModal';
import { useSearchParams } from 'react-router-dom';
import { ChipFilters } from 'components';

const DeviceTable = (props: DeviceTableProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		FilterComponent,
		headerLinkUrl,
		buttons,
		onMoreClick,
		onSortBy,
		onRowClick,
		onHeaderLinkClick,
		onMassDelete,
		onSearch,
		data,
		allData,
		title,
		textLinkType,
		withSearch,
		withActions,
		type,
		withHeader,
		withExpander,
		withSelection,
		showColumns,
		columnOrder,
		isComponentListWidget,
		isLoading,
		defaultSortBy,
	} = props;

	const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
	const [tooltipIndex, setTooltipIndex] = useState<number>(-1);
	const [tooltipDepth, setTooltipDepth] = useState<number>(-1);
	const [tooltipPosition, setTooltipPosition] = useState<DOMRect | undefined>(undefined);
	const [expanded, setExpanded] = useState<ExpandedState>({});
	const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
	const [sorting, setSorting] = useState<SortingState>([]);
	const [searchBy, setSearchBy] = useState<string | number>('');
	const [defaultSortInitialized, setDefaultSortInitialized] = useState<boolean>(false);
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
	const [_, setFilters] = useSearchParams();

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	useEffect(() => {
		setFilters(
			(prev) => {
				prev.set('offset', String(pageIndex * pageSize));
				prev.set('limit', String(pageSize));
				return prev;
			},
			{ replace: true },
		);
	}, [pageIndex, pageSize, setFilters]);

	useEffect(() => {
		if (!defaultSortInitialized) {
			const newSorting =
				defaultSortBy?.split(',').map((sort: string) => {
					const desc = sort.startsWith('-');
					const id = sort.replace(/^-/, '');
					return { id, desc };
				}) || [];
			setSorting(newSorting);
		}
		setDefaultSortInitialized(true);
	}, [defaultSortInitialized, defaultSortBy, setSorting]);

	const handleMoreClick = useCallback(
		(e: React.MouseEvent, row: Row<Record<string, unknown>>, position: DOMRect) => {
			e && e.stopPropagation();
			setTooltipPosition(position);
			if (displayTooltip && row.index === tooltipIndex && row.depth === tooltipDepth) {
				setDisplayTooltip(false);
				return;
			}

			setTooltipIndex(row.index);
			setTooltipDepth(row.depth);
			setDisplayTooltip(true);
			onMoreClick(e, row);
		},
		[displayTooltip, tooltipIndex, tooltipDepth, onMoreClick],
	);

	const handleBlur = useCallback((e: MouseEvent) => {
		setDisplayTooltip(false);
	}, []);

	const columns = useColumns({
		data: data?.items || [],
		withExpander,
		withSelection,
		columnNames: DeviceTableColumnNames,
		showColumns,
		handleMoreClick,
	});

	const tableInstance = useReactTable({
		columns,
		data: data?.items ?? [],
		state: {
			expanded,
			columnOrder,
			rowSelection,
			sorting,
			pagination,
		},
		onExpandedChange: setExpanded,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		getSubRows: (row) => row.subRows,
		getRowId(originalRow: IHeatingDevice | IComponent, index: number, parent: Row<Record<string, unknown>>) {
			if (parent) {
				if (!parent.original.components) return originalRow.uuid;
				return parent.original.components?.[index]?.uuid;
			}
			return originalRow.uuid;
		},
		pageCount: Math.ceil((data?.total ?? 0) / pageSize),
		enableMultiRowSelection: true,
		enableSubRowSelection: false,
		enableMultiSort: true,
		enableSortingRemoval: false,
		sortDescFirst: false,
		manualSorting: true,
		manualPagination: true,
	});

	useEffect(() => {
		document.addEventListener('click', (e: MouseEvent) => handleBlur(e));

		return () => {
			document.removeEventListener('click', (e: MouseEvent) => handleBlur(e));
		};
	}, [handleBlur]);

	useEffect(() => {
		const sortQuery = sorting.map((sort: any) => {
			return sort.desc ? `-${sort.id}` : `${sort.id}`;
		});
		onSortBy?.(sortQuery);
	}, [sorting, onSortBy]);

	const stopEventPropagation = (e: React.MouseEvent) => {
		e && e.stopPropagation();
	};

	const onActionButtonClicked = async (e: any, action: string) => {
		e && e.preventDefault();
		switch (action) {
			case 'assign':
				UIkit.modal('#assign-employees-modal').show();
				break;
			case 'delete':
				onMassDelete?.(e, rowSelection);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		function handleResize() {
			setDisplayTooltip(false);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<div className="o-device-mgmt-list">
			{withHeader ? (
				<>
					{type === 'dashboard' ? (
						<div className="headerContainer dashboardHeader">
							<div className="list-header">
								{title && <h4>{title}</h4>}
								{headerLinkUrl && textLinkType ? (
									<TextLink
										type={textLinkType}
										text={isComponentListWidget ? t('Add Component') : (t('All Devices') as string)}
										link={headerLinkUrl}
										onClick={onHeaderLinkClick}></TextLink>
								) : null}
							</div>
							<div className="link-container dashboard-link">
								<div className="filtersContainer">
									<ChipFilters
										data={allData}
										onFilter={(maintenanceDue) => {
											maintenanceDue
												? setFilters(
														(prev) => {
															prev.set('offset', String(0));
															prev.set('limit', String(pageSize));
															prev.set('is_maintenance', String(true));
															return prev;
														},
														{ replace: true },
												  )
												: setFilters(
														(prev) => {
															prev.set('offset', String(0));
															prev.set('limit', String(pageSize));
															prev.delete('is_maintenance');
															return prev;
														},
														{ replace: true },
												  );
											tableInstance.setPageIndex(0);
										}}
									/>
								</div>
								<div className="addComponentContainer">
									{withSearch ? (
										<div style={{ display: 'flex', marginRight: 24 }}>
											{FilterComponent ? FilterComponent : null}
											<Dropdown
												placeholder={t('Search by').toString()}
												items={[
													{ value: 'name', text: t('System name') },
													{ value: 'owner_name', text: t('Owner name') },
													{ value: 'owner_address', text: t('Owner address') },
												]}
												setOption={setSearchBy}
											/>
											<Input
												placeholder={t('Device Search') as string}
												type="search"
												setValue={(value: string) => onSearch?.(searchBy, value)}
												disabled={!searchBy}></Input>
										</div>
									) : null}
									{isComponentListWidget ? <AddOutlinedCircle /> : null}
								</div>
							</div>
						</div>
					) : (
						<div className="headerContainer">
							<div className="list-header">
								{title && <h4>{title}</h4>}
								{withActions ? (
									<div className="dropdown-container">
										<ButtonComponent
											title={t('Assign')}
											text={t('Assign')}
											onButtonClicked={(e) => onActionButtonClicked(e, 'assign')}
											type="primary"
											icon={{
												name: 'add-outlined-circle',
												position: 'left',
											}}
											isDisabled={Object.keys(rowSelection).length !== 1}></ButtonComponent>
										<ButtonComponent
											title={t('Delete')}
											text={t('Delete')}
											onButtonClicked={(e) => onActionButtonClicked(e, 'delete')}
											type="primary"
											icon={{
												name: 'trash-outlined',
												position: 'left',
											}}
											isDisabled={!Object.keys(rowSelection).length}></ButtonComponent>
										{/* TODO: Uncomment the line below for list/grid switch in the future */}
										{/* <AtomToggleReact id="switch" type="medium" items={[translations.list, translations.grid]} value={toggled} setToggle={handleButtonSwitch}></AtomToggleReact> */}
									</div>
								) : null}
							</div>
							<div className="link-container">
								<div className="addComponentContainer">
									{withSearch ? (
										<div style={{ display: 'flex', marginRight: 24 }}>
											{FilterComponent ? FilterComponent : null}
											<Dropdown
												placeholder={t('Search by').toString()}
												items={[
													{ value: 'name', text: t('System name') },
													{ value: 'owner_name', text: t('Owner name') },
													{ value: 'owner_address', text: t('Owner address') },
												]}
												setOption={setSearchBy}
											/>
											<Input
												placeholder={t('Device Search') as string}
												type="search"
												setValue={(value: string) => onSearch?.(searchBy, value)}
												disabled={!searchBy}></Input>
										</div>
									) : null}
									{isComponentListWidget ? <AddOutlinedCircle /> : null}
									{headerLinkUrl && textLinkType ? (
										<TextLink
											type={textLinkType}
											text={isComponentListWidget ? t('Add Component') : (t('All Devices') as string)}
											link={headerLinkUrl}
											onClick={onHeaderLinkClick}></TextLink>
									) : null}
								</div>
							</div>
						</div>
					)}
				</>
			) : null}
			<div className="table-wrapper">
				<table>
					<thead>
						{tableInstance.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id} colSpan={header.colSpan}>
										<div className="header-container" onClick={header.column.getToggleSortingHandler()}>
											{flexRender(header.column.columnDef.header, header.getContext())}
											{header.id === 'more' ||
											(withSelection && header.id === 'selection') ||
											(withExpander && header.id === 'expander') ? null : (
												<ArrowUpDown className="sort-icon" />
											)}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{!isLoading ? (
							<>
								{data?.items?.length ? (
									<>
										{tableInstance.getRowModel().rows.map((row) => {
											return (
												<tr className={row.depth ? 'expanded' : undefined} key={uuidv4()} onClick={(e) => onRowClick(e, row)}>
													{row.getVisibleCells().map((cell) => {
														return (
															<>
																<td
																	key={uuidv4()}
																	onClick={cell.column.id === 'expander' ? stopEventPropagation : undefined}
																	className={cell.column.id === 'more' ? 'more-cell' : undefined}>
																	{cell.column.id === 'more' ? (
																		tooltipIndex === row.index &&
																		tooltipDepth === row.depth &&
																		displayTooltip &&
																		tooltipPosition ? (
																			<>
																				<Tooltip
																					style={{
																						zIndex: 9999,
																						top: tooltipPosition.top + window.scrollY + 30,
																						left: tooltipPosition.left + window.scrollX - 30,
																					}}
																					arrowPosition="top"
																					type="menu"
																					text=""
																					buttons={buttons}></Tooltip>
																				{flexRender(cell.column.columnDef.cell, cell.getContext())}
																			</>
																		) : (
																			flexRender(cell.column.columnDef.cell, cell.getContext())
																		)
																	) : (
																		flexRender(cell.column.columnDef.cell, cell.getContext())
																	)}
																</td>
															</>
														);
													})}
												</tr>
											);
										})}
									</>
								) : (
									<tr className="componentsPlaceholder">
										<td colSpan={99}>There are no components</td>
									</tr>
								)}
							</>
						) : (
							<>
								{Array.from({ length: 15 }).map((_, index) => (
									<tr>
										<td colSpan={20}>
											<Skeleton height={'3em'} />
										</td>
									</tr>
								))}
							</>
						)}
					</tbody>
				</table>
			</div>
			<div className="pagination-container">
				<button
					className="pagination-button"
					onClick={() => {
						tableInstance.setPageIndex(0);
					}}
					disabled={!tableInstance.getCanPreviousPage()}>
					<ArrowLeft className="pagination-icon" />
				</button>
				<button className="pagination-button" onClick={() => tableInstance.previousPage()} disabled={!tableInstance.getCanPreviousPage()}>
					<ArrowLeftSmall className="pagination-icon-small" />
				</button>
				<span className="page-selector-container">
					<span className="">
						Page
						<input
							type="number"
							value={tableInstance.getState().pagination.pageIndex + 1}
							onChange={(e) => {
								tableInstance.setPageSize(e.target.value ? Number(e.target.value) - 1 : 0);
							}}
							className="pagination-number"
							max={tableInstance.getPageCount()}
							min={1}
						/>
					</span>
					of {tableInstance.getPageCount()}
				</span>
				<button className="pagination-button" onClick={() => tableInstance.nextPage()} disabled={!tableInstance.getCanNextPage()}>
					<ArrowRightSmall className="pagination-icon-small" />
				</button>
				<button
					className="pagination-button"
					onClick={() => tableInstance.setPageIndex(tableInstance.getPageCount() - 1)}
					disabled={!tableInstance.getCanNextPage()}>
					<ArrowLeft className="pagination-icon-revert" />
				</button>
				<select
					value={tableInstance.getState().pagination.pageSize}
					onChange={(e) => {
						tableInstance.setPageSize(Number(e.target.value));
					}}
					className="pagination-selector">
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
			<div className="m-assign-employees-widget assign-employees-hidden">
				<AssignEmployeesModal modalId="assign-employees-modal" deviceIDs={Object.keys(rowSelection)}></AssignEmployeesModal>
			</div>
		</div>
	);
};

export default DeviceTable;
