import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from '../../m-modal-form/m-modal-form.component';
import { AddEmployeeModalProps, CheckboxGroupProps, CheckboxOption } from '../WidgetAssignEmployees.d';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown/Dropdown';
import Checkbox from 'components/Checkbox/Checkbox';
import UIkit from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import { addEmpModalDataSelector } from 'store/modules/heatingdevice/selectors';
import { AppDispatch } from 'store';

function CheckboxGroup(props: CheckboxGroupProps): JSX.Element {
	const { label, options } = props;
	const [localOptions, setLocalOptions] = useState(options);

	const handleCheckboxChange = (e: any) => {
		const { value } = e.target;
		const newOptions = localOptions.map((option: CheckboxOption) => {
			if (option.value === value) {
				return { ...option, checked: !option.checked };
			}
			return option;
		});
		setLocalOptions(newOptions);
	};

	return (
		<>
			<div className="uk-width-1-1">
				<p className="checkbox-input-label">{label}</p>
				{localOptions.map((option: CheckboxOption, index: number) => {
					return (
						<Checkbox
							key={index}
							disabled={false}
							label={option.label}
							name={option.value}
							value={option.value}
							checked={option.checked}
							onChange={handleCheckboxChange}
						/>
					);
				})}
			</div>
		</>
	);
}

function AddEmployeeModal(props: AddEmployeeModalProps): JSX.Element {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { modalId, parentModalId, onAddEmployee } = props;
	const formRef = useRef<HTMLFormElement>(null);

	const modalData = useSelector(addEmpModalDataSelector);
	const [resetValue, setResetValue] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// await dispatch(getAddModalData());
			} catch (err) {
				// TODO RG: Handle error when alert molecule is ready
			}
		};
		fetchData();
	}, [dispatch, resetValue]);

	const handleFormSubmit = async (e: SubmitEvent) => {
		e && e.preventDefault();
		const formData = new FormData(e.target as HTMLFormElement);

		try {
			// await dispatch(addEmployee(formData));
		} catch (err) {
			// TODO RG: Handle error when alert molecule is ready
			return;
		}

		formRef && formRef.current && formRef.current.reset();
		setResetValue((current) => (current === '' ? undefined : ''));
		UIkit.modal(`#${modalId}`).hide();
		onAddEmployee();
		UIkit.modal(`#${parentModalId}`).show();
	};

	return (
		<ModalForm id={modalId} headline="" formUrl="" content="" handleFormSubmit={handleFormSubmit}>
			<h4 className="uk-text-center modal-heading">{t('Add employee')}</h4>
			<form ref={formRef}>
				<div className="modal-body">
					<div data-uk-grid className="scrollable-wrapper add-modal">
						<div className="uk-width-1-2">
							<Input
								type="label"
								name="firstName"
								value={resetValue}
								label={t('First name') as string}
								placeholder={t('First name') as string}
							/>
						</div>
						<div className="uk-width-1-2">
							<Input
								type="label"
								name="lastName"
								value={resetValue}
								label={t('Last name') as string}
								placeholder={t('Last name') as string}
							/>
						</div>
						<div className="uk-width-1-2">
							<Input type="label" name="phone" value={resetValue} label={t('Phone') as string} placeholder={t('Phone') as string} />
						</div>
						<div className="uk-width-1-2">
							<Input type="label" name="mobile" value={resetValue} label={t('Mobile') as string} placeholder={t('Mobile') as string} />
						</div>
						<div className="uk-width-1-1">
							<Input
								type="label"
								name="email"
								value={resetValue}
								label={t('E-Mail') as string}
								placeholder={t('E-Mail') as string}
								width={506}
							/>
						</div>
						<div className="uk-width-1-2">
							<Dropdown
								name="function"
								defaultValue={resetValue}
								label={t('Function') as string}
								placeholder={t('Function') as string}
								items={modalData.functions || []}
							/>
						</div>
						<div className="uk-width-1-2">
							<Dropdown
								name="role"
								defaultValue={resetValue}
								label={t('Role') as string}
								placeholder={t('Role') as string}
								items={modalData.roles || []}
							/>
						</div>
						{modalData.checkboxGroups &&
							modalData.checkboxGroups.length &&
							modalData.checkboxGroups.map((checkboxGroup: CheckboxGroupProps, index: number) => {
								return <CheckboxGroup key={index} label={checkboxGroup.label} options={checkboxGroup.options} />;
							})}
					</div>
				</div>
				<div className="uk-modal-footer uk-text-center">
					<input
						type="submit"
						className="submit-button uk-button uk-flex-inline uk-text-center"
						value={t('Save assignment') as string}></input>
				</div>
			</form>
		</ModalForm>
	);
}

export default memo(AddEmployeeModal);
