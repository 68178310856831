import React from 'react';
import backboneApi from 'store/modules/backbone';

const DeviceTitle = (props: { sku: string }) => {
	const { data: extraDeviceData } = backboneApi.useGetComponentDetailsQuery(props.sku || '');

	return (
		<>
			<span className="title">{extraDeviceData?.name || '-'}</span>
		</>
	);
};

export default DeviceTitle;
