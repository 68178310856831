import { IHeatingDeviceState } from 'types/IHeatingDeviceState';

const initialState: IHeatingDeviceState = {
	apiUrls: {
		getCurrentEvents: { endpoint: '', method: '' },
		getSystems: { endpoint: '', method: '' },
		getSystem: { endpoint: '', method: '' },
		getSystemComponents: { endpoint: '', method: '' },
		getSystemData: { endpoint: '', method: '' },
		deleteSystem: { endpoint: '', method: '' },
		getToken: { endpoint: '', method: '' },
		getAddModalData: { endpoint: '', method: '' },
		getDeviceStatistics: { endpoint: '', method: '' },
		getDeviceAssignments: { endpoint: '', method: '' },
		assignEmployees: { endpoint: '', method: '' },
		addEmployee: { endpoint: '', method: '' },
		deleteEmployee: { endpoint: '', method: '' },
	},
	devicesData: [],
	systemDevices: [],
	totalItems: 0,
	currentEvents: [],
	deviceData: null,
	mainComponents: [],
	otherComponents: [],
	filters: {},
	addEmpModalData: { functions: [], roles: [], checkboxGroups: [] },
	statistics: {
		total: 0,
		unknown: 0,
		online: 0,
		failure: 0,
		offline: 0,
		maintenance: 0,
	},
	eventsFilters: null,
	token: '',
	stepperModalData: {},
	header: {},
	initialHeaderData: {},
	summiteerData: {
		loading: true
	},
};

export default initialState;
