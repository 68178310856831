import {
	BlobClient,
	BlobDeleteResponse,
	BlobItem,
	BlobServiceClient,
	BlobUploadCommonResponse,
	BlockBlobClient,
	ContainerClient,
} from '@azure/storage-blob';

class BlobStorageService implements IBlobStorageService {
	private blobServiceClient: BlobServiceClient;
	private containerClient: ContainerClient;
	private blobClient: BlobClient;
	private blockBlobClient: BlockBlobClient;

	constructor(connectionString: string, containerName: string) {
		try {
			this.containerClient = new ContainerClient(connectionString, containerName);
		} catch (e) {
			throw new Error('Es konnte keine Verbindung zu Azure Blob Storage hergestellt werden');
		}
	}

	async listBlobs(): Promise<BlobItem[]> {
		const blobs: BlobItem[] = [];
		for await (const blob of this.containerClient.listBlobsFlat()) {
			blobs.push(blob);
		}
		return blobs;
	}

	/**
	 * Example usage (browser):
	 * ```js
	 * // Download blob from Azure Storage
	 * BlobStorage.downloadBlob(blobName).then((res) => {
	 *     var link = document.createElement("a"); // Or maybe get it from the current document
	 *     link.href = URL.createObjectURL(res);
	 *     link.download = blobName;
	 *     link.innerHTML = "Click here to download the file";
	 *     document.body.appendChild(link);
	 * }).catch((err) => {
	 *     console.log("Err: ", err);
	 * })
	 * ```
	 */
	async downloadBlob(noteId: string, blobName: string): Promise<Blob | undefined> {
		this.blobClient = this.containerClient.getBlobClient(`notes/${noteId}/attachments/${blobName}`);
		const downloadBlockBlobResponse = await this.blobClient.download();
		return downloadBlockBlobResponse.blobBody;
	}

	/**
	 * Example usage (browser):
	 *
	 * ```js
	 * // Upload blob to Azure Storage
	 * const BlobStorage = new BlobStorageService(account, sasToken, containerName);
	 * const content = "Hello World!";
	 * const blobName = "newblob" + new Date().getTime();
	 * BlobStorage.uploadBlob(blobName, content).then((res) => {
	 *     console.log("Upload: ", res);
	 * }).catch((err) => {
	 *     console.log("Upload err: ", err);
	 * });
	 * ```
	 */
	async uploadBlob(noteId: string, blobName: string, blob: Blob): Promise<BlobUploadCommonResponse> {
		this.blockBlobClient = this.containerClient.getBlockBlobClient(`notes/${noteId}/attachments/${blobName}`);
		return this.blockBlobClient.uploadData(blob);
	}

	async deleteBlob(noteId: string, blobName: string): Promise<BlobDeleteResponse> {
		this.blockBlobClient = this.containerClient.getBlockBlobClient(`notes/${noteId}/attachments/${blobName}`);
		return this.blockBlobClient.deleteIfExists();
	}
}

export default BlobStorageService;

export interface IBlobStorageService {
	listBlobs: () => Promise<BlobItem[]>;
	downloadBlob: (noteId: string, blobName: string) => Promise<Blob | undefined>;
	uploadBlob: (noteId: string, blobName: string, blob: Blob) => Promise<BlobUploadCommonResponse>;
	deleteBlob: (noteId: string, blobName: string) => Promise<BlobDeleteResponse>;
}
