import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { WidgetCustomerDataProps } from './WidgetCustomerData.d';
import CustomerData from 'components/CustomerData';
import { Close } from 'assets/icons';
import { AddCustomerFormData } from 'components/CustomerData/CustomerData.d';
import { parse, formatISO } from 'date-fns';
import backboneApi, { patchSystem } from 'store/modules/backbone';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { DeviceOwner } from 'types/DeviceOwner';
import DeleteDialog from 'components/ConfirmationDialog';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 16,
		},
		closeButton: {
			position: 'absolute',
			color: '#6b718b',
			right: 16,
			top: 24,
		},
	});

export interface ModalFormResponseModel {
	success: boolean;
	content: string;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close style={{ width: 18, color: '#212d40' }} />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const SaveButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const ResetButton = withStyles((theme: Theme) => ({
	root: {
		color: '#6b718b',
		backgroundColor: 'transparent',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		backgroundColor: '#f8f8fa',
	},
}))(MuiDialogActions);

function WidgetCustomerData(props: WidgetCustomerDataProps): JSX.Element {
	const [patchSystem, error] = backboneApi.usePatchSystemMutation();
	const { customer, open, deviceAddress } = props;
	const [customerData, setCustomerData] = useState(customer);
	const [deviceLocationAddress, setdeviceLocationAddress] = useState<any>(deviceAddress);
	const [validate, setValidate] = useState(false);
	const formRef = useRef<HTMLFormElement | null>(null);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [nextPressed, setNextPressed] = useState(false);

	const onConfirm = (e: React.MouseEvent) => {
		e && e.preventDefault();
		patchSystem({ systemId: id!, payload: { owner: customerData } })
			.unwrap()
			.then((res) => {
				if (res.success) {
					setOpenConfirmModal(false);
				}
			})
			.catch((err) => {
				console.error('Failed to fetch:', err.message);
			});
	};

	const onCancel = (e: React.MouseEvent) => {
		e && e.preventDefault();
		setOpenConfirmModal(false);
	};

	const handleValidation = (isValid: boolean) => {
		setValidate(isValid);
	};

	useEffect(() => {
		if (validate && nextPressed) {
			handleClose();
			setOpenConfirmModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validate, nextPressed]);

	const routeParams = useParams();
	const { id } = routeParams;

	const { t } = useTranslation();

	const handleClose = () => {
		props.handleClose?.();
	};

	const parseNestObject = (obj) => {
		const result = {};
		for (const key in obj) {
			const keys = key.split('.');
			let current = result;
			for (let i = 0; i < keys.length; i++) {
				if (i === keys.length - 1) {
					current[keys[i]] = obj[key];
				} else {
					current[keys[i]] = current[keys[i]] || {};
				}
				current = current[keys[i]];
			}
		}
		return result;
	};

	const handleSubmit = async (e) => {
		e && e.preventDefault();
		if (!(formRef && formRef.current)) {
			return;
		}
		const formData = new FormData(formRef.current);
		const formDataArray = Array.from(formData.entries());
		const uniqueKeys = [...new Set(formDataArray.map(([key]) => key))];
		const dataObject = formDataArray.reduce((acc: Array<AddCustomerFormData>, [key, value], index) => {
			const objectIndex = Math.floor(index / uniqueKeys.length);
			if (!acc[objectIndex]) {
				acc[objectIndex] = {} as AddCustomerFormData;
			}
			acc[objectIndex][key] = value;
			if (key.toLowerCase().indexOf('date') !== -1) {
				acc[objectIndex][key] = formatISO(parse(acc[objectIndex][key], 'dd. MMM yyyy', new Date())).split('T')[0];
			}
			return acc;
		}, [])[0];
		const finalData = parseNestObject(dataObject);
		setCustomerData(finalData as DeviceOwner);
		setNextPressed(true);
	};

	const handleReset = () => {
		setCustomerData(customer);
		setdeviceLocationAddress(deviceAddress);
	};

	const flattenObject = (obj, parentKey = '', result = {}) => {
		for (const key in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(key)) {
				const propName = parentKey ? `${parentKey}.${key}` : key;
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					flattenObject(obj[key], propName, result);
				} else {
					result[propName] = obj[key];
				}
			}
		}
		return result;
	};

	const parsePrefilledData = (arr: Array<any>) => {
		const parsedArr = arr.map((obj) => {
			return flattenObject(obj);
		});
		return parsedArr;
	};

	return (
		<div className="m-system-management-widget-customer-data">
			<Dialog
				onClose={handleClose}
				open={open}
				aria-labelledby="customized-dialog-title"
				PaperProps={{
					style: { borderRadius: 20, minWidth: '80%', maxHeight: '80vh' },
				}}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose} />
				<Typography style={{ marginTop: 20, paddingLeft: 48, fontSize: 32, fontWeight: 600 }}>{t('Edit Customer Data')}</Typography>
				<form ref={formRef}>
					<div style={{ paddingLeft: 48, paddingRight: 48 }}>
						<CustomerData
							customer={customerData}
							data={parsePrefilledData([customerData])}
							handleValidation={handleValidation}
							deviceAddress={deviceLocationAddress}
							nextPressed
						/>
					</div>
					<DialogActions>
						<ResetButton onClick={handleReset} variant="contained" color="primary">
							{t('Reset')}
						</ResetButton>
						<SaveButton type="submit" onClick={handleSubmit} variant="contained" color="primary">
							{t('Save')}
						</SaveButton>
					</DialogActions>
				</form>
			</Dialog>
			<DeleteDialog
				open={openConfirmModal}
				title={t('Change customer data')}
				content={t('Are you sure you want to change the data?')}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</div>
	);
}
export default WidgetCustomerData;
