import { DeviceOwner } from 'types/DeviceOwner';
import { LocationAddress } from 'types/LocationAddress';
export interface CustomerDataProps {
	handleChange?: () => void;
	customer?: DeviceOwner;
	data?: any;
	nextPressed?: boolean;
	deviceAddress?: LocationAddress;
	nextStepAction?: (data: FormData) => void;
	handleValidation?: (isValid: boolean) => void;
}

export interface AddCustomerFormData {
	salutation?: string;
	firstname: string;
	lastname: string;
	phone: string;
	email: string;
	alternative_location?: boolean;
	google_address?: string;
	google_location?: string;
	'address.street': string;
	'address.house_number': string;
	'address.zip_code': string;
	'address.city': string;
	'address.country': string;
	'location.street'?: string;
	'location.house_number'?: string;
	'location.zip_code'?: string;
	'location.city'?: string;
	'location.country'?: string;
}

export const CustomerData: CustomerDataProps = {
	nextStepAction: (data) => console.log(data),
	customer: {
		salutation: 'Herr',
		firstname: 'Manfred',
		lastname: 'Mustermann',
		address: {
			street: 'Musterstraße',
			house_number: '1',
			city: 'München',
			zip_code: '80335',
		},
		company: 'Musterfirma GmbH',
		phone: '+49 1234 56789123',
		email: 'manfred.mustermann@beispiel.com',
	},
	deviceAddress: {
		street: 'Axenstraße',
		house_number: '11',
		city: 'Berlin',
		zip_code: '13089',
	},
};
export const CustomerDataTranslations = {
	'Customer Data': 'Kundendaten',
	'First Name': 'Vorname',
	'Last Name': 'Nachname',
	Phone: 'Telefon',
	Email: 'E-Mail',
	Street: 'Straße',
	Number: 'Hausnummer',
	ZIP: 'PLZ',
	ORT: 'Ort',
	'Device address is different than customer address': 'Anlagenstandort weicht vom Wohnort ab',
	Residence: 'Wohnort',
	'Customer Data System Location': 'Anlagenstandort',
};
