import React, { useState } from 'react';
import { FormGroupProps } from 'components/types';

function FormGroup(props: FormGroupProps): JSX.Element {
	const { formGroupTitle } = props;

	const [deviceName, setDeviceName] = useState<string>('');

	return (
		<fieldset className="m-form-group">
			<legend className="legend-title">{deviceName.length ? deviceName : formGroupTitle}</legend>
			<div className="formGroupContainer">
				<div className="inputContainer">
					<div className="row">{props.children}</div>
				</div>
			</div>
		</fieldset>
	);
}

export default FormGroup;
