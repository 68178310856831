import React, { memo, useEffect, useState } from 'react';
import { Card, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { StatusBarProps } from '../StatusBar.d';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { isPast, parseISO, differenceInDays } from 'date-fns';
import { MaintenanceClipboard } from 'assets/icons';
import { WrenchFilled } from 'assets/icons';
import { IComponent } from 'types/Component';

const StatusBar = (props: StatusBarProps) => {
	const classes = StatusesQuickFilters();
	const { components } = props;
	const [loading, setLoading] = useState<boolean>(true);
	const [nextMaintenanceComponent, setNextMaintenanceComponent] = useState<IComponent | null>(null);
	const [daysSinceDue, setDaysSinceDue] = useState<number | null>(null);
	const [showStatusWidget, setShowStatusWidget] = useState<boolean>(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (!components || components.length === 0) {
			setNextMaintenanceComponent(null);
			setDaysSinceDue(null);
			return;
		}

		const mainComponentsWithPastMaintenances = components.filter(
			(component) => component.is_main_component && component.next_maintenance_date && isPast(new Date(component.next_maintenance_date)),
		);

		if (!mainComponentsWithPastMaintenances.length) {
			setNextMaintenanceComponent(null);
			setDaysSinceDue(null);
			return;
		}

		const sortedMainComponents = mainComponentsWithPastMaintenances.sort(
			(a, b) => new Date(a.next_maintenance_date).getTime() - new Date(b.next_maintenance_date).getTime(),
		);

		setNextMaintenanceComponent(sortedMainComponents[0]);
		if (nextMaintenanceComponent !== null) {
			setShowStatusWidget(true);
			return;
		}

		const dueDate = parseISO(sortedMainComponents[0].next_maintenance_date);
		const currentDate = new Date();

		const differenceInDaysValue = differenceInDays(currentDate, dueDate);
		setDaysSinceDue(differenceInDaysValue);
	}, [components, nextMaintenanceComponent, showStatusWidget]);

	const subheadlineDays = daysSinceDue !== null ? daysSinceDue : 0;
	const statusDescription =
		subheadlineDays === 0
			? `${t('Maintenance due today')}.`
			: t('The maintenance of this device is due since') + ' ' + subheadlineDays + ' ' + t('days ago') + '.';

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 650);
	}, []);

	return (
		<div className={classes.root}>
			{showStatusWidget && (
				<Card className={classes.card}>
					<Grid container className={classes.gridWrapper}>
						<Grid item className={classes.leftBorder}></Grid>
						<Grid item className={classes.rightContent}>
							<Grid container className={classes.statusBarContent}>
								<Grid item xs={7}>
									<Grid container className={classes.leftColumnRow}>
										<Grid item xs={12} md={12}>
											{loading ? (
												<div className={classes.skeletonsContainer}>
													<Skeleton height={60} />
												</div>
											) : (
												<div className={classes.iconPlaceholder}>
													<WrenchFilled className={classes.statusIcon} />
													<span className={classes.statusTitle}>{t('Maintenance due')}</span>
												</div>
											)}
										</Grid>
									</Grid>
									<Grid container className={classes.leftColumnRow}>
										<Grid item xs={12}>
											{loading ? (
												<div className={classes.skeletonsContainer}>
													<Skeleton />
													<Skeleton />
													<Skeleton />
												</div>
											) : (
												<span className={classes.statusDescription}>{statusDescription}</span>
											)}
										</Grid>
									</Grid>
									<Grid container className={classes.leftColumnRow}>
										<Grid item xs={12}>
											{/* Will probably be readded later */}
											{/* <Button color="primary" variant="contained">Störung beheben</Button> */}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={5}>
									<Grid container>
										<Grid item xs={12}>
											{loading ? (
												<div className={classes.timeAndDate}>
													<Skeleton />
												</div>
											) : (
												<div className={classes.timeAndDate}>12/12/2022 12:12 PM</div>
											)}
										</Grid>
									</Grid>
									<Grid container className={classes.actionButtonsRow}>
										<Grid item xs={6} className={classes.actionButtonContainer}>
											<div className={classes.actionButton}>
												<MaintenanceClipboard className={classes.actionButtonIcon} />
												<div className={classes.actionButtonText} onClick={props.onPerformMaintenance}>
													{t('Perform maintenance')}
												</div>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			)}
		</div>
	);
};

export default memo(StatusBar);

const StatusesQuickFilters = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: 'auto',
			maxWidth: 880,
		},
		skeletonsContainer: {
			padding: '0 20px',
			width: '100%',
		},
		card: {
			height: '208px',
			width: '808px',
			borderRadius: '12px',
			justifyItems: 'center',
			alignItems: 'center',
		},
		gridWrapper: {
			height: '100%',
			width: '100%',
		},
		leftBorder: {
			display: 'block',
			width: '12px',
			height: '100%',
			backgroundColor: '#00c5b9',
		},
		rightContent: {
			height: '100%',
			width: 'calc(100% - 12px)',
		},
		statusBarContent: {
			height: '100%',
			width: '100%',
			padding: '16px 16px',
		},
		white: {
			height: '100%',
			width: '100%',
		},
		leftColumnRow: {
			padding: '8px 0',
		},
		statusBarContentTitle: {
			height: '100%',
			width: '100%',
			backgroundColor: 'red',
			borderRadius: '12px',
			padding: '16px 48px',
		},
		iconPlaceholder: {
			display: 'flex',
		},
		statusIcon: {
			color: '#00c5b9',
			height: 44,
			width: 48,
			padding: 12,
			backgroundColor: '#92f0ea',
			borderRadius: 12,
		},
		statusTitle: {
			paddingTop: 4,
			height: 30,
			marginLeft: 16,
			color: '#212d40',
			fontFamily: 'Inter',
			fontSize: 24,
			fontWeight: 600,
		},
		statusDescription: {
			color: '#212d40',
			fontFamily: 'Inter',
			fontSize: 16,
			fontWeight: 500,
		},
		timeAndDate: {
			width: '100%',
			height: '50px',
			textAlign: 'right',
			fontFamily: 'Inter',
			fontSize: 14,
			color: '#212d40',
			fontWeight: 'normal',
			paddingRight: '20px',
		},
		actionButtonsRow: {
			height: '88px',
			justifyContent: 'right',
		},
		actionButtonContainer: {
			width: '100%',
			height: '100%',
			padding: '0 16px 0 0',
		},
		actionButtonIcon: {
			height: 32,
			width: 32,
			color: '#212d40',
			marginBottom: 10,
		},
		actionButtonText: {
			color: '#212d40',
			fontSize: 16,
			fontWeight: 500,
			fontFamily: 'Inter',
		},
		actionButton: {
			width: 136,
			height: 120,
			padding: '16px 16px',
			borderRadius: '12px',
			backgroundColor: '#8eacc6',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
	}),
);
