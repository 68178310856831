import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { WidgetComponentListProps } from './WidgetComponentList.d';
import DeviceMgmtList from 'components/DeviceTable';
import { useTranslation } from 'react-i18next';
import DeleteDialog from 'components/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import backboneApi from 'store/modules/backbone';
import { format } from 'date-fns';
import { List } from 'types/List';
import { IHeatingDevice } from 'types/IHeatingDevice';
import { IComponent } from 'types/Component';
import ModalAddComponent from 'components/ModalAddComponent';

function WidgetComponentList(props: WidgetComponentListProps): JSX.Element {
	const { data, onConfirmClick, isComponentListWidget } = props;
	const textLinkIcon = require('assets/icons/add-outlined-circle.svg') as any;
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const routeParams = useParams();
	const { id } = routeParams;
	if (!id) {
		navigate('/404');
	}

	const [openModalAddComponent, setModalAddComponent] = useState<boolean>(false);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [selectedRowId, setSelectedRowId] = useState<string>('');
	const [editingRow, setEditingRow] = useState<{ id: string; serial: unknown; name: unknown; installationDate: unknown } | null>(null);
	const [editMode, setEditMode] = useState<boolean>(false);

	function formatDate(dateStr) {
		const date = Date.parse(dateStr);
		if (isNaN(date) == false) {
			return format(date, 'dd.MM.yyyy');
		}
		return '';
	}

	function transformDatesInArray(data: List<IHeatingDevice[] | IComponent[]>) {
		const items = data?.items?.length
			? data.items.map((obj) => ({
					...obj,
					manufacture_date: formatDate(obj.manufacture_date),
					installation_date: formatDate(obj.installation_date),
					commissioning_date: formatDate(obj.commissioning_date),
					warranty_end_date: formatDate(obj.warranty_end_date),
			  }))
			: [];
		return {
			offset: data?.offset || 0,
			limit: data?.limit || 0,
			total: data?.total || 0,
			items,
		};
	}

	const onRowClick = (ev: React.MouseEvent<Element, MouseEvent>, row: Row<Record<string, unknown>>) => {
		// add code here when need arises
	};

	const onAddClick = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		setEditMode(false);
		setModalAddComponent(true);
	};

	const onMoreClick = (ev: React.MouseEvent, row: Row<Record<string, unknown>>) => {
		ev && ev.stopPropagation();
		setSelectedRowId(row.id);
		setEditingRow({
			id: row.id,
			serial: row.original.serial,
			name: row.original.name,
			installationDate: row.original.installation_date,
		});
	};

	const onConfirmDelete = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		onDeleteComponent(selectedRowId);
		setOpenDeleteModal(false);
	};

	const onDeleteComponent = async (uuid: string) => {
		try {
			await dispatch(backboneApi.endpoints.deleteSystemComponent.initiate({ systemId: id!, componentId: uuid }));
			await dispatch(backboneApi.endpoints.getSystemComponents.initiate(id!, {}));
		} catch (err) {
			//TODO RG: handle error when alert molecule is ready
		}
	};

	const onCancelDelete = (ev: React.MouseEvent) => {
		ev && ev.preventDefault();
		setOpenDeleteModal(false);
	};

	return (
		<div className="o-component-list-widget">
			<DeviceMgmtList
				data={transformDatesInArray(data)}
				textLinkType="underlined_medium"
				textLinkIcon={textLinkIcon}
				title={t('Components').toString()}
				buttons={[
					{
						name: 'Löschen',
						onClick: (e: React.MouseEvent<HTMLDivElement>): void => {
							e && e.stopPropagation();
							setOpenDeleteModal(true);
						},
					},
					{
						name: 'Bearbeiten',
						onClick: (ev: React.MouseEvent<Element, MouseEvent>) => {
							ev && ev.stopPropagation();
							setEditMode(true);
							setModalAddComponent(true);
						},
					},
				]}
				showColumns={{
					status: true,
					serial: true,
					type_description: true,
					installation_date: true,
					software_version: true,
				}}
				columnOrder={['status', 'serial', 'type_description', 'installation_date', 'software_version']}
				headerLinkUrl="#"
				onMoreClick={onMoreClick}
				onRowClick={onRowClick}
				onHeaderLinkClick={onAddClick}
				isComponentListWidget={isComponentListWidget}
				withHeader
			/>
			<ModalAddComponent open={openModalAddComponent} variation="sub" handleClose={() => setModalAddComponent(false)} />
			<DeleteDialog
				open={openDeleteModal}
				title={t('Delete device')}
				content={t('Are you sure?')}
				onConfirm={onConfirmDelete}
				onCancel={onCancelDelete}
			/>
		</div>
	);
}

export default WidgetComponentList;
