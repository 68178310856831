import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'store';
import pimcoreApi from 'store/modules/pimcore';
import { heatingDevicePortalActions } from '../heatingdevice/reducer';
import apiBaseUrls from 'constants/apiBaseUrls';
import { Customers } from 'types/Customers';

const baseQueryFactory = async (api: BaseQueryApi, baseUrl: string) => {
	let apiToken = (api.getState() as RootState).heatingdevice.token;

	if (!apiToken) {
		const { data: tokenResponse } = await api.dispatch(pimcoreApi.endpoints.getToken.initiate('')).unwrap();
		if (tokenResponse && tokenResponse.token) {
			api.dispatch(heatingDevicePortalActions.setToken(tokenResponse.token));
			apiToken = tokenResponse.token;
		}
	}

	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${apiToken}`);
			return headers;
		},
	});
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV|| 'development'].customers);

	let result = await baseQuery(args, api, extraOptions);
	if (result.error && (result.error.status === 'FETCH_ERROR' || result.error.status === 'PARSING_ERROR')) {
		baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].customers);
		result = await baseQuery(args, api, extraOptions);
	}
	return result;
};

// Define a service using a base URL and expected endpoints
export const customersApi = createApi({
	reducerPath: 'customersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['customers'],
	endpoints: (builder) => ({
		getCustomerInfo: builder.query<Customers, any>({
			query: () => {
				return {
					url: '/my',
					method: 'GET',
				};
			},
		}),
	}),
});

export const { getCustomerInfo } = customersApi.endpoints;

export default customersApi;
