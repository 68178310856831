import { Chip } from '@material-ui/core';
import { PenCircleFilled } from 'assets/icons';
import React from 'react';
import { WidgetNotesProps, Note } from './WidgetNotes.d';

function WidgetNotes(props: WidgetNotesProps): JSX.Element {
	const { notes, onEdit } = props;

	const truncateDescription = (description: string): string => {
		const maxLength = 100;
		if (description.length <= maxLength) {
			return description;
		}
		const truncatedText = description.slice(0, maxLength);
		const lastSpace = truncatedText.lastIndexOf(' ');

		if (lastSpace === -1) {
			return truncatedText + '...';
		}

		return truncatedText.slice(0, lastSpace) + '...';
	};

	return (
		<div className="m-notes-widget" data-uk-slider="finite: true">
			<ul className="uk-slider-items uk-grid">
				{notes &&
					notes.map((note: Note, index: number) => (
						<li key={index}>
							<div className="wrapper">
								<div className="top">
									<span className="date-creator">{`${new Date(note.creation_date).toLocaleDateString('de-DE', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
									})} - ${note.creator_name}`}</span>
									<PenCircleFilled className="edit-icon" onClick={() => onEdit(note)} />
								</div>
								<div className="content">
									<span className="title" onClick={() => onEdit(note)}>
										{note.title}
									</span>
									<span className="description">{truncateDescription(note.text)}</span>
									<div className="attachments">
										{note.attachments &&
											note.attachments.slice(0, 3).map((attachment, index) => {
												const fileName = attachment.split('/').pop();
												return <Chip key={index} label={fileName} className="attachment-chip" />;
											})}
									</div>
								</div>
							</div>
						</li>
					))}
			</ul>
			<ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
		</div>
	);
}

export default WidgetNotes;
