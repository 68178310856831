import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'store';
import apiBaseUrls from 'constants/apiBaseUrls';
import pimcoreApi from '../pimcore';
import { heatingDevicePortalActions } from '../heatingdevice/reducer';
import { Note, NotePayload } from 'components/WidgetNotes/WidgetNotes.d';
import { DeviceEvent, DeviceEventQueryParams, DeviceEventResponse } from 'types/DeviceEvent';
import { Maintenance, PostMaintenanceRequest } from 'types/Maintenance';
import { MaintenanceFilters } from 'types/MaintenanceFilters';
import { List } from 'types/List';
import { ComponentDetails, IComponent } from 'types/Component';
import { IHeatingDevice } from 'types/IHeatingDevice';

const baseQueryFactory = async (api, baseUrl: string) => {
	let apiToken = (api.getState() as RootState).heatingdevice.token;

	if (!apiToken) {
		const { data: tokenResponse } = await api.dispatch(pimcoreApi.endpoints.getToken.initiate('')).unwrap();
		if (tokenResponse && tokenResponse.token) {
			api.dispatch(heatingDevicePortalActions.setToken(tokenResponse.token));
			apiToken = tokenResponse.token;
		}
	}

	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${apiToken}`);
			return headers;
		},
	});
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].backbone);

	let result = await baseQuery(args, api, extraOptions);
	if (result.error && (result.error.status === 'FETCH_ERROR' || result.error.status === 'PARSING_ERROR')) {
		baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].backbone);
		result = await baseQuery(args, api, extraOptions);
	}
	return result;
};

// Define a service using a base URL and expected endpoints
export const backboneApi = createApi({
	reducerPath: 'backboneApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Systems', 'SystemAssignments', 'SystemComponents', 'SystemEvents', 'SystemNotes', 'SystemStatistics', 'SystemMaintenances'],
	endpoints: (builder) => ({
		getSystems: builder.query<any, string>({
			query: (filters) => {
				const urlFilters = decodeURIComponent(filters);
				return {
					url: `/devices?${urlFilters}`,
					method: 'GET',
				};
			},
			transformResponse: (response: List<Array<IHeatingDevice>>, meta, arg) => {
				response.items = response.items.map((item: any) => {
					const mainComponents = item.components.filter((component: IComponent) => component.is_main_component);
					item.subRows = mainComponents.map((component) => {
						return { ...component, uuid: item.uuid };
					});

					item.serial = '';

					if (item.components.length === 1 && item.components[0].is_main_component) {
						item.serial = item.components[0].serial;
					}

					item.type_description = '';

					return item;
				});
				return response;
			},
			providesTags: (result, err, arg) =>
				result.items
					? [...result.items.map(({ uuid }) => ({ type: 'Systems' as const, uuid })), { type: 'Systems', id: 'LIST' }]
					: [{ type: 'Systems', id: 'LIST' }],
		}),
		postSystems: builder.mutation<any, any>({
			query: (payload) => {
				return {
					url: `/installations`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'Systems', id: result.uuid },
				{ type: 'Systems', id: 'LIST' },
			],
		}),
		getSystem: builder.query<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}`,
				method: 'GET',
			}),
			providesTags: (result) => [{ type: 'Systems' as const, id: result.uuid }],
		}),
		patchSystem: builder.mutation<any, { systemId: string; payload: any }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}`,
				method: 'PATCH',
				body: payload,
			}),
			invalidatesTags: (result) => [
				{ type: 'Systems', id: result.uuid },
				{ type: 'Systems', id: 'LIST' },
			],
		}),
		deleteSystem: builder.mutation<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, err, arg) => {
				return [
					{ type: 'Systems', id: arg },
					{ type: 'Systems', id: 'LIST' },
				];
			},
		}),
		getSystemAssignments: builder.query<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}/assignments`,
				method: 'GET',
			}),
			providesTags: (result, err, arg) => [{ type: 'SystemAssignments' as const, id: arg }],
		}),
		postSystemAssignments: builder.mutation<any, { systemId: string; payload: any }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}/assignments`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [{ type: 'SystemAssignments' as const, id: arg.systemId }],
		}),
		deleteSystemAssignments: builder.mutation<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}/assignments`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, err, arg) => [{ type: 'SystemAssignments' as const, id: arg }],
		}),
		getSystemComponents: builder.query<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}/components?is_archived=false`,
				method: 'GET',
			}),
			providesTags: (result, err, arg) =>
				result.items
					? [
							...result.items.map(({ uuid }) => ({ type: 'SystemComponents' as const, id: uuid })),
							{ type: 'SystemComponents', id: `LIST-${arg}` },
					  ]
					: [{ type: 'SystemComponents', id: `LIST-${arg}` }],
		}),
		postSystemComponents: builder.mutation<any, { systemId: string; payload: any }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}/components`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [{ type: 'SystemComponents' as const, id: `LIST-${arg.systemId}` }],
		}),
		getSystemComponent: builder.query<any, { systemId: string; componentId: string }>({
			query: ({ systemId, componentId }) => ({
				url: `/devices/${systemId}/components/${componentId}`,
				method: 'GET',
			}),
			providesTags: (result, err, arg) => [{ type: 'SystemComponents' as const, id: arg.componentId }],
		}),
		getComponentDetails: builder.query<ComponentDetails, string>({
			query: (serial_or_material) => ({
				url: `/components/${serial_or_material}`,
				method: 'GET',
			}),
		}),
		patchSystemComponent: builder.mutation<any, { systemId: string; componentId: string; payload: any }>({
			query: ({ systemId, componentId, payload }) => ({
				url: `/devices/${systemId}/components/${componentId}`,
				method: 'PATCH',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'SystemComponents' as const, id: arg.componentId },
				{ type: 'SystemComponents' as const, id: `LIST-${arg.systemId}` },
			],
		}),
		deleteSystemComponent: builder.mutation<any, { systemId: string; componentId: string }>({
			query: ({ systemId, componentId }) => ({
				url: `/devices/${systemId}/components/${componentId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'SystemComponents' as const, id: arg.componentId },
				{ type: 'SystemComponents' as const, id: `LIST-${arg.systemId}` },
			],
		}),
		getSystemNotes: builder.query<any, string>({
			query: (systemId) => ({
				url: `/devices/${systemId}/notes`,
				method: 'GET',
			}),
			transformResponse: (response: { offset: number; limit: number; total: number; items: Array<Note> }) => {
				return response.items;
			},
			providesTags: (result, err, arg) =>
				result.items
					? [...result.items.map(({ uuid }) => ({ type: 'SystemNotes' as const, uuid })), { type: 'SystemNotes', id: `LIST-${arg}` }]
					: [{ type: 'SystemNotes', id: `LIST-${arg}` }],
		}),
		postSystemNotes: builder.mutation<Note, { systemId: string; payload: NotePayload }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}/notes`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'SystemNotes' as const, id: result?.uuid },
				{ type: 'SystemNotes', id: `LIST-${arg.systemId}` },
			],
		}),
		deleteSystemNotes: builder.mutation<any, { systemId: string; noteId: string }>({
			query: ({ systemId, noteId }) => ({
				url: `/devices/${systemId}/notes/${noteId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, err, arg) => [{ type: 'SystemNotes' as const, id: `LIST-${arg.systemId}` }],
		}),
		patchSystemNotes: builder.mutation<Note, { systemId: string; noteId: string; payload: NotePayload }>({
			query: ({ systemId, noteId, payload }) => ({
				url: `/devices/${systemId}/notes/${noteId}`,
				method: 'PATCH',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'SystemNotes' as const, id: result?.uuid },
				{ type: 'SystemNotes', id: `LIST-${arg.systemId}` },
			],
		}),
		putSystemNotesAttachment: builder.mutation<any, { systemId: string; payload: any }>({
			query: ({ systemId, payload }) => ({
				url: `/devices/${systemId}/notes/attachment`,
				method: 'PUT',
				body: payload,
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'SystemNotes' as const, id: result.uuid },
				{ type: 'SystemNotes', id: `LIST-${arg.systemId}` },
			],
		}),
		getDeviceStatistics: builder.query<any, string>({
			query: () => ({
				url: `/statistics/devices`,
				method: 'GET',
			}),
			providesTags: ['SystemStatistics'],
		}),
		getCurrentEvents: builder.query<List<Array<DeviceEvent>>, DeviceEventQueryParams | null>({
			query: (filters) => {
				const searchParams = new URLSearchParams(filters as any);
				if (!filters?.type || !filters?.type.length) searchParams.delete('type');
				return {
					url: `/events/me?${searchParams}`,
					method: 'GET',
				};
			},
		}),
		getMaintenances: builder.query<List<Maintenance>, MaintenanceFilters>({
			query: (filters) => ({
				url: `/maintenances?${new URLSearchParams(filters as URLSearchParams)}`,
				method: 'GET',
			}),
		}),
		postMaintenances: builder.mutation<any, Array<PostMaintenanceRequest>>({
			query: (payload) => ({
				url: `/maintenances`,
				method: 'POST',
				body: payload,
			}),
		}),
		getMaintenanceNumbers: builder.query<any, string>({
			query: (serial_or_material_number) => ({
				url: `/maintenances/numbers?serial_or_material_number=${serial_or_material_number}`,
				method: 'GET',
			}),
		}),
		getMaintenanceKit: builder.query<any, string>({
			query: (serialOrMaterialNumber) => ({
				url: `/maintenances/${serialOrMaterialNumber}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	getSystems,
	postSystems,
	getSystem,
	patchSystem,
	deleteSystem,
	getSystemAssignments,
	postSystemAssignments,
	deleteSystemAssignments,
	getSystemComponents,
	postSystemComponents,
	getSystemComponent,
	patchSystemComponent,
	deleteSystemComponent,
	getSystemNotes,
	postSystemNotes,
	deleteSystemNotes,
	putSystemNotesAttachment,
	getDeviceStatistics,
	getCurrentEvents,
	getMaintenances,
	postMaintenances,
	getMaintenanceKit,
} = backboneApi.endpoints;

export default backboneApi;
