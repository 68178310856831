import { Button, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Switch, Theme, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import { ModalRCProps } from './ModalRC.d';
import { Close } from 'assets/icons';

const useStyles = makeStyles((theme) => ({
	topScrollPaper: {
		justifyContent: 'flex-end',
	},
	noMargin: {
		margin: 'unset',
	},
	fullHeight: {
		maxHeight: 'unset',
		height: '100%',
	},
	paperWidth: {
		width: '40%',
		maxWidth: 'unset',
	},
	closeButton: {
		position: 'absolute',
		color: '#6b718b',
		right: 12,
		top: 8,
	},
	closeIcon: {
		width: 16,
		color: '#000',
		'&.withSearch': {
			color: '#fff',
		},
	},
	dialogTitle: {
		padding: '20px 20px 0px 42px',
		'&.withSearch': {
			padding: '20px 20px 49px 42px',
			backgroundColor: '#212d40',
			color: '#fff',
		},
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 64,

		'& > div.a-input > div.dropdown > div.password-wrapper > input.input-field': {
			width: '80%',
			color: '#fff',
			backgroundColor: '#212d40',
			boxShadow: 'inset 0 0 0 1px #6b718b',
		},
	},
	switch: {
		width: 64,
		height: 32,
		marginLeft: 16,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform: 'translateX(32px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: '#2ECA45',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 28,
			height: 28,
		},
		'& .MuiSwitch-track': {
			borderRadius: 32 / 2,
			backgroundColor: '#c7cacf',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	},
	headlineIconsContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 20,
	},
	headline: {
		fontFamily: 'Saira',
		fontSize: 32,
		fontWeight: 500,
		'&.withIcon': {
			marginLeft: 16,
		},
	},
	dialogActions: {
		margin: 0,
		padding: theme.spacing(1),
		backgroundColor: '#f8f8fa',
	},
}));

const NextButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		margin: '16px 10% !important',
		backgroundColor: '#212d40',
		borderRadius: 0,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const PrevButton = withStyles((theme: Theme) => ({
	root: {
		color: '#6b718b',
		backgroundColor: 'transparent',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

function ModalRC(props: ModalRCProps): JSX.Element {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withIcon, withSearch, withActions, icon, open, headline, searchPlaceholder, checked, setSearchValue, onClose, onToggle, onFormSubmit, withToggle } =
		props;

	return (
		<div className="m-remote-control-modal">
			<form onSubmit={onFormSubmit}>
				<Dialog
					onClose={onClose}
					open={open}
					disablePortal
					classes={{
						scrollPaper: classes.topScrollPaper,
						paper: classes.noMargin,
						paperScrollPaper: classes.fullHeight,
						paperWidthSm: classes.paperWidth,
					}}
					PaperProps={{ square: true }}>
					<DialogTitle disableTypography className={`${classes.dialogTitle} ${withSearch ? 'withSearch' : null}`}>
						<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
							<Close className={`${classes.closeIcon} ${withSearch ? 'withSearch' : null}`} />
						</IconButton>
						<div className={`${classes.headerContainer} withSearch`}>
							<div className={classes.headlineIconsContainer}>
								{withIcon ? <span style={{ width: 32, cursor: 'pointer' }} onClick={onClose}>{icon}</span> : null}
								<span className={`${classes.headline} ${withIcon ? 'withIcon' : null}`}>{headline}</span>
								{withToggle && (<Switch
									focusVisibleClassName=".Mui-focusVisible"
									className={classes.switch}
									checked={checked}
									disableRipple
									name='modeSwitch'
									value={'on'}
									onChange={onToggle}
								/>)}
							</div>
							{withSearch ? <Input type="search" placeholder={searchPlaceholder} setValue={setSearchValue} /> : null}
						</div>
					</DialogTitle>
					<DialogContent>{props.children}</DialogContent>
					{withActions ? (
						<DialogActions className={classes.dialogActions}>
							<PrevButton onClick={() => onClose?.()}>{t('Previous')}</PrevButton>
							<NextButton type="submit">{t('Save')}</NextButton>
						</DialogActions>
					) : null}
				</Dialog>
			</form>
		</div>
	);
}

export default ModalRC;
