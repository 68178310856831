import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'store';
import apiBaseUrls from 'constants/apiBaseUrls';
import pimcoreApi from '../pimcore';
import { heatingDevicePortalActions } from '../heatingdevice/reducer';

const baseQueryFactory = async (api, baseUrl: string) => {
	let apiToken = (api.getState() as RootState).heatingdevice.token;

	if (!apiToken) {
		const { data: tokenResponse } = await api.dispatch(pimcoreApi.endpoints.getToken.initiate('')).unwrap();
		if (tokenResponse && tokenResponse.token) {
			api.dispatch(heatingDevicePortalActions.setToken(tokenResponse.token));
			apiToken = tokenResponse.token;
		}
	}

	return fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${apiToken}`);
			return headers;
		},
	});
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].iot);

	let result = await baseQuery(args, api, extraOptions);
	if (result.error && (result.error.status === 'FETCH_ERROR' || result.error.status === 'PARSING_ERROR')) {
		baseQuery = await baseQueryFactory(api, apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].iot);
		result = await baseQuery(args, api, extraOptions);
	}
	return result;
};

export const iotApi = createApi({
	reducerPath: 'iotApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Parameters'],
	endpoints: (builder) => ({
		getParameters: builder.query<any, string>({
			query: (uuid) => ({
				url: `/systems/${uuid}/parameters`,
				method: 'GET',
			}),
			providesTags: (result, err, arg) =>
				result
					? [
							{ type: 'Parameters', id: arg },
							{ type: 'Parameters', id: 'LIST' },
					  ]
					: [{ type: 'Parameters', id: 'LIST' }],
		}),
		putParameters: builder.mutation<any, { uuid: string; parameters: any }>({
			query: ({ uuid, parameters }) => ({
				url: `/systems/${uuid}/parameters`,
				method: 'PUT',
				body: parameters,
			}),
			invalidatesTags: (result, err, arg) => [
				{ type: 'Parameters' as const, id: arg.uuid },
				{ type: 'Parameters', id: 'LIST' },
			],
		}),
		getFailures: builder.query<any, string>({
			query: (uuid) => ({
				url: `/systems/${uuid}/failures`,
				method: 'GET',
			}),
		}),
	}),
});

export const { getParameters, putParameters, getFailures } = iotApi.endpoints;

export default iotApi;
