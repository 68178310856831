import React from 'react';
import { ModalAddMaintenanceCardProps, MoleculeStep2Props } from 'components/ModalAddMaintenance/ModalAddMaintenance.d';
import FormGroup from './FormGroup';
import { useTranslation } from 'react-i18next';

function Step2(props: MoleculeStep2Props): JSX.Element {
	const { t } = useTranslation();
	const { cards } = props;
	return (
		<div className="m-step2" id="step2">
			<span className="modal-title">{t('Add maintenance')}</span>
			{cards.map((card: ModalAddMaintenanceCardProps, index: number) => (
				<FormGroup
					key={index}
					id={index}
					formGroupTitle={card.columns[0].value}
					toggle={{ id: 'toggleIdentifier' + index, items: [t('Serial number'), t('Material number')] }}
					inputSerialNumber={{
						placeholder: t('Serial number').toString(),
						label: t('Serial number maintenance kit').toString(),
						type: 'label',
						required: card.columns[1].required,
						width: 350,
					}}
					inputMaterialNumber={{
						placeholder: t('Material number').toString(),
						label: t('Material number maintenance kit').toString(),
						type: 'label',
						required: card.columns[1].required,
						width: 350,
					}}
					maintenanceDateLabel={t('Maintenance date').toString()}
				/>
			))}
		</div>
	);
}
export default Step2;
