import React, { memo } from "react";
import { HeatingDeviceStatusEnum } from 'types/HeatingDeviceStatusEnum';
import ReactStatusTag from 'components/StatusTag/StatusTag';
import './statusTag.scss';

interface StatusTagProps {
  type: 'pill' | 'icon'
  status: HeatingDeviceStatusEnum,
  is_maintenance: boolean,
  is_failure: boolean,
  is_iot_permission_granted: boolean,
}

enum StatusTagEnum {
  ONLINE = "ONLINE",
  ERROR = "ERROR",
  MAINTENANCE = "MAINTENANCE",
  OFFLINE = "OFFLINE",
  ARCHIVED = "ARCHIVED",
}

const StatusTag = ({type, status, is_failure, is_maintenance, is_iot_permission_granted}: StatusTagProps) => {

  let statusResult = StatusTagEnum.ONLINE;
  let statusColor: "default" | "error" | "offline" | "warning" | "red" | "green" | "yellow" | "gray" | undefined = 'default';

  if (status === HeatingDeviceStatusEnum.ONLINE) {
    if (is_maintenance) {
      statusResult = StatusTagEnum.MAINTENANCE;
      statusColor = 'warning';
    }

    if (is_failure) {
      statusResult = StatusTagEnum.ERROR;
      statusColor = 'error';
    }
  }

  if (status === HeatingDeviceStatusEnum.OFFLINE || status === HeatingDeviceStatusEnum.UNKNOWN || status === HeatingDeviceStatusEnum.ARCHIVED || !is_iot_permission_granted) {
    statusResult = StatusTagEnum.OFFLINE;
    statusColor = 'offline';
  }

  return (
    <div className="m-system-management-status-tag">
      {{
        ['pill']: <ReactStatusTag text={statusResult.charAt(0).toUpperCase() + statusResult.slice(1).toLowerCase() } color={statusColor} statusInfo={status} statusResult={statusResult} iotPermission={is_iot_permission_granted} />,
        ['icon']: <><i className="icon icon-status-online"></i> {statusResult}</>
      }[type]}
    </div>
  )
}

export default memo(StatusTag)
