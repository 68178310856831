import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetCustomerDetailsProps } from './WidgetCustomerDetails.d';
import TextLink from 'components/TextLink/TextLink';
import WidgetCustomerData from 'components/WidgetCustomerData';
import { EmailOutlined, PenCircleFilled, PhoneOutlined, PinOutlined } from 'assets/icons';

function CustomerDetailsWidgetComponent(props: WidgetCustomerDetailsProps): JSX.Element {
	const [openCustomerDataModal, setOpenCustomerDataModal] = useState<boolean>(false);
	const { customer, deviceAddress, translations } = props;
	const formattedAddress: string | null = customer
		? `${customer?.address?.street} ${customer?.address?.house_number}, ${customer?.address?.zip_code} ${customer?.address?.city}`
		: null;
	const { t, i18n } = useTranslation();

	const generateGoogleMapsURL = () => {
		const url = new URL(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`);
		return url.href;
	};

	const handleCloseCustomerDataModal = () => {
		setOpenCustomerDataModal(false);
	};

	const handleOpenCustomerDataModal = () => {
		setOpenCustomerDataModal(true);
	};
	return (
		<div className="m-customer-details-widget">
			<div className="wrapper">
				<div className="data-wrapper">
					<div className="headline-wrapper">
						<span className="headline">{t('Customer Data')}</span>
						<div className="edit-wrapper">
							<div className="edit-link" onClick={handleOpenCustomerDataModal}>
								<PenCircleFilled></PenCircleFilled>
								<span>{t('edit')}</span>
							</div>
						</div>
					</div>
					{customer ? (
						<>
							<span className="text">{customer?.salutation && customer?.salutation !== 'keine Angabe' ? `${customer?.salutation} ${customer?.firstname} ${customer?.lastname}` : `${customer?.firstname} ${customer?.lastname}`}</span>
							<span className="text">{`${customer?.address?.street} ${customer?.address?.house_number}`} </span>
							<span className="text">{`${customer?.address?.zip_code} ${customer?.address?.city}`}</span>
							<div className="customer-phone-container">
								<PhoneOutlined className="customer-phone-icon" />
								<TextLink text={customer?.phone} type="default" link={`tel:${customer?.phone}`} />
							</div>
							{customer?.email ? (
								<div className="customer-email-container">
									<EmailOutlined className="customer-email-icon" />
									<TextLink text={customer?.email} type="default" link={`mailto:${customer?.email}`} />
								</div>
							) : (
								<div className="email-replacement" />
							)}
							<span className="location-text">{t('Customer Data System Location')}</span>
							<div className="customer-location-container">
								<PinOutlined className="customer-location-icon" />
								<TextLink text={formattedAddress ?? ''} type="default" link={generateGoogleMapsURL()} />
							</div>
						</>
					) : (
						<div className="empty-wrapper">
							<img className="placeholder-image" src="https://via.placeholder.com/156" />
							<span className="empty-message">{translations.emptyMessage}</span>
						</div>
					)}
				</div>
			</div>
			<WidgetCustomerData
				customer={customer}
				deviceAddress={deviceAddress}
				open={openCustomerDataModal}
				handleClose={handleCloseCustomerDataModal}
			/>
		</div>
	);
}

export default CustomerDetailsWidgetComponent;
