import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ProgressBarLargeProps } from './ProgressBarLarge.d';
import { StepConnector, withStyles } from '@material-ui/core';
import { CheckThin } from 'assets/icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			'& .MuiStepLabel-label': {
				fontFamily: 'Inter',
				fontSize: 16,
				fontWeight: 500,
				color: '#212d40',
				marginTop: 8,
			},
			'& .Mui-error': {
				color: '#eb525a',
			},
			'& .MuiStepper-root': {
				backgroundColor: 'transparent',
				padding: 0,
			},
		},
		button: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		iconDefault: {
			width: 24,
			height: 24,
			borderRadius: 6,
			color: 'white',
			textAlign: 'center',
			fontFamily: 'Inter',
			fontSize: 12,
			fontWeight: 600,
			cursor: 'pointer',
		},
		iconActive: {
			backgroundColor: '#212d40',
		},
		iconCompleted: {
			backgroundColor: '#74b959',
		},
		iconInactive: {
			backgroundColor: '#c7cacf',
		},
		iconError: {
			backgroundColor: '#eb525a',
		},
		iconText: {
			paddingTop: 4,
		},
	}),
);

const ConitnuousConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 12px)',
		right: 'calc(50% + 12px)',
	},
	active: {
		'& $line': {
			backgroundColor: '#74b959',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#74b959',
		},
	},
	line: {
		height: 2,
		border: 0,
		backgroundColor: '#c7cacf',
		borderRadius: 1,
		width: '100%',
	},
})(StepConnector);

export default function LargeProgressBar(props: ProgressBarLargeProps): JSX.Element {
	const { steps, activeStep, failedSteps, setActiveStep } = props;

	const classes = useStyles();

	const handleSetActive = (index: number) => {
		setActiveStep ? setActiveStep(index) : null;
	};

	return (
		<div className={classes.root}>
			<Stepper alternativeLabel activeStep={activeStep} connector={<ConitnuousConnector />}>
				{steps.map((label, index) => {
					const stepProps: { completed?: boolean } = {};
					const labelProps: { error?: boolean } = {};
					let variant = '';
					if (failedSteps.includes(index)) {
						labelProps.error = true;
						variant = 'error';
					} else if (activeStep === index) variant = 'active';
					else if (activeStep > index) variant = 'completed';
					else variant = 'inactive';
					return (
						<Step key={label} {...stepProps}>
							{
								{
									inactive: (
										<StepLabel
											StepIconComponent={() => (
												<div
													className={`${classes.iconDefault} ${classes.iconInactive}`}
													onClick={() => handleSetActive(index)}>
													<div className={classes.iconText}>{index + 1}</div>
												</div>
											)}
											{...labelProps}>
											{label}
										</StepLabel>
									),
									active: (
										<StepLabel
											StepIconComponent={() => (
												<div className={`${classes.iconDefault} ${classes.iconActive}`}>
													<div className={classes.iconText}>{index + 1}</div>
												</div>
											)}
											{...labelProps}>
											{label}
										</StepLabel>
									),
									completed: (
										<StepLabel
											StepIconComponent={() => (
												<div
													className={`${classes.iconDefault} ${classes.iconCompleted}`}
													onClick={() => handleSetActive(index)}>
													<div className={classes.iconText}>
														<CheckThin style={{ width: 12 }} className="iconCheck" />
													</div>
												</div>
											)}
											{...labelProps}>
											{label}
										</StepLabel>
									),
									error: (
										<StepLabel
											StepIconComponent={() => (
												<div className={`${classes.iconDefault} ${classes.iconError}`} onClick={() => handleSetActive(index)}>
													<div className={classes.iconText}>!</div>
												</div>
											)}
											{...labelProps}>
											{label}
										</StepLabel>
									),
								}[variant]
							}
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
}
