import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';import { palette } from "./colors";
import { typography } from "./typography";
import * as overrides from './components'

const theme = createTheme({
    palette,
    typography: {
        ...typography,
        button: {
            textTransform: "none",
        },
    },
    overrides,
});

export default theme;
