import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiBaseUrls from 'constants/apiBaseUrls';
import SystemData from 'types/SystemData';

// Define a service using a base URL and expected endpoints
export const pimApi = createApi({
	reducerPath: 'pimApi',
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].pim,
	}),
	endpoints: (builder) => ({
		getSystemData: builder.query<SystemData, { sku: string; name: string }>({
			query: ({ sku, name }) =>
				`/api/rest/export/v1-export-device-management?apikey=e0916d864585fa1cb1af410d04b71335&locale=de&limit=1&async=0&sku=${sku}&name=${name}`,
			transformResponse: (response: Array<SystemData>, _meta, arg) => {
				const name = response[0].name;
				const categoryName = response[0].categoryName;
				const imagePath = response[0].images[0].replace(/\\/g, '');
				return { name, categoryName, sku: arg.sku, images: [imagePath] };
			},
		}),
	}),
});

export const { getSystemData } = pimApi.endpoints;

export default pimApi;
