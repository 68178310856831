import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import SystemDetails from './SystemDetails';
import { SystemDetailsData } from './SystemDetails.d';
import { AppDispatch } from 'store';

export default function Root(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const routeParams = useParams();
	const { id } = routeParams;
	useEffect(() => {
		// dispatch(getSystem(id!));
		// dispatch(getSystemComponents(id!));
		// dispatch(
		// 	getCurrentEvents({
		// 		creation_date_from: new Date(`${new Date().getFullYear()}-01-01`).toISOString(),
		// 		creation_date_to: new Date(`${new Date().getFullYear()}-12-31`).toISOString(),
		// 	}),
		// );
	}, [dispatch, id, location.search]);

	return <SystemDetails {...SystemDetailsData}></SystemDetails>;
}
