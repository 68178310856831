import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Toggle from 'components/Toggle';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const CustomerData = (props: any) => {
	const [company, setCompany] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [salutation, setSalutation] = useState<string>('');
	const [customerAddressType, setCustomerAddressType] = useState<boolean>(true);
	const [customerStreet, setCustomerStreet] = useState<string>('');
	const [customerHouseNumber, setCustomerHouseNumber] = useState<string>('');
	const [customerZipCode, setCustomerZipCode] = useState<string>('');
	const [customerCity, setCustomerCity] = useState<string>('');
	const [customerCountry, setCustomerCountry] = useState<string>('');
	const [googlePlace, setGooglePlace] = useState<any>(null);
	const [addressIsDifferent, setAddressIsDifferent] = useState<boolean>(false);
	const [differentCustomerAddressType, setDifferentCustomerAddressType] = useState<boolean>(true);
	const [differentCustomerStreet, setDifferentCustomerStreet] = useState<string>('');
	const [differentCustomerHouseNumber, setDifferentCustomerHouseNumber] = useState<string>('');
	const [differentCustomerZipCode, setDifferentCustomerZipCode] = useState<string>('');
	const [differentCustomerCity, setDifferentCustomerCity] = useState<string>('');
	const [differentCustomerCountry, setDifferentCustomerCountry] = useState<string>('');
	const [differentGooglePlace, setDifferentGooglePlace] = useState<any>(null);

	useEffect(() => {
		if (addressIsDifferent)
			props?.saveData({
				company,
				firstName,
				lastName,
				phone,
				email,
				salutation,
				customerAddressType,
				customerStreet,
				customerHouseNumber,
				customerZipCode,
				customerCity,
				customerCountry,
				addressIsDifferent,
				differentCustomerAddressType,
				differentCustomerStreet,
				differentCustomerHouseNumber,
				differentCustomerZipCode,
				differentCustomerCity,
				differentCustomerCountry,
			});
		else
			props?.saveData({
				company,
				firstName,
				lastName,
				phone,
				email,
				salutation,
				customerAddressType,
				customerStreet,
				customerHouseNumber,
				customerZipCode,
				customerCity,
				customerCountry,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		firstName,
		lastName,
		phone,
		email,
		salutation,
		customerAddressType,
		customerStreet,
		customerHouseNumber,
		customerZipCode,
		customerCity,
		customerCountry,
		addressIsDifferent,
		differentCustomerAddressType,
		differentCustomerStreet,
		differentCustomerHouseNumber,
		differentCustomerZipCode,
		differentCustomerCity,
		differentCustomerCountry,
	]);

	const handleCompanyChange = (company: string) => {
		setCompany(company);
	};

	const handleFirstNameChange = (name: string) => {
		setFirstName(name);
	};

	const handleLastNameChange = (name: string) => {
		setLastName(name);
	};

	const handlePhoneChange = (phone: string) => {
		setPhone(phone);
	};

	const handleEmailChange = (email: string) => {
		setEmail(email);
	};

	const handleSalutationChange = (salutation: string) => {
		setSalutation(salutation);
	};

	const handleCustomerAddressTypeChange = (customerAddressType: boolean) => {
		setCustomerAddressType(customerAddressType);
	};

	const handleCustomerStreetChange = (customerStreet: string) => {
		setCustomerStreet(customerStreet);
	};

	const handleCustomerHouseNumberChange = (customerHouseNumber: string) => {
		setCustomerHouseNumber(customerHouseNumber);
	};

	const handleCustomerZipCodeChange = (customerZipCode: string) => {
		setCustomerZipCode(customerZipCode);
	};

	const handleCustomerCityChange = (customerCity: string) => {
		setCustomerCity(customerCity);
	};

	const handleCustomerCountryChange = (customerCountry: string) => {
		setCustomerCountry(customerCountry);
	};

	const handleDifferentAddressChange = (addressIsDifferent: React.ChangeEvent<HTMLInputElement>) => {
		setAddressIsDifferent(addressIsDifferent.target.checked);
	};

	const handleDifferentCustomerAddressTypeChange = (differentCustomerAddressType: boolean) => {
		setDifferentCustomerAddressType(differentCustomerAddressType);
	};

	const handleDifferentCustomerStreetChange = (differentCustomerStreet: string) => {
		setDifferentCustomerStreet(differentCustomerStreet);
	};

	const handleDifferentCustomerHouseNumberChange = (differentCustomerHouseNumber: string) => {
		setDifferentCustomerHouseNumber(differentCustomerHouseNumber);
	};

	const handleDifferentCustomerZipCodeChange = (differentCustomerZipCode: string) => {
		setDifferentCustomerZipCode(differentCustomerZipCode);
	};

	const handleDifferentCustomerCityChange = (differentCustomerCity: string) => {
		setDifferentCustomerCity(differentCustomerCity);
	};

	const handleDifferentCustomerCountryChange = (differentCustomerCountry: string) => {
		setDifferentCustomerCountry(differentCustomerCountry);
	};

	const handleDifferentGooglePlaceChange = (differentGooglePlace: string) => {
		setDifferentGooglePlace(differentGooglePlace);
	};

	const getAddressComponents = (address) => {
		if (!address) {
			return null;
		}
		const result: any = {
			house_number: null,
			street: null,
			city: null,
			country: null,
			zip_code: null,
		};

		for (let i = 0; i < address['address_components'].length; i++) {
			const component = address['address_components'][i];

			if (component.types.includes('street_number')) {
				result.house_number = parseInt(component.long_name);
			} else if (component.types.includes('route')) {
				result.street = component.long_name;
			} else if (component.types.includes('locality')) {
				result.city = component.long_name;
			} else if (component.types.includes('country')) {
				result.country = component.long_name;
			} else if (component.types.includes('postal_code')) {
				result.zip_code = component.long_name;
			}
		}
		return result;
	};

	return (
		<div className="add-devices">
			<fieldset className="m-form-group">
				<legend className="legend-title">{t('Customer Data')}</legend>
				<div className="formGroupContainer">
					<div className="inputContainer">
						<div className="row">
							<div className="salutationContainer">
								<Dropdown
									name="salutation"
									label={t('Salutation').toString()}
									placeholder={t('Salutation').toString()}
									defaultValue={'MR'}
									items={[
										{ value: 'MR', text: 'MR' },
										{ value: 'MS', text: 'MS' },
										{ value: 'OTHERS', text: 'OTHERS' },
										{ value: 'UNKNOWN', text: 'UNKNOWN' },
									]}
									setOption={handleSalutationChange}

								/>
							</div>
							<Input
								placeholder={t('First Name').toString()}
								type={'label'}
								setValue={handleFirstNameChange}
								label={`${t('First Name')}*`}
								error={props?.errors?.firstName}
								invalid={!!props?.errors?.firstName}
							/>
							<Input
								setValue={handleLastNameChange}
								placeholder={t('Last Name').toString()}
								type={'label'}
								label={`${t('Last Name')}*`}
								error={props?.errors?.lastName}
								invalid={!!props?.errors?.lastName}
							/>
						</div>
						<div className="row">
							<Input setValue={handlePhoneChange} placeholder={t('Phone').toString()} type={'label'} label={t('Phone').toString()} />
							<Input
								setValue={handleEmailChange}
								placeholder={t('Email').toString()}
								type={'label'}
								label={`${t('Email')}*`}
								error={props?.errors?.email}
								invalid={!!props?.errors?.email}
							/>
							<Input placeholder={t('Company').toString()} type={'label'} setValue={handleCompanyChange} label={`${t('Company')}`} />
						</div>
					</div>
				</div>
			</fieldset>
			<br></br>
			<fieldset className="m-form-group">
				<legend className="legend-title">{t('Residence')}</legend>
				<div className="header">
					<Toggle items={[t('Manual'), t('Automatic')]} value={customerAddressType} setToggle={handleCustomerAddressTypeChange} />
				</div>
				<div className="formGroupContainer">
					<div className="inputContainer">
						{customerAddressType ? (
							<div className="row">
								<Input
									placeholder={t('Street').toString()}
									type={'label'}
									setValue={handleCustomerStreetChange}
									label={t('Street').toString() + ' *'}
									error={props?.errors?.customerStreet}
									invalid={!!props?.errors?.customerStreet}
								/>
								<Input
									setValue={handleCustomerHouseNumberChange}
									placeholder={t('House number').toString()}
									type={'label'}
									label={`${t('House number')}*`}
									error={props?.errors?.customerHouseNumber}
									invalid={!!props?.errors?.customerHouseNumber}
								/>
								<Input
									setValue={handleCustomerZipCodeChange}
									placeholder={t('Zip').toString()}
									type={'label'}
									label={`${t('Zip')}*`}
									error={props?.errors?.customerZipCode}
									invalid={!!props?.errors?.customerZipCode}
								/>
								<Input
									setValue={handleCustomerCityChange}
									placeholder={t('City').toString()}
									type={'label'}
									label={`${t('City')}*`}
									error={props?.errors?.customerCity}
									invalid={!!props?.errors?.customerCity}
								/>
								<div className="salutationContainer">
									<Dropdown
										name="country"
										label={`${t('Country')}*`}
										placeholder={t('Country').toString()}
										defaultValue={'Herr'} //to be changed
										items={[{ value: 'Germany', text: t('Germany') }]}
										setOption={handleCustomerCountryChange}
										error={props?.errors?.customerCountry}
										invalid={!!props?.errors?.customerCountry}
									/>
								</div>
							</div>
						) : (
							<div className="row">
								<div className="automaticAddressContainer">
									<Input
										type="address"
										id="address"
										value={googlePlace?.formatted_address || ''}
										placeholder={t('Address').toString()}
										// TODO RG: Find a use for the place parameter later on when sending data to backend
										onPlaceSelected={(place: google.maps.places.PlaceResult) => setGooglePlace(place)}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</fieldset>
			<br></br>
			<Checkbox
				label={t('Device address is different than customer address').toString()}
				id="addressIsDifferent"
				name="alternative_location"
				value={addressIsDifferent.toString()}
				checked={addressIsDifferent}
				onChange={handleDifferentAddressChange}
			/>
			<br></br>
			{addressIsDifferent && (
				<fieldset className="m-form-group">
					<legend className="legend-title">{t('Customer Data System Location')}</legend>
					<div className="header">
						<Toggle
							items={[t('Manual'), t('Automatic')]}
							value={differentCustomerAddressType}
							setToggle={handleDifferentCustomerAddressTypeChange}
						/>
					</div>
					<div className="formGroupContainer">
						<div className="inputContainer">
							{differentCustomerAddressType ? (
								<div className="row">
									<Input
										placeholder={t('Street').toString()}
										type={'label'}
										setValue={handleDifferentCustomerStreetChange}
										label={t('Street').toString() + ' *'}
										error={props?.errors?.differentCustomerStreet}
										invalid={!!props?.errors?.differentCustomerStreet}
									/>
									<Input
										setValue={handleDifferentCustomerHouseNumberChange}
										placeholder={t('House number').toString()}
										type={'label'}
										label={t('House number').toString()}
										error={props?.errors?.differentCustomerHouseNumber}
										invalid={!!props?.errors?.differentCustomerHouseNumber}
									/>
									<Input
										setValue={handleDifferentCustomerZipCodeChange}
										placeholder={t('Zip').toString()}
										type={'label'}
										label={t('Zip').toString()}
										error={props?.errors?.differentCustomerZipCode}
										invalid={!!props?.errors?.differentCustomerZipCode}
									/>
									<Input
										setValue={handleDifferentCustomerCityChange}
										placeholder={t('City').toString()}
										type={'label'}
										label={t('City').toString()}
										error={props?.errors?.differentCustomerCity}
										invalid={!!props?.errors?.differentCustomerCity}
									/>
									<div className="salutationContainer">
										<Dropdown
											name="country"
											label={t('Country').toString()}
											placeholder={t('Country').toString()}
											defaultValue={'Herr'} //to be changed
											items={[{ value: 'Germany', text: t('Germany') }]}
											setOption={handleDifferentCustomerCountryChange}
											error={props?.errors?.differentCustomerCountry}
											invalid={!!props?.errors?.differentCustomerCountry}
										/>
									</div>
								</div>
							) : (
								<div className="row">
									<div className="automaticAddressContainer">
										<Input
											type="address"
											id="address"
											value={googlePlace?.formatted_address || ''}
											placeholder={t('Address').toString()}
											// TODO RG: Find a use for the place parameter later on when sending data to backend
											onPlaceSelected={(place: google.maps.places.PlaceResult) => setDifferentGooglePlace(place)}
										/>
									</div>
									<input readOnly type="hidden" value={getAddressComponents(googlePlace)?.street || ''} />
									<input readOnly type="hidden" value={getAddressComponents(googlePlace)?.house_number || ''} />
									<input readOnly type="hidden" value={getAddressComponents(googlePlace)?.zip_code || ''} />
									<input readOnly type="hidden" value={getAddressComponents(googlePlace)?.city || ''} />
									<input readOnly type="hidden" value={getAddressComponents(googlePlace)?.country || ''} />
								</div>
							)}
						</div>
					</div>
				</fieldset>
			)}
		</div>
	);
};

export default CustomerData;
