import React from 'react';
import { ToggleProps } from './Toggle.d';

function Toggle({ items, value: toggle, setToggle }: ToggleProps): JSX.Element {
	const onToggle = (ev: React.MouseEvent, value: boolean) => {
		ev.preventDefault();
		if (setToggle) setToggle(value);
	};

	if (!(items?.[0] && items?.[1])) return <></>;
	return (
		<div className="a-toggle">
			<div data-ref="defaultWrapper" className={`uk-toggle-container ${toggle ? 'active' : 'passive'} ${items[0].length > 4 ? 'wide' : ''}`}>
				<button
					onClick={(ev: React.MouseEvent) => onToggle(ev, true)}
					className={`uk-toggle__button ${items[0].length > 4 ? 'wide' : ''} ${toggle ? 'active' : 'passive'}`}>
					{items[0]}
				</button>
				<button
					onClick={(ev: React.MouseEvent) => onToggle(ev, false)}
					className={`uk-toggle__button ${items[0].length > 4 ? 'wide' : ''} ${!toggle ? 'active' : 'passive'}`}>
					{items[1]}
				</button>
			</div>
		</div>
	);
}
export default Toggle;
