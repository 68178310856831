import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './initialState';
import { v4 as uuidv4 } from 'uuid';

const uiSlice = createSlice({
	name: 'uiSlice',
	initialState,
	reducers: {
		setLoading: (state, { payload }: PayloadAction<any>) => {
			state.loading[payload.type] || (state.loading[payload.type] = false);
			state.loading[payload.type] = payload.loading;
		},
	},
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
