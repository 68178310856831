import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { NoDeviceRegistered } from 'assets/icons';
import ModalAddComponent from 'components/ModalAddComponent/ModalAddComponent';

function DeviceEmptyView(): JSX.Element {
	const [openAddMainComponentModal, setopenAddMainComponentModal] = useState<boolean>(false);
	const { t } = useTranslation();
	return (
		<div className="empty-view-container">
			<Typography variant="h2" className="empty-view-headline">
				{t('No registered device')}
			</Typography>
			<Typography paragraph className="empty-view-paragraph">
				{t(
					"It seems like you haven't registered a main device yet. Click Register Device to start the process and get the most out of all the features of our system."
				)}
			</Typography>
			<div className="empty-view-img">
				<NoDeviceRegistered />
			</div>
			<button className="register-device-btn" onClick={() => setopenAddMainComponentModal(true)}>
				{t('Register Device')}
			</button>
			<ModalAddComponent open={openAddMainComponentModal} variation="main" handleClose={() => setopenAddMainComponentModal(false)} />
		</div>
	);
}

export default DeviceEmptyView;
