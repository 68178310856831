import { CloseButtonProps, toast } from 'react-toastify';
import React from 'react';

import { AttentionFilled, CheckFilledCircle, Close, NotificationFilled } from 'assets/icons';
import { IconButton } from '@material-ui/core';

const CloseButton = ({ closeToast }: CloseButtonProps): JSX.Element => (
	<IconButton style={{ width: 48, height: 48, color: 'inherit' }} onClick={closeToast}>
		<Close style={{ width: 16, height: 16 }} />
	</IconButton>
);

const showToast = {
	success: (message: string) => {
		toast.success(message, {
			theme: 'colored',
			icon: CheckFilledCircle,
			closeButton: CloseButton,
		});
	},
	warn: (message: string) => {
		toast.warn(message, {
			theme: 'colored',
			icon: NotificationFilled,
			closeButton: CloseButton,
		});
	},
	error: (message: string) => {
		toast.error(message, {
			theme: 'colored',
			icon: AttentionFilled,
			closeButton: CloseButton,
		});
	},
};

export default showToast;
