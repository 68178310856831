import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import List from './List';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';

export default function Root(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const { i18n } = useTranslation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const searchParamsObj = Object.fromEntries(searchParams.entries());
		dispatch(heatingDevicePortalActions.setFilters({ filters: searchParamsObj }));
	}, [dispatch, location.search]);

	return <List />;
}
