import React, { useEffect, useState } from 'react';
import SystemMngModal from 'components/StepperModal/StepperModal';
import { ProgressBarLargeProps } from 'components/ProgressBarLarge/ProgressBarLarge.d';
import { ModalAddWarrantyProps } from './ModalAddWarranty.d';
import Step1 from 'components/CustomerData';
import Step2 from './components/Step2';

const stepper: ProgressBarLargeProps = {
	steps: ['Kunde', 'Zustimmung'],
	activeStep: 0,
	failedSteps: [],
};

const ModalAddWarranty = (props: ModalAddWarrantyProps) => {
	const { open, handleClose, prefilledData } = props;

	const [data, setData] = useState<any>(prefilledData);

	useEffect(() => {
		if (prefilledData) {
			setData(prefilledData);
		}
	}, [prefilledData]);

	const step1 = <Step1 key={0}></Step1>;

	const step2 = (
		<div className="add-warranty-modal">
			<Step2 key={1}></Step2>
		</div>
	);

	const flattenObject = (obj, parentKey = '', result = {}) => {
		for (const key in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(key)) {
				const propName = parentKey ? `${parentKey}.${key}` : key;
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					flattenObject(obj[key], propName, result);
				} else {
					result[propName] = obj[key];
				}
			}
		}
		return result;
	};

	const parsePrefilledData = (arr: Array<any>) => {
		const parsedArr = arr.map((obj) => {
			return flattenObject(obj);
		});
		return parsedArr;
	};

	return (
		<SystemMngModal
			prefilledData={parsePrefilledData(prefilledData)}
			stepper={stepper}
			stepContent={[step1, step2]}
			open={open}
			handleClose={() => handleClose()}
			withoutSuccessStep></SystemMngModal>
	);
};

export default ModalAddWarranty;
