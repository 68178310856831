import { Button, Theme, withStyles } from '@material-ui/core';

const PrevButton = withStyles((theme: Theme) => ({
	root: {
		color: '#6b718b',
		backgroundColor: 'transparent',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

export default PrevButton;
