import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import apiBaseUrls from 'constants/apiBaseUrls';
import WarrantyRequest from 'types/WarrantyRequest';

const baseQueryFactory = fetchBaseQuery({
	baseUrl: apiBaseUrls[process.env.REACT_APP_PIMCORE_ENV || 'development'].warranty,
	prepareHeaders: (headers) => {
		const apiKey = process.env.REACT_APP_WARRANTY_API_KEY;

		if (apiKey) {
			headers.set('x-wolf-api-key', apiKey);
		}

		return headers;
	},
});

export const warrantyApi = createApi({
	reducerPath: 'warrantyApi',
	baseQuery: baseQueryFactory,
	endpoints: (builder) => ({
		postWarranty: builder.mutation<Record<string, unknown>, WarrantyRequest>({
			query: (body) => ({
				url: '/warranty_request/bulk',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { postWarranty } = warrantyApi.endpoints;

export default warrantyApi;
