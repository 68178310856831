import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './initialState';
import type { IHeatingDeviceState } from 'types/IHeatingDeviceState';
import { DeviceEventQueryParams } from 'types/DeviceEvent';
import { HeaderProps } from 'components/Header/Header.d';
import { ComponentDetails } from 'types/Component';

const heatingDevicePortalSlice = createSlice({
	name: 'heatingDevicePortalSlice',
	initialState,
	reducers: {
		setToken(state, { payload }: PayloadAction<string>) {
			state.token = payload;
		},
		setInitialHeaderData(state, { payload }: PayloadAction<Partial<HeaderProps>>) {
			state.initialHeaderData = payload;
		},
		setHeaderProps(state, action) {
			state.header = action.payload;
		},
		setSummiteerData(state, { payload }: PayloadAction<any>) {
			state.summiteerData = payload;
		},
		setFilters(state, { payload: { filters } }: PayloadAction<Pick<IHeatingDeviceState, 'filters'>>) {
			state.filters = filters;
		},
		clearFilters(state) {
			state.filters = {};
		},
		setEventsFilters(state, { payload }: PayloadAction<DeviceEventQueryParams>) {
			state.eventsFilters = { ...state.eventsFilters, ...payload };
		},
		addSystemDevice(state, { payload }: PayloadAction<ComponentDetails>) {
			if (!state.systemDevices.some((item) => item.uuid === payload.uuid)) {
				state.systemDevices.push(payload);
			}
		},
		removeSystemDevice(state, { payload }: PayloadAction<any>) {
			state.systemDevices = state.systemDevices.filter((_, index) => index !== payload);
		},
		clearSystemDevices(state) {
			state.systemDevices = [];
		},
		setStepperModalData(state, { payload }: PayloadAction<any>) {
			state.stepperModalData = { ...state.stepperModalData, ...payload };
		},
	},
});

export const heatingDevicePortalActions = heatingDevicePortalSlice.actions;
export default heatingDevicePortalSlice.reducer;
