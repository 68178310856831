import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { AppDispatch } from 'store';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { MoleculeMaintenanceModalProps, ModalAddMaintenanceCardProps } from 'components/ModalAddMaintenance/ModalAddMaintenance.d';
import LargeProgressBar from 'components/ProgressBarLarge';
import SuccessStep from './components/SuccessStep';
import { Close } from 'assets/icons';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import backboneApi from 'store/modules/backbone';
import { useParams } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { PostMaintenanceRequest } from 'types/Maintenance';
import showToast from 'services/utils/toaster';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 16,
			backgroundColor: '#f8f8fa',
		},
		closeButton: {
			position: 'absolute',
			color: '#6b718b',
			right: 16,
			top: 24,
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close style={{ width: 16, color: '#6b718b' }} />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		minHeight: 400,
	},
}))(MuiDialogContent);

const NextButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const PrevButton = withStyles((theme: Theme) => ({
	root: {
		color: '#6b718b',
		backgroundColor: 'transparent',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		backgroundColor: '#f8f8fa',
	},
}))(MuiDialogActions);

function MaintenanceModal(props: MoleculeMaintenanceModalProps): JSX.Element {
	const { stepper, devices } = props;
	const dispatch = useDispatch<AppDispatch>();
	const [activeStep, setActiveStep] = React.useState(0);
	const [failedSteps, setFailedSteps] = React.useState<Array<number>>([]);
	const [success, setSuccess] = React.useState(false);
	const [selectedSystems, setSelectedSystems] = React.useState<Array<string>>([]);
	const [cards, setCards] = React.useState<Array<ModalAddMaintenanceCardProps>>([]);
	const [addedCount, setAddedCount] = React.useState(0);
	const [postMaintenances, _] = backboneApi.usePostMaintenancesMutation();
	const [alertDialog, setAlertDialog] = React.useState({
		open: false,
		title: '',
		content: '',
		closeText: '',
	});

	const routeParams = useParams();
	const { id } = routeParams;

	const { data: components } = backboneApi.useGetSystemComponentsQuery(id!);

	const { t } = useTranslation();

	useEffect(() => {
		const newCards: Array<ModalAddMaintenanceCardProps> = [];

		if (!components) return;

		components?.items
			?.filter((component) => component.is_main_component)
			.forEach(async (component, index) => {
				const component_numbers = await dispatch(backboneApi.endpoints.getMaintenanceNumbers.initiate(component.serial));
				const ownerFullName =
					component.owner?.firstname && component.owner?.lastname ? `${component.owner?.firstname} ${component.owner?.lastname}` : '-';

				newCards.push({
					columns: [
						{
							label: t('Product name'),
							value: component.type_description,
						},
						{
							label: t('Serial number'),
							value: component.serial,
							required: component_numbers?.data?.length > 0,
						},
						{
							label: t('Owner'),
							value: ownerFullName,
						},
						{
							label: t('Maintenance due'),
							value: component.next_maintenance_date || '',
						},
					],
					checkboxValue: component.serial,
				});
			});
		setCards(newCards);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [components]);

	const handleCloseAlertDialog = () => {
		setAlertDialog({
			open: false,
			title: '',
			content: '',
			closeText: '',
		});
	};

	const returnStep = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return <Step1 cards={cards} />;
			case 1:
				return <Step2 cards={cards.filter((card) => selectedSystems.includes(card.checkboxValue))} />;
			default:
				return <Step1 cards={cards} />;
		}
	};

	const handlePrev = () => {
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const handleClose = () => {
		props.handleClose?.();
		setActiveStep(0);
	};

	const handleSubmit = async (e) => {
		e && e.preventDefault();
		const formData = new FormData(e.target);
		const formDataObject = Object.fromEntries(formData.entries());
		let selected: Array<string> = [];
		const postMaintenancesData: Array<PostMaintenanceRequest> = [];
		let results: any = [];
		let allConfirmed = true;

		switch (activeStep) {
			case 0:
				selected = Object.keys(formDataObject);
				if (selected.length === 0) {
					showToast.error(t('You need to select at least one system to continue.'));
					break;
				}
				setSelectedSystems(selected);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 1:
				selectedSystems.forEach(async (system, index) => {
					const date = format(parse(formDataObject[`${index}-maintenanceDate`].toString(), 'dd. MMM yyyy', new Date()), 'yyyy-MM-dd');
					const number = formDataObject[`${index}-serialNumber`] || formDataObject[`${index}-materialNumber`];
					const component_serial = system;
					const confirmed_by_installer = formDataObject[`${index}-maintenanceConfirmed`] === `${index}-maintenanceConfirmed`;

					if (!confirmed_by_installer) {
						setAlertDialog({
							open: true,
							title: 'Maintenance not confirmed',
							content: 'You have not confirmed the maintenance. Please confirm the maintenance to continue.',
							closeText: 'Ok',
						});
						allConfirmed = false;
					}

					const postMaintenance: PostMaintenanceRequest = { component_serial, date, confirmed_by_installer };

					if (number) {
						postMaintenance.number = number as string;
					}

					postMaintenancesData.push(postMaintenance);
				});

				if (!allConfirmed) {
					break;
				}

				try {
					results = await postMaintenances(postMaintenancesData).unwrap();

					setAddedCount(postMaintenancesData.length);
					setSuccess(true);
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				} catch (error) {
					setSuccess(false);
					setFailedSteps([...failedSteps, activeStep]);
					setAlertDialog({
						open: true,
						title: 'Error',
						content: error.data.message,
						closeText: 'Ok',
					});
				}

				break;

			default:
				handleClose();
				break;
		}
	};

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				PaperProps={{
					style: { borderRadius: 20, minWidth: '80%' },
				}}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					<div style={{ maxWidth: '80%', margin: 'auto' }}>
						<LargeProgressBar steps={stepper.steps} failedSteps={failedSteps} activeStep={activeStep} setActiveStep={setActiveStep} />
					</div>
				</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent className="m-maintenance-modal">
						{activeStep < 2 ? returnStep(activeStep) : <SuccessStep maintenanceCount={addedCount} setActiveStep={setActiveStep} />}
					</DialogContent>
					{activeStep > 1 ? null : (
						<DialogActions>
							{activeStep > 0 && (
								<PrevButton onClick={() => handlePrev()} variant="contained" color="primary">
									{t('Back')}
								</PrevButton>
							)}
							<NextButton type="submit" variant="contained" color="primary">
								{t('Next')}
							</NextButton>
						</DialogActions>
					)}
				</form>
			</Dialog>
			<Dialog
				open={alertDialog.open}
				onClose={handleCloseAlertDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<MuiDialogTitle id="alert-dialog-title">{t(alertDialog.title)}</MuiDialogTitle>
				<MuiDialogContent>{t(alertDialog.content)}</MuiDialogContent>
				<MuiDialogActions>
					<NextButton onClick={handleCloseAlertDialog} autoFocus>
						{t(alertDialog.closeText)}
					</NextButton>
				</MuiDialogActions>
			</Dialog>
		</div>
	);
}
export default MaintenanceModal;
