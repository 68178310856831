interface ListItem {
	value: number;
	degrees: string;
	ist: string;
	headline: string;
}

export interface WidgetHMIProps {
	cardLink: string;
	offlineText: string;
	noConnectionIcon: string;
	systemData: Array<ListItem> | any;
}

export const WidgetHMIData: WidgetHMIProps = {
	cardLink: '#',
	offlineText: 'Live-Daten können nicht angezeigt werden',
	noConnectionIcon: 'no-connection',
	systemData: [
		{
			value: 52,
			degrees: '&#x2103',
			ist: 'IST',
			headline: 'Warmwasser',
			icon: 'screw-outlined',
		},
		{
			value: 55,
			degrees: '&#x2103',
			ist: 'IST',
			headline: 'Warmwasser',
			icon: 'screw-outlined',
		},
		{
			value: 65,
			degrees: '&#x2103',
			ist: 'IST',
			headline: 'Warmwasser',
			icon: 'screw-outlined',
		},
		{
			value: 67,
			degrees: '&#x2103',
			ist: 'IST',
			headline: 'Warmwasser',
			icon: 'screw-outlined',
		},
	],
};
