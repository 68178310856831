import { Button, Theme, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SuccessStepProps } from 'components/ModalAddMaintenance/ModalAddMaintenance.d';

const NextButton = withStyles((theme: Theme) => ({
    root: {
      color: 'white',
      backgroundColor: '#212d40',
      borderRadius: 0,
      margin: 16,
      textTransform: 'none',
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      padding: '10px 32px',
      '&:hover': {
        backgroundColor: '#212d40',
      },
    },
  }))(Button);
  
  const PrevButton = withStyles((theme: Theme) => ({
    root: {
      color: '#212d40',
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: 'solid 1px #212d40',
      margin: 16,
      textTransform: 'none',
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      padding: '10px 16px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
  }))(Button);

function SuccessStep(props: SuccessStepProps): JSX.Element {
    const { maintenanceCount, setActiveStep } = props;

    const { t } = useTranslation();

    const handleAddMore = () => {
      setActiveStep(0);
    }

    return (
      <div className='m-success-step' id='success-step'>
        <div className="confetti-container">
				<div className="circle"></div>
				<div className="circle2"></div>
				<div className="circle3"></div>
				<div className="circle4"></div>
				<div className="circle5"></div>
				<div className="circle6"></div>
				<div className="circle7"></div>
				<div className="circle8"></div>
				<div className="circle9"></div>
				<div className="circle10"></div>
				<div className="circle11"></div>
				<div className="circle12"></div>
				<div className="circle13"></div>
				<div className="circle14"></div>
				<div className="circle15"></div>
				<div className="circle16"></div>
				<div className="circle17"></div>
				<div className="five-pointed-star"></div>
			</div>
      <h2 className="headline">{t('Maintenance has been added')}</h2>
			<h3 className="earned-points-subheadline">{maintenanceCount} {t('maintenances have been added successfully')}</h3>
            <div className="buttons-container">
                <PrevButton variant="contained" color="primary" onClick={handleAddMore}>
                    {t('Add more maintenance')}
                </PrevButton>
                <NextButton type='submit' variant="contained" color="primary">
                    {t('To overview')}
                </NextButton>
            </div>
        </div>
    );
}
export default SuccessStep;
