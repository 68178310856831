export default {
	prod: {
		backbone: 'https://api.management.device.svc.wolf.eu/v1',
		pimcore: 'https://www.wolf.eu/mywolf/api/system-management/v1',
		pim: 'https://pim-pimcore.aim.internal.svc.wolf.eu',
		iot: 'https://iot.systems.device.svc.wolf.eu/v1',
		attachments: 'https://api.attachments.device.svc.wolf.eu/v1',
		sap: 'https://serials.sap.svc.wolf.eu/v1',
		warranty: 'https://api.warranty.svc.wolf.eu',
		customers: 'https://api.customers.svc.wolf.eu/v1',
	},
	stage: {
		backbone: 'https://api.management.device.staging.svc.wolf.eu/v1',
		pimcore: 'https://wolfeu.website.staging.svc.wolf.eu/mywolf/api/system-management/v1',
		pim: 'https://pim-pimcore.aim.internal.staging.svc.wolf.eu',
		iot: 'https://iot.systems.device.staging.svc.wolf.eu/v1',
		attachments: 'https://api.attachments.device.staging.svc.wolf.eu/v1',
		sap: 'https://serials.sap.staging.svc.wolf.eu/v1',
		warranty: 'https://api.warranty.staging.svc.wolf.eu',
		customers: 'https://api.customers.staging.svc.wolf.eu/v1',
	},
	dev_test: {
		backbone: 'https://api.management.device.internal.test.svc.wolf.eu/v1',
		pimcore: 'https://wolfeu.website.internal.test.svc.wolf.eu/mywolf/api/system-management/v1',
		pim: 'https://pim-pimcore.aim.internal.test.svc.wolf.eu',
		iot: 'https://iot.systems.device.internal.test.svc.wolf.eu/v1',
		attachments: 'https://api.attachments.device.internal.test.svc.wolf.eu/v1',
		sap: 'https://serials.sap.internal.test.svc.wolf.eu/v1',
		warranty: 'https://api.warranty.internal.test.svc.wolf.eu',
		customers: 'https://api.customers.internal.test.svc.wolf.eu/v1',
	},
	development: {
		backbone: 'https://api.management.device.internal.test.svc.wolf.eu/v1',
		pimcore: 'https://wolfeu.website.internal.test.svc.wolf.eu/mywolf/api/system-management/v1',
		pim: 'https://pimcore.aim.internal.test.svc.wolf.eu',
		iot: 'https://iot.systems.device.internal.test.svc.wolf.eu/v1',
		attachments: 'https://api.attachments.device.internal.test.svc.wolf.eu/v1',
		sap: 'https://serials.sap.internal.test.svc.wolf.eu/v1',
		warranty: 'https://api.warranty.internal.test.svc.wolf.eu',
		customers: 'https://api.customers.internal.test.svc.wolf.eu/v1',
	},
};
