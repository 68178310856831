import React from 'react';
import { AtomToggleProps } from 'components/ModalAddMaintenance/ModalAddMaintenance.d';

function AtomToggleReact({ items, value: toggle, setToggle }: AtomToggleProps): JSX.Element {

    const onToggle = (value: boolean) => {
        if (setToggle) setToggle(value);
    };

    if(!(items?.[0] && items?.[1]))
        return <></>;
    return <div className="formGroupToggle">
        <div data-ref="defaultWrapper" className={`uk-toggle-container ${toggle ? "active" : "passive"} ${items[0].length > 4 ? "wide" : ""}`}>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    onToggle(true)
                }}
                className={`uk-toggle__button ${items[0].length > 4 ? "wide" : ""} ${toggle ? "active" : "passive"}`}
            >
                {items[0]}
            </button>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    onToggle(false)
                }}
                className={`uk-toggle__button ${items[0].length > 4 ? "wide" : ""} ${!toggle ? "active" : "passive"}`}
            >
                {items[1]}
            </button>
        </div>
    </div>
}
export default AtomToggleReact;