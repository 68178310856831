import React, { useRef } from 'react';
import { useEffect } from 'react';
import { MoleculeModalFormProps } from './m-modal-form.data';


function ModalForm(props: MoleculeModalFormProps): JSX.Element {
    const { formUrl, handleFormSubmit } = props;
    const refModalContent = useRef<HTMLDivElement>(null);

    const handleSubmit = handleFormSubmit ? handleFormSubmit : async (e: SubmitEvent) => {
        e && e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);

        const response = await fetch(formUrl.toString(), {
            method: 'post',
            body: formData,
            credentials: 'same-origin'
        });

        const data = await response.json();

        if (refModalContent.current && data.data?.content) {
            refModalContent.current.innerHTML = data.data.content;
        }
    }

    useEffect(() => {
        const form = refModalContent.current ? refModalContent.current.querySelectorAll<'form'>('form')[0] : null;
        if (form) {
            form.onsubmit = handleSubmit;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id={props.id} className="m-modal-form uk-modal" data-uk-modal="container:false">
            <div className="uk-modal-dialog uk-margin-auto-vertical">
                <div className="uk-modal-header">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <h4 className="uk-modal-title">{props.headline}</h4>
                </div>
                <div ref={refModalContent}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ModalForm;