import React, { useRef, useState } from 'react';
import { AtomInputLabelProps } from './InputLabel.d';

function InputLabel(props: AtomInputLabelProps): JSX.Element {
	const { text, tooltip, thin } = props;

	const [showTooltip, setShowTooltip] = useState(false);
	const anchorRef = useRef<HTMLButtonElement | null>(null);

	return (
		<>
			<div className="a-input-label input-label-wrapper">
				<label className={`input-label ${thin ? 'input-label-thin' : ''}`}>{text}</label>

				{tooltip ? (
					<span
						className="info-tooltip"
						ref={anchorRef}
						onMouseLeave={() => setShowTooltip(false)}
						onMouseEnter={() => setShowTooltip(true)}>
						i
					</span>
				) : null}
			</div>
		</>
	);
}
export default InputLabel;
