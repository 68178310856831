import { DeviceOwner } from 'types/DeviceOwner';
import { LocationAddress } from 'types/LocationAddress';
export interface WidgetCustomerDetailsProps {
	translations: Record<string, string>;
	customer: DeviceOwner;
	deviceAddress?: LocationAddress;
}

export const WidgetCustomerDetailsData: WidgetCustomerDetailsProps = {
	customer: {
		salutation: 'Herr',
		firstname: 'Manfred',
		lastname: 'Mustermann',
		address: {
			street: 'Musterstraße',
			house_number: '1',
			city: 'München',
			zip_code: '80335',
		},
		company: 'Musterfirma GmbH',
		phone: '+49 1234 56789123',
		email: 'manfred.mustermann@beispiel.com',
	},
	deviceAddress: {
		street: 'Axenstraße',
		house_number: '11',
		city: 'Berlin',
		zip_code: '13089',
	},
	translations: {
		headline: 'Kundendaten',
		editLink: 'bearbeiten',
		location: 'Anlageort',
		emptyMessage: 'Daten ergänzen und 5 Jahre Garantie hinzufügen',
	},
};
