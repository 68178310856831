export const colors = {
	primary: '#212d40',
	secondary: '#6b718b',
	accent: '#81adc8',
	success: '#00af00',
	successLight: '#C7EDC7',
	warning: '#d36e02',
	danger: '#bb0000',
	text: '#11151c',
	'text-grey': '#6B718B',
	'wolf-red': '#e10000',
	'dark-blue': '#212d40',
	'light-blue': '#81adc8',
	'true-white': '#fff',
	'wolf-black': '#11151c',
	'true-black': '#000',
	'dark-grey': '#6a6e70',
	'silver-grey': '#d2d2d2',
	'light-grey': '#f7f7f7',
	'stroke-grey': '#d2d2d2',
	'lighter-grey': '#f5f5f5',
	'darker-grey': '#272734',
	'red-30-white': '#ea4c4c',
	'red-50-white': '#f07f7f',
	'red-70-white': '#f6b2b2',
	'red-90-white': '#fce5e5',
	'dark-blue-10-white': '#404652',
	'dark-blue-30-white': '#636b79',
	'dark-blue-50-white': '#8f959f',
	'dark-blue-70-white': '#bcbfc5',
	'dark-blue-90-white': '#e8e9eb',
	'light-blue-30-white': '#a6c5d8',
	'light-blue-50-white': '#c0d6e3',
	'light-blue-70-white': '#d9e6ee',
	'light-blue-90-white': '#f2f7fa',
	'light-blue-table': '#F6F7FB',
	'red-80-black': '#ea4c4c',
	'red-50-black': '#f07f7f',
	'dark-blue-50-black': '#8f959f',
	'light-blue-80-black': '#a6c5d8',
	'light-blue-50-black': '#c0d6e3',
	'red-hover': '#b70406',
};

export const palette = {
	primary: {
		light: colors['red-50-white'],
		main: colors.primary,
		dark: colors['red-hover'],
	},
	secondary: {
		light: colors.accent,
		main: colors.secondary,
	},
	error: {
		light: colors['red-70-white'],
		main: colors.danger,
		dark: colors['red-hover'],
	},
	warning: {
		main: colors.warning,
	},
	info: {
		main: colors['light-blue'],
	},
	success: {
		light: colors.successLight,
		main: colors.success,
	},
	background: {
		default: colors['true-white'],
	},
	common: {
		white: colors['true-white'],
		black: colors['wolf-black'],
	},
};
