import React from 'react';
import { AtomTextLinkProps } from './TextLink.d';

function TextLink(props: AtomTextLinkProps): JSX.Element {
    const { text, link, disabled, type, icon, onClick } = props;

    return (
        <div className='a-text-link'>
            {
                {
                    'default': <a className={`text-link ${ disabled ? "disabled" : "" }`} href={link} onClick={onClick}>{text}</a>,
                    'underlined_small': <a className={`text-link underlined small ${ disabled ? "disabled" : "" }`} href={link} onClick={onClick}>{text}</a>,
                    'underlined_medium': <a className={`text-link underlined ${ disabled ? "disabled" : "" }`} href={link} onClick={onClick}>{text}</a>,
                    'icon': 
                        <>
                            <img src={icon} className={`icon ${ disabled ? "icon-disabled" : "" }`} />
                            <a className={`text-link ${ disabled ? "disabled" : "" }`} href={link} onClick={onClick}>{text}</a>
                        </>
                }[type]
            }
        </div>
    );
}
export default TextLink;
