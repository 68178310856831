import React from 'react';
import { CustomerData as CustomerDataProps } from 'components/CustomerData/CustomerData.d';
import { ApprovalData } from 'components/Approval/Approval.d';
import { SystemManagementModalProps } from 'components/StepperModal/StepperModal.d';
import CustomerData from 'components/CustomerData';
import ApprovalStep from 'components/Approval';

export const AddDeviceModalData: SystemManagementModalProps = {
	stepper: {
		steps: ['Geräte', 'Zustimmung'],
		activeStep: 1,
		failedSteps: [],
	},
    stepContent: [
        <CustomerData key='step1' {...CustomerDataProps} />,
        <ApprovalStep key='step2' {...ApprovalData} />
    ],
	open: false,
};
