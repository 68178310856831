import { WidgetHMIProps, WidgetHMIData } from 'components/WidgetHMI/WidgetHMI.d';
import { WidgetCustomerDetailsData, WidgetCustomerDetailsProps } from 'components/WidgetCustomerDetails/WidgetCustomerDetails.d';
import { WidgetDeviceViewProps } from 'components/WidgetDeviceView/WidgetDeviceView.d';
import { StatusBarProps, StatusBar } from 'components/StatusBar/StatusBar.d';
import { HeaderProps } from 'components/Header/Header.d';
import { HeaderDetailViewData } from 'components/Header/Header.data';

export interface SystemDetailsProps {
	// apiUrls: Record<Endpoints, EndpointsProps>;
	// currentEventsProps: MoleculeWidgetCurrentEventsProps;
	hmiDataProps: WidgetHMIProps;
	customerDetailsProps: WidgetCustomerDetailsProps;
	statusBarProps: StatusBarProps;
	header: HeaderProps;
	routes: any;
}

export const SystemDetailsData: SystemDetailsProps = {
	hmiDataProps: {
		...WidgetHMIData,
	},
	customerDetailsProps: {
		...WidgetCustomerDetailsData,
	},
	statusBarProps: {
		...StatusBar,
	},
	header: {
		...HeaderDetailViewData,
		background: 'linear-gradient(198deg, rgba(68,89,112,1) 9%, rgba(142,172,198,1) 82%)',
		showBackButton: true,
	},
	routes: {
		base: '#',
		list: '#',
		detail: '#',
	},
};

export const MyOrganismSystemDetailsTranslations = {
	Notes: 'Notizen',
	'Technical Details': 'Technische Details',
};
