import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Input from 'components/Input';
import { useStyles } from '../hooks/useStyles';
import { AddCustomerFormData } from '../CustomerData.d';
import Dropdown from 'components/Dropdown';

export interface CustomerForm {
	customerData?: AddCustomerFormData;
	handleChange?: () => void;
	err: any;
}

function CustomerForm({ customerData, handleChange, err }: CustomerForm): JSX.Element {
	const classes = useStyles();
	const { t } = useTranslation();
	const { salutation, firstname, lastname, phone, email } = customerData ?? {};

	return (
		<Grid container className={classes.borderedRow}>
			<Grid item xs={12} md={12} className={classes.rowTitle}>
				<span>{t('Customer Data')}</span>
			</Grid>
			<Grid item xs={12} sm={12} md={2} className={classes.formColumn}>
				<div>
					<Dropdown
						name="salutation"
						label={t('Salutation').toString()}
						placeholder={t('Salutation').toString()}
						defaultValue={customerData && salutation}
						items={[
							{ value: 'Herr', text: 'Herr' },
							{ value: 'Frau', text: 'Frau' },
							{ value: 'keine Angabe', text: 'keine Angabe' },
						]}
						error={err?.salutation}
						invalid={!!err?.salutation}
					/>
				</div>
			</Grid>
			<Grid item xs={12} sm={6} md={2} className={classes.formColumn}>
				<div>
					<Input
						type="label"
						id="firstName"
						name="firstname"
						value={customerData && firstname}
						onChange={handleChange}
						label={t('First Name').toString()}
						placeholder={t('First Name').toString()}
						error={err?.firstname}
						invalid={!!err?.firstname}
					/>
				</div>
			</Grid>

			<Grid item xs={12} sm={6} md={2} className={classes.formColumn}>
				<Input
					type="label"
					id="lastName"
					name="lastname"
					value={customerData && lastname}
					onChange={handleChange}
					label={t('Last Name').toString()}
					placeholder={t('Last Name').toString()}
					error={err?.lastname}
					invalid={!!err?.lastname}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
				<Input
					type="label"
					id="phone"
					name="phone"
					value={customerData && phone}
					onChange={handleChange}
					label={t('Phone').toString()}
					placeholder={t('Phone').toString()}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={3} className={classes.formColumn}>
				<Input
					type={'label'}
					id="email"
					name="email"
					value={customerData && email}
					onChange={handleChange}
					label={t('Email').toString()}
					placeholder={t('Email').toString()}
					error={err?.email}
					invalid={!!err?.email}
					required={!!email}
				/>
			</Grid>
		</Grid>
	);
}
export default CustomerForm;
