import React from 'react';
import { TooltipProps, TooltipButton } from './Tooltip.d';

function Tooltip(props: TooltipProps): JSX.Element {
	const { arrowPosition, text, type, image, wide, buttons, ...attributes } = props;

	return (
		<div className="a-tooltip" {...attributes}>
			{
				{
					info: (
						<div className={`tooltip ${wide ? 'tooltip-wide' : ''} ${arrowPosition == 'bottom' ? 'tooltip-bottom' : 'tooltip-top'} `}>
							{text}
						</div>
					),
					edit: (
						<div
							className={`tooltip ${wide ? 'tooltip-wide' : ''} ${
								arrowPosition == 'bottom' ? 'tooltip-bottom' : 'tooltip-top'
							} tooltip-edit`}>
							{text}
						</div>
					),
					help: (
						<div
							className={`tooltip tooltip-help ${wide ? 'tooltip-wide' : ''} ${
								arrowPosition == 'bottom' ? 'tooltip-bottom' : 'tooltip-top'
							}`}>
							{text}
							{image ? <img src={image} /> : null}
						</div>
					),
					thumb: (
						<div
							className={`tooltip tooltip-info ${wide ? 'tooltip-wide' : ''} ${
								arrowPosition == 'bottom' ? 'tooltip-bottom' : 'tooltip-top'
							}`}>
							<div className="center">{image ? <img src={image} /> : null}</div>
							<div>{text}</div>
						</div>
					),
					menu: (
						<div
							className={`tooltip ${wide ? 'tooltip-wide' : ''} ${
								arrowPosition == 'bottom' ? 'tooltip-bottom' : 'tooltip-top'
							} tooltip-menu`}>
							{buttons &&
								buttons.map((button: TooltipButton, index: number) => {
									return (
										<div className="" key={index} onClick={button.onClick}>
											{button.name}
										</div>
									);
								})}
						</div>
					),
				}[type]
			}
		</div>
	);
}
export default Tooltip;
