import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Card } from '@material-ui/core';
import { PlusFilled } from 'assets/icons';
import { DeviceTable as DeviceMgmtList, Header as SystemHeader, StatusFilters, FloatingButton, ConfirmationDialog } from 'components';
import { HeaderProps } from 'components/types';
import ROUTES from 'constants/routes';
import backboneApi from 'store/modules/backbone';
import { headerSelector, initialHeaderSelector } from 'store/modules/heatingdevice/selectors';
import showToast from 'services/utils/toaster';
import ModalAddSystem from 'components/ModalAddSystem';

function SystemList(): JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [filters, setFilters] = useSearchParams('expand=components');

	const headerProps = useSelector(headerSelector);

	useEffect(() => {
		if (!filters.get('expand')) {
			setFilters(
				(prev) => {
					prev.set('expand', 'components');
					return prev;
				},
				{ replace: true },
			);
		}
	}, [filters, setFilters]);

	const { data, isLoading } = backboneApi.useGetSystemsQuery(decodeURIComponent(filters.toString()));
	const [deleteSystem, _] = backboneApi.useDeleteSystemMutation();

	const [actionRow, setActionRow] = useState<string | null>(null);

	const [openAddSystemModal, setOpenAddSystemModal] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [deletingIds, setDeletingIds] = useState<string[]>([]);

	const quickFilterKeys = useMemo(() => ['is_failure', 'is_maintenance', 'status'], []);

	const setQuickFiltersData = (quickFiltersData) => {
		setFilters(
			(prev) => {
				Object.keys(Object.fromEntries(prev)).forEach((key) => {
					if (!quickFiltersData[key] && quickFilterKeys.includes(key)) {
						prev.delete(key);
					}
				});

				Object.keys(quickFiltersData).forEach((filterKey) => {
					prev.set(filterKey, quickFiltersData[filterKey]);
				});
				return prev;
			},
			{ replace: true },
		);
	};

	const onMassDelete = (e, rowSelection) => {
		setDeletingIds(Object.keys(rowSelection).map((item) => rowSelection[item]));
		setOpenDeleteDialog(true);
	};

	const handleSortBy = useCallback(
		async (sortBy: Array<string>) => {
			if (sortBy.length) {
				setFilters(
					(prev) => {
						prev.set('sort', sortBy.join(','));
						return prev;
					},
					{ replace: true },
				);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters],
	);

	const handleSearch = (searchBy: string, search: string) => {
		if (!search) {
			setFilters((prev) => {
				prev.delete(searchBy);
				return prev;
			});
			return;
		}
		setFilters((prev) => {
			prev.set(searchBy, search);
			return prev;
		});
	};

	return (
		<div className="o-system-list">
			<SystemHeader {...(headerProps as HeaderProps)} />
			<Container className="system-list-container">
				<StatusFilters onFilter={setQuickFiltersData} />
				<Card className="main-system-list-card">
					<Grid container>
						<Grid item xs={12}>
							<DeviceMgmtList
								{...{
									endpointUrl: '',
									allDevicesUrl: '',
									onMoreClick: (ev, row) => {
										ev && ev.stopPropagation();
										setActionRow(row.original.uuid as string);
									},
									onRowClick: (_, row) => {
										navigate(`${ROUTES.DETAIL}${row.original.uuid}`);
									},
									data: data,
									headerLinkUrl: '',
									onSortBy: handleSortBy,
									textLinkType: 'underlined_medium',
									showColumns: {
										status: true,
										name: true,
										type_description: true,
										serial: true,
										owner: true,
										location: true,
									},
									buttons: [
										{
											name: t('Delete'),
											onClick: (e: React.MouseEvent<HTMLDivElement>): void => {
												e && e.stopPropagation();
												setDeletingIds([actionRow!]);
												setOpenDeleteDialog(true);
											},
										},
									],
									columnOrder: ['selection', 'status', 'expander', 'name', 'type_description', 'serial', 'owner', 'address'],
									withSearch: true,
									withSelection: true,
									withActions: true,
									withExpander: true,
									withHeader: true,
									onMassDelete: onMassDelete,
									defaultSortBy: filters.get('sort'),
									isLoading,
									onSearch: handleSearch,
								}}></DeviceMgmtList>
						</Grid>
					</Grid>
				</Card>
			</Container>
			<FloatingButton
				type="default"
				text={t('Add system')}
				icon={<PlusFilled />}
				onClick={() => {
					setOpenAddSystemModal(true);
				}}
			/>
			<ModalAddSystem open={openAddSystemModal} handleClose={() => setOpenAddSystemModal(false)} />
			<ConfirmationDialog
				open={openDeleteDialog}
				title="Anlage löschen"
				content="Möchten Sie die ausgewählten Anlagen wirklich löschen?"
				onCancel={() => setOpenDeleteDialog(false)}
				onConfirm={() => {
					deletingIds.map(deleteSystem);
					setDeletingIds([]);
					showToast.success(t('System successfully deleted'));
				}}
			/>
		</div>
	);
}

export default SystemList;
