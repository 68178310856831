import { IComponent } from 'types/Component';

export interface StatusBarProps {
	onClickWarranty: () => void;
	onPerformMaintenance: () => void;
	components: IComponent[];
}

export const StatusBar: StatusBarProps = {
	onClickWarranty: () => console.log('clicked add warranty'),
	onPerformMaintenance: () => console.log('clicked perform maintenance'),
	components: [],
};
