import React, { memo } from 'react';
import SystemMngModal from 'components/StepperModal';
import { SystemManagementModalProps } from 'components/StepperModal/StepperModal.d';

function ModalAddWarranty(props: SystemManagementModalProps): JSX.Element {
    const { open, stepper, stepContent, handleClose } = props;

    return (
        <div className='m-add-device-modal'>
            <SystemMngModal open={open} stepper={stepper} stepContent={stepContent} handleClose={handleClose}></SystemMngModal>
        </div>
    );
}

export default memo(ModalAddWarranty);