import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';
import { filtersSelector } from 'store/modules/heatingdevice/selectors';
import { HeatingDeviceStatusEnum } from 'types/HeatingDeviceStatusEnum';
import { AttentionFilled, RevisionOutlined, NoConnection, CheckFilledCircle } from 'assets/icons';
import backboneApi from 'store/modules/backbone';
import { Skeleton } from '@material-ui/lab';
import ROUTES from 'constants/routes';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';

const StatusFilters = (props: { onFilter: (any) => void }) => {
	const selectedFilters = useSelector(filtersSelector);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { data: deviceStatistics, isLoading } = backboneApi.useGetDeviceStatisticsQuery('', {});

	const [statusFilters, setStatusFilters] = useState<Record<string, Record<string, unknown>>>({
		FAILURE: {
			name: 'Störungen',
			icon: <AttentionFilled className="filter-icon-bg" style={{ color: '#eb525a', backgroundColor: 'rgba(235, 82, 90, .2)' }} />,
			iconSelected: <AttentionFilled className="filter-icon-bg" style={{ color: '#ffffff', backgroundColor: 'rgba(255, 255, 255, .4)' }} />,
			color: '#eb525a',
			counter: 0,
			filter: { is_failure: true },
			selected: false,
		},
		MAINTENANCE: {
			name: 'Ausstehende Wartungen',
			icon: <RevisionOutlined className="filter-icon-bg" style={{ color: '#00c5b9', backgroundColor: 'rgba(0, 197, 185, .2)' }} />,
			iconSelected: <RevisionOutlined className="filter-icon-bg" style={{ color: '#ffffff', backgroundColor: 'rgba(255, 255, 255, .4)' }} />,
			color: '#00c5b9',
			counter: 0,
			filter: { is_maintenance: true },
			selected: false,
		},
		OFFLINE: {
			name: 'Anlagen Offline',
			icon: <NoConnection className="filter-icon-bg" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
			iconSelected: <NoConnection className="filter-icon-bg" style={{ color: '#ffffff', backgroundColor: 'rgba(255, 255, 255, .4)' }} />,
			color: '#a2a2a2',
			counter: 0,
			filter: { status: ['OFFLINE', 'UNKNOWN'] },
			selected: false,
		},
		ONLINE: {
			name: 'Anlagen in Betrieb',
			icon: <CheckFilledCircle className="filter-icon-bg" style={{ color: '#74b959', backgroundColor: 'rgba(116, 185, 89, .2)' }} />,
			iconSelected: <CheckFilledCircle className="filter-icon-bg" style={{ color: '#ffffff', backgroundColor: 'rgba(255, 255, 255, .4)' }} />,
			color: '#74b959',
			counter: 0,
			filter: { status: ['ONLINE'] },
			selected: false,
		},
	});

	useEffect(() => {
		setStatusFilters((prev) => {
			return {
				...prev,
				FAILURE: {
					...prev.FAILURE,
					selected: selectedFilters.is_failure || false,
					counter: deviceStatistics?.failure || 0,
				},
				MAINTENANCE: {
					...prev.MAINTENANCE,
					selected: selectedFilters.is_maintenance || false,
					counter: deviceStatistics?.maintenance || 0,
				},
				OFFLINE: {
					...prev.OFFLINE,
					selected:
						selectedFilters.status?.includes(HeatingDeviceStatusEnum.OFFLINE) ||
						selectedFilters.status?.includes(HeatingDeviceStatusEnum.UNKNOWN) ||
						false,
					counter: deviceStatistics?.offline + deviceStatistics?.unknown || 0,
				},
				ONLINE: {
					...prev.ONLINE,
					selected: selectedFilters.status?.includes(HeatingDeviceStatusEnum.ONLINE) || false,
					counter: deviceStatistics?.online || 0,
				},
			};
		});
	}, [selectedFilters, deviceStatistics]);

	const handleSelectedStatus = (status) => {
		setStatusFilters((prev) => {
			const newStatusFilters = {
				...prev,
				[status]: {
					...prev[status],
					selected: !prev[status].selected,
				},
			};

			Object.keys(newStatusFilters).map((key) => {
				if (key !== status) {
					newStatusFilters[key].selected = false;
				}
			});

			let filters: { is_failure?: boolean; is_maintenance?: boolean; status?: string[] } = {};
			Object.keys(newStatusFilters)
				.filter((key) => newStatusFilters[key].selected)
				.map((key) => {
					if (newStatusFilters[key].filter.status) {
						if (!filters.status) filters.status = [];
						filters.status = [...filters.status, ...newStatusFilters[key].filter.status];
					} else {
						filters = { ...filters, ...newStatusFilters[key].filter };
					}
				});
			props.onFilter(filters);
			return newStatusFilters;
		});
	};

	return (
		<div className="m-system-management-quickFilters">
			<div className="wrapContainer">
				<Grid container spacing={3}>
					{Object.keys(statusFilters).map((key) => (
						<Grid item xs={12} sm={6} md={4} lg={3} key={key}>
							<Card
								style={{ width: '100%', height: '174px', borderRadius: '12px' }}
								className="quickFilter"
								onClick={() => {
									handleSelectedStatus(key);
								}}>
								<Grid
									container
									className={statusFilters[key].selected ? 'selected' : ''}
									style={{ alignItems: 'center', flexWrap: 'initial' }}>
									<Grid item className="leftSide" style={{ backgroundColor: statusFilters[key].color as string }} />
									<Grid
										item
										className="rightSide"
										style={{ backgroundColor: statusFilters[key].selected ? (statusFilters[key].color as string) : '#fff' }}>
										{!isLoading ? (
											<div>
												<div className="statusContent">
													<div className="icon rounded-corners">
														<>
															{statusFilters[key].selected
																? (statusFilters[key].iconSelected as SVGAElement)
																: (statusFilters[key].icon as HTMLOrSVGElement)}
														</>
													</div>
													<div className="name">{statusFilters[key]?.name as string}</div>
													{(statusFilters[key].selected as HTMLOrSVGElement) && (
														<button
															className="remove-filter-btn"
															onClick={(event) => {
																event.stopPropagation();
																dispatch(heatingDevicePortalActions.clearFilters())
																navigate(ROUTES.HOME);
															}}>
															X
														</button>
													)}
													<div className="counter">{statusFilters[key]?.counter as string}</div>
												</div>
											</div>
										) : (
											<div className="statusContent">
												<div className="skeleton-icon">
													<Skeleton animation="wave" height={'4em'} />
												</div>
												<div className="skeleton-name">
													<Skeleton animation="wave" />
													<Skeleton animation="wave" />
												</div>
											</div>
										)}
									</Grid>
								</Grid>
							</Card>
						</Grid>
					))}
				</Grid>
			</div>
		</div>
	);
};

export default memo(StatusFilters);
