import { SystemManagementModalProps } from 'components/StepperModal/StepperModal.d';

export const AddDeviceModaData: SystemManagementModalProps = {
	stepper: {
		steps: ['Geräte'],
		activeStep: 0,
		failedSteps: [],
	},
	stepContent: [],
	open: false,
};
