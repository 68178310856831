import { WrenchFilled } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { WidgetMaintenancesProps } from './WidgetMaintenances.d';
import { Maintenance } from 'types/Maintenance';
import DeviceImage from './components/DeviceImage';
import DeviceTitle from './components/DeviceTitle';
import Filters from './components/Filters';
import ModalMaintenanceDetails from './components/ModalMaintenanceDetails';

function WidgetNotes(props: WidgetMaintenancesProps): JSX.Element {
	const { maintenances } = props;

	const [list, setList] = useState<Array<Maintenance>>((maintenances?.items || []) as Array<Maintenance>);
	const [filteredList, setFilteredList] = useState<Array<Maintenance>>([]);
	const [filter, setFilter] = useState<string>('');
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [selectedMaintenance, setSelectedMaintenance] = useState<Maintenance | null>(null);

	useEffect(() => {
		setList((maintenances?.items || []) as Array<Maintenance>);
	}, [maintenances]);

	useEffect(() => {
		if (filter === '') {
			setFilteredList(list);
		} else {
			setFilteredList(list.filter((item) => item.number === filter));
		}
	}, [filter, list]);

	return (
		<>
			<Filters serials={list.map((item) => item.number)} setFilter={(string) => setFilter(string)} />
			<div className="m-maintenances-widget" data-uk-slider="finite: true">
				<ul className="uk-slider-items uk-grid">
					{filteredList &&
						filteredList.map((maintenance: Maintenance, index: number) => (
							<li key={index}>
								<div
									className="wrapper"
									onClick={() => {
										setOpenDetails(true);
										setSelectedMaintenance(maintenance);
									}}>
									<div className="top">
										<WrenchFilled className="icon" />
										<span className="date-creator">{`${new Date(maintenance.creation_date).toLocaleDateString('de-DE', {
											day: '2-digit',
											month: '2-digit',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})}`}</span>
									</div>
									<DeviceTitle sku={maintenance.number} />
									<span className="serial-number">SN: {maintenance.number}</span>
									<DeviceImage sku={maintenance.number} />
								</div>
							</li>
						))}
				</ul>
				<ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
			</div>
			<ModalMaintenanceDetails open={openDetails} handleClose={() => setOpenDetails(false)} maintenance={selectedMaintenance} />
		</>
	);
}

export default WidgetNotes;
