import { Button, Theme, withStyles } from '@material-ui/core';

const NextButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

export default NextButton;
