import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { SystemManagementModalProps } from './StepperModal.d';
import LargeProgressBar from '../ProgressBarLarge/ProgressBarLarge';
import Header from './components/Header';
import SuccessStep from './components/SuccessStepTemp';
import { Close } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { heatingDevicePortalActions } from 'store/modules/heatingdevice/reducer';
import { systemDevicesSelector } from 'store/modules/heatingdevice/selectors';
import { parse, formatISO } from 'date-fns';
import { AddComponentFormData } from 'components/AddComponent/AddComponent.d';
import { AddCustomerFormData } from 'components/CustomerData/CustomerData.d';
import showToast from 'services/utils/toaster';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 16,
			backgroundColor: '#f8f8fa',
		},
		closeButton: {
			position: 'absolute',
			color: '#6b718b',
			right: 16,
			top: 24,
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close style={{ width: 16, color: '#6b718b' }} />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const NextButton = withStyles((theme: Theme) => ({
	root: {
		color: 'white',
		backgroundColor: '#212d40',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 32px',
		'&:hover': {
			backgroundColor: '#212d40',
		},
	},
}))(Button);

const PrevButton = withStyles((theme: Theme) => ({
	root: {
		color: '#6b718b',
		backgroundColor: 'transparent',
		borderRadius: 0,
		margin: 16,
		textTransform: 'none',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 500,
		padding: '10px 16px',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}))(Button);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		backgroundColor: '#f8f8fa',
	},
}))(MuiDialogActions);

function SystemMngModal(props: SystemManagementModalProps): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const { stepper, stepContent, withTitle, stepNames, prefilledData } = props;
	const systemDevices = useSelector(systemDevicesSelector);
	const { t } = useTranslation();

	const [activeStep, setActiveStep] = useState(0);
	const [failedSteps, setFailedSteps] = useState<Array<number>>([]);
	const [systemName, setSystemName] = useState('');
	const [success, setSuccess] = useState(false);
	const [nextPressed, setNextPressed] = useState<boolean>(false);
	const [stepInputData, setStepInputData] = useState<Array<Array<AddComponentFormData | AddCustomerFormData>>>([]);

	const formRef = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		setStepInputData([prefilledData]);
	}, [prefilledData]);

	const handleNext = (event?: React.MouseEvent) => {
		event && event.preventDefault();
		if (!(formRef && formRef.current)) {
			return;
		}

		const formData = new FormData(formRef.current);
		const formDataArray = Array.from(formData.entries());
		const uniqueKeys = [...new Set(formDataArray.map(([key]) => key))];
		const objects = formDataArray.reduce((acc: Array<AddComponentFormData | AddCustomerFormData>, [key, value], index) => {
			const objectIndex = Math.floor(index / uniqueKeys.length);
			if (!acc[objectIndex]) {
				acc[objectIndex] = {} as AddComponentFormData | AddCustomerFormData;
			}
			acc[objectIndex][key] = value;
			if (key.toLowerCase().indexOf('date') !== -1) {
				acc[objectIndex][key] = formatISO(parse(acc[objectIndex][key], 'dd. MMM yyyy', new Date())).split('T')[0];
			}
			return acc;
		}, []);
		setStepInputData((current) => {
			const updatedStepInputData = [...current];
			updatedStepInputData[activeStep] = objects;
			return updatedStepInputData;
		});
		setNextPressed(true);
	};

	const handlePrev = () => {
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const handleClose = () => {
		dispatch(heatingDevicePortalActions.clearSystemDevices());
		setStepInputData([]);
		setActiveStep(0);
		setFailedSteps([]);
		setSystemName('');
		setSuccess(false);
		props.handleClose?.();
	};

	const parseStepData = (stepInputData: Array<Array<AddComponentFormData | AddCustomerFormData>>) => {
		const parsedData = {};
		stepInputData.forEach((stepData, index) => {
			const stepKey = stepNames && stepNames[index] ? stepNames[index] : `step${index + 1}`;
			parsedData[stepKey] = stepData;
		});
		parsedData['name'] = systemName;
		return parsedData;
	};

	const handleFinish = useCallback(async () => {
		let response;
		try {
			response = await props.handleFinish?.(parseStepData(stepInputData));
		} catch (error) {
			showToast.error(error.data.message);
		}
		if (response && !response.errors) setSuccess(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepInputData]);

	const handleValidation = useCallback(
		(isValid: boolean) => {
			setNextPressed(false);
			if (isValid) {
				if (activeStep < stepper.steps.length - 1) {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				} else {
					if (!systemName && withTitle) {
						showToast.error(`${t('Please enter a name for the system')}.`);
						return;
					}
					handleFinish();
				}
				setFailedSteps((current) => current.filter((step) => step !== activeStep));
			} else {
				setFailedSteps((current) => [...current, activeStep]);
			}
		},
		[activeStep, stepper.steps.length, systemName, handleFinish, withTitle, t],
	);

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				PaperProps={{
					style: { borderRadius: 20, minWidth: '80%' },
				}}>
				<form ref={formRef}>
					<DialogTitle id="customized-dialog-title" onClose={handleClose}>
						<div style={{ maxWidth: '80%', margin: 'auto' }}>
							<LargeProgressBar steps={stepper.steps} failedSteps={failedSteps} activeStep={activeStep} />
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="m-system-management-modal">
							{withTitle && <Header name={systemName} setName={setSystemName} disabled={success} />}
							{!success ? (
								React.cloneElement(stepContent[activeStep], {
									data: stepInputData[activeStep],
									handleValidation,
									nextPressed,
								})
							) : (
								<SuccessStep devices={systemDevices} customerData={stepInputData[2][0]} />
							)}
						</div>
					</DialogContent>
					{!success ? (
						<DialogActions>
							{activeStep > 0 && (
								<PrevButton onClick={() => handlePrev()} variant="contained" color="primary">
									Zurück
								</PrevButton>
							)}
							<NextButton type="submit" onClick={handleNext} variant="contained" color="primary">
								Weiter
							</NextButton>
						</DialogActions>
					) : (
						<DialogActions>
							<NextButton type="submit" onClick={() => handleClose()} variant="contained" color="primary">
								Fertig
							</NextButton>
						</DialogActions>
					)}
				</form>
			</Dialog>
		</div>
	);
}
export default SystemMngModal;
