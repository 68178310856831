export interface ApprovalProps {
	editTo: string;
	title: string;
	consentsTag: string;
	consentsFirstText: string;
	consentsSecondText: string;
	consentsThirdText: string;
	consentsFirst: boolean;
	consentsSecond: boolean;
	consentsThird: boolean;
	fileUploadTag: string;
	fileUpload: string;
	oder: string;
	selectFiles: string;
	fileSize: string;
	encrypted: string;
	data?: any;
	nextPressed?: boolean;
	handleValidation?: (isValid: boolean) => void;
}

export const ApprovalData: ApprovalProps = {
	editTo: 'bearbeiten',
	title: 'Musteranlage',
	consentsTag: 'Zustimmungen',
	consentsFirstText:
		'Ich will für meinen Endkunden, die 5 Jahre Garantie beantragen und bestätige, ihn zu den Garantiebedingungen und zur Datenschutzerklärung informiert zu haben.',
	consentsSecondText:
		'Hiermit bestätige ich, dass mir die Einwilligung des Kunden vorliegt, seine mit diesem Anmeldeformular erhobenen Daten an die WOLF GmbH weiterzugeben. Die Daten werden von der WOLF GmbH für die Abwicklung der 5 Jahres - Garantie genutzt, gespeichert und verwaltet.',
	consentsThirdText:
		'Ich bin damit einverstanden, dass die WOLF GmbH meine mit diesem Anmeldeformular erhobenen Daten zu Werbezwecken nutzt. Wenn ich die Einwilligung nicht erteile oder sie widerrufe, hat dies keine Auswirkung auf die Anmeldung zur 5 Jahres Garantie.',
	consentsFirst: false,
	consentsSecond: false,
	consentsThird: false,
	fileUploadTag: 'Vertragsupload',
	fileUpload: 'Dateien hierher ziehen',
	oder: 'oder',
	selectFiles: 'Dateien auswählen',
	fileSize: 'File Size 10 MB',
	encrypted: 'Encrypted',
};
