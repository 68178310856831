import React, { useEffect } from 'react';
import { EditSquare, RevisionOutlined } from 'assets/icons';
import { CardProps } from '../CurrentEvents.d';

function Card({ style, event, onClickCard }: CardProps): JSX.Element {
	const [title, setTitle] = React.useState<string>('');
	const [info, setInfo] = React.useState<string>('');

	const iconMapping = {
		device_component_maintenance_registered: (
			<RevisionOutlined className="card-icon" style={{ color: '#00c5b9', backgroundColor: 'rgba(0, 197, 185, .2)' }} />
		),
		device_added: <EditSquare className="card-icon" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
		device_removed: <EditSquare className="card-icon" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
		device_component_added: <EditSquare className="card-icon" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
		device_component_removed: <EditSquare className="card-icon" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
		commissioning: <EditSquare className="card-icon" style={{ color: '#a2a2a2', backgroundColor: 'rgba(162, 162, 162, .2)' }} />,
	};

	useEffect(() => {
		switch (event.type) {
			case 'device_component_maintenance_registered':
				setTitle(`Wartung vor Ort`);
				setInfo(`Wartung von ${event.event_details.type_description} durchgeführt`);
				break;
			case 'device_added':
				setTitle(`Anlage hinzugefügt`);
				setInfo(`Die Anlage ${event.event_details.name} wurde hinzugefügt`);
				break;
			case 'device_removed':
				setTitle(`Anlage entfernt`);
				setInfo(`Die Anlage ${event.event_details.name} wurde entfernt`);
				break;
			case 'device_component_added':
				setTitle(`Hauptwärmeerzeuger hinzugefügt`);
				setInfo(`Das Gerät ${event.event_details.name} wurde hinzugefügt`);
				break;
			case 'device_component_removed':
				setTitle(`Hauptwärmeerzeuger entfernt`);
				setInfo(`Das Gerät ${event.event_details.name} wurde entfernt.`);
				break;
			case 'commissioning':
				setTitle(`Inbetriebnahme`);
				setInfo(`${event.event_details.name} wurde in Betrieb genommen`);
				break;
			default:
				setTitle('');
				setInfo('');
				break;
		}
	}, [event]);

	return (
		<div className="card-wrapper" style={style} onClick={onClickCard}>
			<p className="card-title">
				<span style={{ float: 'right', paddingRight: 20 }}>{iconMapping[event.type]}</span>
				{new Date(event.creation_date).toLocaleDateString('de-DE')}
			</p>
			<p className="card-subHeadline">{title}</p>
			<p className="card-info">{info}</p>
		</div>
	);
}

export default Card;
