import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { isSameDay, startOfDay, endOfDay, subDays } from 'date-fns';
import { CurrentEventsProps } from './CurrentEvents.d';
import StackableCards from './components/StackableCards';
import { DeviceEvent } from 'types/DeviceEvent';
import { eventWidgetSupportedTypes } from 'constants/supportedEventTypes';
import backboneApi from 'store/modules/backbone';

function EventsWidget(props: CurrentEventsProps): JSX.Element {
	const [stacks, setStacks] = useState({});
	const { t } = useTranslation();
	const {
		systemId,
		showAll: { link },
		stacksTimeString,
	} = props;

	const { data: events, isLoading, isFetching } = backboneApi.useGetCurrentEventsQuery(systemId ? { device_uuid: systemId } : {});

	const filterByDate = (data: Array<DeviceEvent>) => {
		const tempStacks = {} as any;
		const today = new Date();
		const yesterday = subDays(today, 1);
		const twoDaysAgo = subDays(today, 2);
		const sevenDaysAgo = subDays(today, 7);

		tempStacks.today = data.filter((obj: DeviceEvent) => {
			const creationDate = new Date(obj.creation_date);
			if ((!systemId || systemId === '') && !eventWidgetSupportedTypes.includes(obj.type)) return false;
			return isSameDay(creationDate, today);
		});

		tempStacks.yesterday = data.filter((obj: DeviceEvent) => {
			const creationDate = new Date(obj.creation_date);
			if ((!systemId || systemId === '') && !eventWidgetSupportedTypes.includes(obj.type)) return false;
			return isSameDay(creationDate, yesterday);
		});

		tempStacks.thisWeek = data.filter((obj: DeviceEvent) => {
			const creationDate = new Date(obj.creation_date);
			if ((!systemId || systemId === '') && !eventWidgetSupportedTypes.includes(obj.type)) return false;
			return creationDate > sevenDaysAgo && creationDate < endOfDay(twoDaysAgo);
		});

		tempStacks.lastTwoWeeks = data.filter((obj: DeviceEvent) => {
			const creationDate = new Date(obj.creation_date);
			if ((!systemId || systemId === '') && !eventWidgetSupportedTypes.includes(obj.type)) return false;
			return creationDate < sevenDaysAgo;
		});

		return tempStacks;
	};

	const stackTime = (timestamp: string) => {
		if (timestamp === 'today') {
			return stacksTimeString[0];
		} else if (timestamp === 'yesterday') {
			return stacksTimeString[1];
		} else if (timestamp === 'thisWeek') {
			return stacksTimeString[2];
		} else if (timestamp === 'lastTwoWeeks') {
			return stacksTimeString[3];
		}
	};

	useEffect(() => {
		const filteredDates = (events && events.items && filterByDate(events?.items)) || {};
		setStacks(filteredDates);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events]);

	return (
		<div className="m-widget-current-events">
			{isLoading || isFetching ? (
				<div className="skeleton-container">
					<Skeleton height={50} />
					<Skeleton height={50} />
					<Skeleton height={50} />
					<Skeleton height={50} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
				</div>
			) : (
				<div className="widget-container">
					<div className="title">
						{t('Latest Activities')}
						{systemId && systemId !== '' && (
							<span>
								<a href={link}>{t('Show All')}</a>
							</span>
						)}
					</div>
					<div className="content">
						{Object.keys(stacks).length ? (
							<>
								{stacks &&
									Object.keys(stacks).map((k, index) =>
										stacks[k].length ? (
											<div key={index}>
												{!systemId && systemId !== '' && <div className="stack-time">{stackTime(k)}</div>}
												<StackableCards key={index} data={stacks[k]} detailedView={!!(systemId && systemId !== '')} />
											</div>
										) : null,
									)}
							</>
						) : (
							<div className="empty-state-container">
								<div className="title">{t('No events to show')}</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}

export default EventsWidget;
